import { Box, Divider, Link, Typography } from "@mui/material";
import unLogoMobile from "../../assets/logos/un-logo.png";
import unLogo from "../../assets/logos/un-logo-text.png";
import undidLogo from "../../assets/logos/undid-logo-text.svg";
import undidLogoMobile from "../../assets/logos/undid-logo-notext-blue.png";
import useResponsive from "../../hooks/useResponsive";
import cn from "classnames";

const TermsOfService = () => {
  const { isMobile } = useResponsive();

  //#region Texts
  const mainTitle = "UN Digital Wallet Terms and Conditions";
  const mainParagraph = ["To be defined..."];

  const termsList = [
    { title: mainTitle, paragraph: mainParagraph, isMain: true },
  ];
  //#endregion

  //#region Renderers
  const sections = termsList.map((pol, index) => {
    const { title, paragraph, isMain } = pol;
    return (
      <Box key={index} className={isMain ? "space-y-8" : "space-y-1"}>
        <Typography variant={isMain ? "h1" : "h2"}>{title}</Typography>
        <Typography whiteSpace="pre-line">{paragraph}</Typography>
      </Box>
    );
  });
  //#endregion

  return (
    <Box
      data-testid="termsOfService"
      className={isMobile ? "p-8 space-y-10" : "p-14 space-y-20"}
    >
      <Box className={cn("flex space-x-12", isMobile && "justify-between")}>
        <img
          src={isMobile ? unLogoMobile : unLogo}
          className="h-16"
          alt="united nations logo"
        />
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            aria-hidden="true"
            className="border-black opacity-50"
          />
        )}
        <img
          src={isMobile ? undidLogoMobile : undidLogo}
          className="h-16"
          alt="digital id logo"
        />
      </Box>
      <Box className={cn("space-y-5", isMobile ? "px-2" : "px-24")}>
        {sections}
      </Box>
    </Box>
  );
};

export { TermsOfService };
