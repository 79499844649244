import { ContentCopyOutlined } from "@mui/icons-material";
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField as MuiTextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { TranslationKey } from "../IntlMessages/IntlMessages";

type TextFieldProps = {
  id: string;
  label?: TranslationKey;
  placeholder?: TranslationKey;
  copy?: boolean;
  disabled?: boolean;
  containerClass?: string;
  value: string | undefined;
  onChange(newValue: string): void;
};

const TextField = ({
  id,
  label,
  placeholder,
  copy = false,
  disabled = false,
  containerClass,
  value,
  onChange,
}: TextFieldProps) => {
  const intl = useIntl();
  const translatedLabel = label ? intl.formatMessage({ id: label }) : null;
  const copyTooltip = intl.formatMessage(
    { id: "copyClipboard" },
    { text: translatedLabel }
  );
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const onCopy = () => {
    navigator.clipboard.writeText(value!);
    setOpenTooltip(true);
    setTimeout(() => setOpenTooltip(false), 2000);
  };

  return (
    <FormGroup className={containerClass}>
      <FormControlLabel
        data-testid={`${id}.label`}
        label={translatedLabel}
        labelPlacement="top"
        control={
          <MuiTextField
            id={id}
            data-testid={id}
            aria-label={translatedLabel || "input"}
            variant="outlined"
            fullWidth
            placeholder={
              placeholder ? intl.formatMessage({ id: placeholder }) : ""
            }
            disabled={disabled}
            InputProps={{
              endAdornment:
                copy && value ? (
                  <Tooltip title={copyTooltip} open={openTooltip}>
                    <IconButton data-testid={`${id}.copy`} onClick={onCopy}>
                      <ContentCopyOutlined color="primary" />
                    </IconButton>
                  </Tooltip>
                ) : null,
            }}
            value={value || ""}
            onChange={(event) => onChange(event.target.value)}
          />
        }
        classes={{ label: "mb-[0.375rem]" }}
      />
    </FormGroup>
  );
};

export { TextField };
