import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../../../authConfig";
import AuthServiceError, { AuthServiceErrorType } from "./AuthServiceError";
import AuthServiceInterface from "./AuthServiceInterface";

export class AuthService implements AuthServiceInterface {
  private msalInstance?: IPublicClientApplication;

  set _msalInstance(instance: IPublicClientApplication) {
    this.msalInstance = instance;
  }

  async login(): Promise<void> {
    if (this.msalInstance) this.msalInstance.loginRedirect(loginRequest);
  }

  async logout(): Promise<void> {
    if (this.msalInstance)
      this.msalInstance.logoutRedirect({ onRedirectNavigate: () => false });
  }

  async getToken(): Promise<string | null> {
    if (this.msalInstance) {
      const account = this.msalInstance.getAllAccounts()[0];
      const { scopes } = loginRequest;
      if (account) {
        try {
          const authResult = await this.msalInstance.acquireTokenSilent({
            account,
            scopes,
          });
          return authResult.idToken;
        } catch (e: any) {
          throw new AuthServiceError(AuthServiceErrorType.couldNotGetUserToken);
        }
      }
      throw new AuthServiceError(AuthServiceErrorType.noUserLogged);
    }
    return null;
  }
}

export default class AuthServiceFactory {
  private static service: AuthServiceInterface;

  static get shared(): AuthServiceInterface {
    if (!this.service) {
      this.service = new AuthService();
    }
    return this.service;
  }
}
