import axe from "@axe-core/react";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/500.css";
import "@fontsource/noto-sans/600.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App/App";
import { msalConfig } from "./authConfig";
import { GlobalContextProvider } from "./contexts/GlobalContextProvider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import AuthServiceFactory from "./core/services/auth/AuthService";

//#region Accessibility setup
const axeConfig = {
  runOnly: ["wcag2a"],
};

if (typeof window !== "undefined" && process.env.NODE_ENV !== "production") {
  axe(React, ReactDOM, 1000, axeConfig);
}
//#endregion

//#region Authentication setup
const msalInstance = new PublicClientApplication(msalConfig);
AuthServiceFactory.shared._msalInstance = msalInstance;

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});
//#endregion

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

msalInstance.initialize().then(() => {
  root.render(
    <GlobalContextProvider>
      <App instance={msalInstance} />
    </GlobalContextProvider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
