import { createContext, useContext, useEffect, useState } from "react";
import User, { UsersResponseData } from "../../core/models/User";
import NetworkingServiceError from "../../core/services/network/NetworkServiceError";
import appConfig from "../../util/appConfig";

// This context allows the app to get the users information

export type UserError = NetworkingServiceError;

// Create type
export type UsersDataType = {
  usersResponseData?: UsersResponseData;
  setUsersResponseData: (responseData?: UsersResponseData) => void;
  selectedUser?: User;
  setSelectedUser: (user?: User) => void;
  usersLoading: boolean;
  setUsersLoading: (isLoading: boolean) => void;
  usersError: UserError | undefined;
  setUsersError: (error?: UserError) => void;
};

// Create context for type
// @ts-ignore
export const UsersDataContext = createContext<UsersDataType>();

//Create provider of the context
export const UsersDataProvider = ({ children }: any) => {
  const [selectedUser, setSelectedUser] = useState<User>();
  const [usersResponseData, setUsersResponseData] =
    useState<UsersResponseData>();
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [usersError, setUsersError] = useState<UserError>();

  const value = {
    usersResponseData,
    setUsersResponseData,
    selectedUser,
    setSelectedUser,
    usersLoading,
    setUsersLoading,
    usersError,
    setUsersError,
  };
  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugUsers && selectedUser)
      console.debug("[Users][SelectedUser] ", selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugUsers && usersResponseData)
      console.debug("[Users][UsersResponseData] ", usersResponseData);
  }, [usersResponseData]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugUsers && usersLoading)
      console.debug("[Users][UsersLoading] ", usersLoading);
  }, [usersLoading]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugUsers && usersError)
      console.warn(
        "[Users][Error] ",
        usersError?.userMessage(),
        usersError?.debugMessage()
      );
  }, [usersError]);
  return (
    <UsersDataContext.Provider value={value}>
      {children}
    </UsersDataContext.Provider>
  );
};

export const useUsersContext = () => useContext(UsersDataContext);
