import { Drawer } from "@mui/material";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { MenuContent } from "./MenuContent";

const SideMenu = () => {
  return (
    <Drawer
      data-testid="side-menu"
      anchor="left"
      variant="permanent"
      PaperProps={{
        className:
          "w-60 h-screen border-r-0 pt-10 pb-8 px-8 bg-gray-background",
      }}
    >
      <Header />
      <MenuContent />
      <Footer />
    </Drawer>
  );
};

export { SideMenu };
