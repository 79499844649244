import { Organization } from "../../defines/organizations";
import { QueryData, ResponseData } from "../services/network/NetworkTypes";

export enum UserRole {
  Registrar = "registrar",
  Web = "web",
}

export default class User {
  public orgLogo?: string;

  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public email: string,
    public roles: Array<UserRole>,
    public organization: Organization,
    public createdOn: Date
  ) {}

  static fromJSON(json: any): User {
    /*
          {
            "id": 2,
            "username": "rodriguesd@unicc.org",
            "first_name": "Diana",
            "last_name": "Rodrigues",
            "groups": [
                "admin"
            ]
          }
    */
    return new User(
      json.id,
      json.first_name,
      json.last_name,
      json.username,
      json.groups,
      window.ENV_ORG,
      json.date_joined
    );
  }

  toJSON(): UserUpdateData {
    return {
      id: this.id,
      username: this.email,
    };
  }
}

export type UserCreateData = {
  username: string;
  first_name: string;
  last_name: string;
  groups: Array<UserRole>;
};

export type UserUpdateData = Partial<UserCreateData> & {
  id: number;
};

export type UserInvitationData = {
  email: string;
};

// This will be used to filter the data
export type UsersDataFilter = "search" | "group";
// Backend keys you can sort with
export type UserDataOrder = "first_name" | "last_name" | "username" | "groups";

// This will help transform the sorting from local to backend keys.
export const userDataOrderTransform: Record<string, UserDataOrder> = {
  firstName: "first_name",
  lastName: "last_name",
  email: "username",
  role: "groups",
};

// These are the keys used for table listings
export type UserDataTableFields = keyof typeof userDataOrderTransform;

export type UsersQueryData = QueryData<UsersDataFilter, UserDataOrder>;
export type UsersResponseData = ResponseData<
  User,
  UsersDataFilter,
  UserDataOrder
>;

export type UserWithPublicKey = Required<User>;
