import { createContext, useContext, useEffect, useState } from "react";
import appConfig from "../../util/appConfig";
import User from "../../core/models/User";
import NetworkingServiceError from "../../core/services/network/NetworkServiceError";

// This context allows the app to get the authentication information
export type AuthError = NetworkingServiceError;

// Create type
export type AuthenticationDataType = {
  authenticated: boolean;
  setAuthenticated: (auth: boolean) => void;
  userInfo?: User;
  setUserInfo: (user: User | undefined) => void;
  authError?: AuthError;
  setAuthError: (error?: AuthError) => void;
};

export const defaultAuthenticationDataType = (): AuthenticationDataType => ({
  authenticated: false,
  setAuthenticated: () => {},
  userInfo: undefined,
  setUserInfo: () => {},
  authError: undefined,
  setAuthError: () => {},
});

// Create context for type
export const AuthenticationDataContext = createContext<AuthenticationDataType>(
  defaultAuthenticationDataType()
);

// Create provider of the context
export const AuthenticationDataProvider = ({ children }: any) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState<User | undefined>();
  const [authError, setAuthError] = useState<AuthError>();

  const value = {
    authenticated,
    setAuthenticated,
    userInfo,
    setUserInfo,
    authError,
    setAuthError,
  };

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugAuth)
      console.debug("[Authentication][UserInfo] ", userInfo);
  }, [userInfo]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugAuth)
      console.debug("[Authentication][Authenticated] ", authenticated);
  }, [authenticated]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugAuth)
      console.debug(
        "[Authentication][Error] ",
        authError?.userMessage(),
        authError?.debugMessage()
      );
  }, [authError]);

  return (
    <AuthenticationDataContext.Provider value={value}>
      {children}
    </AuthenticationDataContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthenticationDataContext);
