import { useEffect, useState } from "react";

export enum Breakpoint {
  Mobile = 0,
  Tablet = 640,
  Laptop = 1024,
  Desktop = 1280,
}

const useResponsive = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(Breakpoint.Laptop);

  const handleResize = () => {
    const { innerWidth: width } = window;
    let newBreakpoint: Breakpoint = Breakpoint.Laptop;

    if (width >= Breakpoint.Mobile && width < Breakpoint.Tablet)
      newBreakpoint = Breakpoint.Mobile;
    else if (width >= Breakpoint.Tablet && width < Breakpoint.Laptop)
      newBreakpoint = Breakpoint.Tablet;
    else if (width >= Breakpoint.Laptop && width < Breakpoint.Desktop)
      newBreakpoint = Breakpoint.Laptop;
    else if (width >= Breakpoint.Desktop) newBreakpoint = Breakpoint.Desktop;

    setBreakpoint(newBreakpoint);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    breakpoint,
    isMobile: [Breakpoint.Mobile, Breakpoint.Tablet].includes(breakpoint),
  };
};

export default useResponsive;
