export const BLUE_100 = "#006EB6";
export const BLUE_600 = "#096EB4";
export const DARK_BLUE = "#204184";
export const GRAY_20 = "#C8C8C8";
export const GRAY_40 = "#9D9D9D";
export const GRAY_60 = "#757575";
export const GRAY_80 = "#474747";
export const GRAY_BACKGROUND = "#E4E4E4";
export const GRAY_BLACK = "#202020";
export const GRAY_INPUT = "#F1F1F1";
export const RED = "#CC2F36";
export const UN_BLUE = "#0575C7";
