import {
  FormControlLabel,
  FormGroup,
  Switch as MuiSwitch,
  styled,
} from "@mui/material";
import { BLUE_100, GRAY_60 } from "../../themes/colors";
import { TranslationKey } from "../IntlMessages/IntlMessages";
import { useIntl } from "react-intl";

type SwitchProps = {
  id: string;
  label: TranslationKey;
  labelParam?: { [param: string]: string };
  value?: boolean;
  onChange: (newValue: boolean) => void;
};

const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 30,
  height: 15,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: "1px",
    "&.Mui-checked": {
      transform: "translateX(15px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : BLUE_100,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 13,
    height: 13,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : GRAY_60,
    boxSizing: "border-box",
  },
}));

const Switch = ({
  id,
  label,
  labelParam,
  value = false,
  onChange,
}: SwitchProps) => {
  const intl = useIntl();
  const translatedLabel = intl.formatMessage({ id: label }, labelParam);

  return (
    <FormGroup>
      <FormControlLabel
        data-testid={`${id}.label`}
        label={translatedLabel}
        control={
          <StyledSwitch
            id={id}
            data-testid={id}
            checked={value}
            onChange={(_, checked) => onChange(checked)}
          />
        }
        classes={{ label: "ml-[0.625rem]" }}
      />
    </FormGroup>
  );
};

export { Switch };
