import {
  DEFAULT_LANGUAGE,
  Language,
  LanguageDirection,
  SUPPORTED_LANGUAGES,
} from "../../lngProvider/constants";
import {
  getInitialLanguage,
  getInitialLanguageDirection,
  useLanguageContext,
} from "./LanguageContext";

const useLanguageActions = () => {
  const { language, setLanguage, setLanguageDirection } = useLanguageContext();

  const dispatchLanguageChange = (newLanguage: Language) => {
    if (language === newLanguage) return;
    const langToSet =
      SUPPORTED_LANGUAGES.find((lng) => lng === newLanguage) ??
      DEFAULT_LANGUAGE;
    setLanguage(langToSet);
    setLanguageDirection(
      langToSet === Language.Arabic
        ? LanguageDirection.RightToLeft
        : LanguageDirection.LeftToRight
    );
  };

  const dispatchCleanLanguageContext = () => {
    setLanguage(getInitialLanguage());
    setLanguageDirection(getInitialLanguageDirection());
  };

  return { dispatchLanguageChange, dispatchCleanLanguageContext };
};
export default useLanguageActions;
