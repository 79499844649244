import {
  Box,
  Table as MuiTable,
  TableContainer,
  Typography,
} from "@mui/material";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import { TableContent } from "./TableContent/TableContent.container";
import { TableFooter } from "./TableFooter/TableFooter.container";
import { TableHeader } from "./TableHeader/TableHeader.container";
import { TableSkeleton } from "./TableSkeleton/TableSkeleton.container";
import { TableInterface } from "./TableTypes";
import { TableFilters } from "./TableFilters";

const Table = <IdField extends string, Model>({
  actionTitle,
  columns,
  count,
  data,
  loading = false,
  ordering,
  page,
  rowRules,
  searchValue,
  showRefresh,
  switchValue,
  EmptyState: EmptyStateSlot,
  BeforeTable,
  onChangeSwitch,
  onClickAction,
  onClickRow,
  onPageChange,
  onRefresh,
  onSearch,
  onSort,
}: TableInterface<IdField, Model>) => {
  const EmptyState = EmptyStateSlot || <TableEmptyState />;

  const filters = (
    <TableFilters
      searchValue={searchValue}
      switchValue={switchValue}
      showRefresh={showRefresh}
      actionTitle={actionTitle}
      onSearch={onSearch}
      onChangeSwitch={onChangeSwitch}
      onRefresh={onRefresh}
      onClickAction={onClickAction}
    />
  );

  const skeleton = !data && loading && (
    <MuiTable data-testid="table" className="border-separate">
      <TableHeader columns={columns} onSort={onSort} ordering={ordering} />
      <TableSkeleton columns={columns} />
    </MuiTable>
  );

  const tableData = data && data.length > 0 && (
    <>
      {BeforeTable}
      <TableContainer>
        <MuiTable data-testid="table" className="border-separate">
          <TableHeader columns={columns} onSort={onSort} ordering={ordering} />
          <TableContent
            columns={columns}
            data={data}
            onClickRow={onClickRow}
            rowRules={rowRules}
          />
        </MuiTable>
      </TableContainer>
      <TableFooter count={count || 0} page={page} onPageChange={onPageChange} />
    </>
  );

  const noResults = data && data.length === 0 && (
    <div data-testid="table.noResults">{EmptyState}</div>
  );

  return (
    <>
      <Box className="space-y-4">
        {filters}
        {skeleton}
        {tableData}
        {noResults}
      </Box>
    </>
  );
};

const TableEmptyState = () => (
  <Box className="min-w-full flex justify-center">
    <Typography variant="overline">
      <IntlMessages id="table.noResults" />
    </Typography>
  </Box>
);

export { Table };
