import { DeleteOutline, SearchOutlined } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  Typography,
} from "@mui/material";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import { PageHeader } from "../../components/PageHeader";
import { TextField } from "../../components/TextField";
import { useAuthContext } from "../../contexts/authentication/AuthenticationContext";
import { useCredentialDefinitionsContext } from "../../contexts/credentialDefinitions/CredentialDefinitionsContext";
import { useCredentialDefinitionsActions } from "../../contexts/credentialDefinitions/UseCredentialDefinitionsActions";
import { useSchemasContext } from "../../contexts/schemas/SchemasContext";
import { useSchemasActions } from "../../contexts/schemas/UseSchemasActions";
import {
  CredentialDefinitionCreateData,
  CredentialDefinitionUpdateData,
} from "../../core/models/CredentialDefinition";
import Schema from "../../core/models/Schema";
import { notify } from "../../libs/observables/notification";
import { GRAY_60 } from "../../themes/colors";
import { SchemasModal } from "./schemasModal.container";

const CredentialDefinitionNewEdit = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { userInfo } = useAuthContext();
  const { selectedCredentialDefinition, credentialDefinitionsError } =
    useCredentialDefinitionsContext();
  const {
    dispatchGetCredentialDefinition,
    dispatchCreateCredentialDefinition,
    dispatchUpdateCredentialDefinition,
    dispatchCleanCredentialDefinitionsContext,
  } = useCredentialDefinitionsActions();
  const { selectedSchema } = useSchemasContext();
  const { dispatchGetSchema } = useSchemasActions();
  const [name, setName] = useState<string>();
  const [schema, setSchema] = useState<Schema>();
  const [openSelectSchema, setOpenSelectSchema] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSchema, setLoadingSchema] = useState<boolean>(false);
  const isViewing =
    !!params.id &&
    userInfo?.organization !== selectedCredentialDefinition?.createdBy;
  const formNotChanged =
    !!params.id &&
    !!selectedCredentialDefinition &&
    selectedCredentialDefinition.schemaId === schema?.id;
  const disableSave = !name || !schema || formNotChanged;

  //#region Load and cleanup
  useEffect(() => {
    if (params.id) dispatchGetCredentialDefinition(+params.id);
    return () => dispatchCleanCredentialDefinitionsContext();
  }, []);

  useEffect(() => {
    if (!params.id) {
      if (selectedCredentialDefinition && !credentialDefinitionsError) {
        notify("success")({ message: "Credential definition created!" });
        navigate(-1);
      }
    } else {
      if (
        loading &&
        selectedCredentialDefinition &&
        !credentialDefinitionsError
      ) {
        notify("success")({ message: "Credential definition updated!" });
      } else if (selectedCredentialDefinition) {
        setName(selectedCredentialDefinition.name);

        if (
          !selectedSchema ||
          selectedSchema.id !== selectedCredentialDefinition.schemaId
        ) {
          setLoadingSchema(true);
          dispatchGetSchema(selectedCredentialDefinition.schemaId);
        }
      }
    }

    setLoading(false);
  }, [selectedCredentialDefinition, credentialDefinitionsError]);

  useEffect(() => {
    if (
      selectedCredentialDefinition &&
      selectedSchema &&
      selectedSchema.id === selectedCredentialDefinition?.schemaId
    ) {
      setLoadingSchema(false);
      setSchema(selectedSchema);
    }
  }, [selectedSchema]);
  //#endregion

  //#region Page actions
  const onCancel = () => navigate(-1);

  const onSave = () => {
    if (!name) {
      notify("error")({ message: "Credential definition name is required!" });
      return;
    }
    if (!schema) {
      notify("error")({ message: "Credential definition schema is required!" });
      return;
    }

    setLoading(true);

    const newCredentialDefinition: CredentialDefinitionCreateData = {
      name,
      schema: schema.schemaId,
      organization_name:
        selectedCredentialDefinition?.createdBy ?? userInfo?.organization!,
    };

    if (params.id) {
      const credentialDefinitionModified: CredentialDefinitionUpdateData = {
        id: +params.id,
        ...newCredentialDefinition,
      };
      dispatchUpdateCredentialDefinition(credentialDefinitionModified);
    } else {
      dispatchCreateCredentialDefinition(newCredentialDefinition);
    }
  };
  //#endregion

  //#region Select schema
  const onSelectSchema = (schema?: Schema) => {
    if (schema) setSchema(schema);
    setOpenSelectSchema(false);
  };
  //#endregion

  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress data-testid="loader" />
      </Backdrop>
      <Box
        data-testid="credentialDefinition"
        className="min-w-full space-y-[1.875rem] flex-box"
      >
        <PageHeader
          title={
            params.id
              ? "credentialDefinition.details"
              : "credentialDefinition.new"
          }
          tooltip="credentialDefinitions"
          divider
          disableSave={disableSave}
          containerClass="flex-initial"
          onCancel={onCancel}
          onSave={!isViewing ? onSave : undefined}
        />
        {/* Form */}
        <Grid container columnSpacing={4} className="flex-auto">
          {/* Left panel */}
          <Grid xs={6} className="space-y-[1.875rem]">
            <TextField
              id="credentialDefinition.name"
              label="credentialDefinition.new.name"
              placeholder="credentialDefinition.new.name.placeholder"
              value={name}
              onChange={setName}
              disabled={!!selectedCredentialDefinition}
            />
            {selectedCredentialDefinition && (
              <>
                <TextField
                  id="credentialDefinition.id"
                  label="credentialDefinition.idAbbr"
                  value={selectedCredentialDefinition.credentialId}
                  onChange={() => {}}
                  copy
                  disabled
                />
              </>
            )}
          </Grid>
          {/* Right panel */}
          <Grid xs={6}>
            <Box
              className={cn(
                "h-full rounded-[10px] bg-gray-input",
                "p-[1.875rem] space-y-5",
                loadingSchema && "flex-box"
              )}
            >
              <Box className="flex justify-between items-center">
                <Typography variant="body2">
                  <IntlMessages
                    id={
                      schema
                        ? "credentialDefinition.new.selectSchema.schemaSelected"
                        : "credentialDefinition.new.selectSchema"
                    }
                  />
                </Typography>
                {!schema && (
                  <Button
                    data-testid="credentialDefinition.schema.browse"
                    variant="text"
                    className="font-medium"
                    startIcon={<SearchOutlined />}
                    onClick={() => setOpenSelectSchema(true)}
                  >
                    <IntlMessages id="credentialDefinition.new.browseSchemas" />
                  </Button>
                )}
              </Box>
              {loadingSchema && (
                <Box className="flex-1 flex justify-center items-center">
                  <CircularProgress />
                </Box>
              )}
              {schema && (
                <Box
                  data-testid="credentialDefinition.schema"
                  className="rounded-lg border border-solid border-gray-20 bg-white p-[1.25rem] space-y-4"
                >
                  {/* Schema */}
                  <Box className="flex justify-between items-center">
                    <Box className="space-y-3">
                      <Typography variant="h6" className="opacity-50">
                        {schema.createdBy}
                      </Typography>
                      <Typography variant="h6" fontWeight={600}>
                        {schema.name}
                      </Typography>
                      <Typography fontWeight={500}>
                        <IntlMessages
                          id="credentialDefinition.schema.version"
                          values={{ version: schema.version }}
                        />
                      </Typography>
                    </Box>
                    {!isViewing && (
                      <IconButton
                        data-testid="credentialDefinition.schema.delete"
                        onClick={() => setSchema(undefined)}
                      >
                        <DeleteOutline color="secondary" />
                      </IconButton>
                    )}
                  </Box>
                  <Divider />
                  {/* Schema attributes */}
                  {schema.attributes.map((attr, index) => (
                    <Box key={index} className="flex items-center">
                      <Typography fontWeight={500}>{attr.name}</Typography>
                      <Typography whiteSpace="pre-wrap" color={GRAY_60}>
                        {" / "}
                        <IntlMessages
                          id={`schema.attribute.type.${attr.type}`}
                        />
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {openSelectSchema && (
        <SchemasModal
          open={openSelectSchema}
          selectedSchema={schema}
          onClose={onSelectSchema}
        />
      )}
    </>
  );
};

export { CredentialDefinitionNewEdit };
