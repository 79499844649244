import { MoreVert } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import { useAuthContext } from "../../contexts/authentication/AuthenticationContext";
import useAuthenticationActions from "../../contexts/authentication/UseAuthenticationActions";
import { getFullName } from "../../util/utils";

const TopBar = () => {
  const { userInfo } = useAuthContext();
  const { dispatchLogout } = useAuthenticationActions();
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuEl(event.currentTarget);
  };

  const handleCloseMenu = () => setMenuEl(null);

  const handleLogoutClick = () => {
    handleCloseMenu();
    dispatchLogout();
  };

  return (
    <AppBar data-testid="top-bar" position="static" className="bg-gray-input">
      <Container maxWidth={false} disableGutters>
        <Toolbar
          disableGutters
          variant="dense"
          className="justify-end space-x-[0.625rem] px-[1.375rem] py-2"
        >
          <Typography
            data-testid="top-bar-user-name"
            variant="body2"
            className="text-gray-80"
          >
            {getFullName(userInfo?.firstName, userInfo?.lastName)}
          </Typography>
          <Box className="flex flex-grow-0">
            <IconButton
              data-testid="top-bar-menu-button"
              aria-label="menu actions"
              className="text-gray-40"
              onClick={handleOpenMenu}
            >
              <MoreVert className="text-lg" />
            </IconButton>
            <Menu
              data-testid="top-bar-menu"
              anchorEl={menuEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              className="mt-8"
              open={Boolean(menuEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                data-testid="top-bar-logout"
                onClick={handleLogoutClick}
              >
                <Typography>
                  <IntlMessages id="logout" />
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export { TopBar };
