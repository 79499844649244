import { Configuration, LogLevel, RedirectRequest } from "@azure/msal-browser";
import { OrgDomain } from "./defines/organizations";
import { Environment } from "./defines/common";

const TenantName = {
  [Environment.Dev]: "undab2cdev",
  [Environment.QA]: "undab2cdev",
  [Environment.Preprod]: "undab2c",
  [Environment.Prod]: "undab2c",
};

const B2CPolicy = {
  [Environment.Dev]: "B2C_1A_DEV_UNDI_H",
  [Environment.QA]: "B2C_1A_DEV_UNDI_H",
  [Environment.Preprod]: "B2C_1A_UNDI_H",
  [Environment.Prod]: "B2C_1A_UNDI_H",
};

const ClientId = {
  [Environment.Dev]: "eaa186df-e649-4ac7-bf64-a79a128c573b",
  [Environment.QA]: "eaa186df-e649-4ac7-bf64-a79a128c573b",
  [Environment.Preprod]: "b0797187-5814-48b7-9f96-b9e23c1bbbad",
  [Environment.Prod]: "98ae215b-27ab-405c-88dc-943fccb731f5",
};

const tenantName = TenantName[window.ENV_ENVIRONMENT];
const b2cPolicy = B2CPolicy[window.ENV_ENVIRONMENT];
const clientId = ClientId[window.ENV_ENVIRONMENT];

/* istanbul ignore next: configuration */
export const b2cPolicies = {
  authorities: {
    signIn: {
      authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${b2cPolicy}`,
    },
  },
  authorityDomain: `${tenantName}.b2clogin.com`,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
/* istanbul ignore next: configuration */
export const msalConfig: Configuration = {
  auth: {
    clientId: clientId, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/* istanbul ignore next: configuration */
export const loginRequest: RedirectRequest = {
  authority: b2cPolicies.authorities.signIn.authority,
  scopes: ["email"],
  domainHint: OrgDomain[window.ENV_ORG],
};
