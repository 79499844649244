import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { ChevronRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import packageJson from "../../../package.json";
import unDidLogo from "../../assets/logos/un-did-logo.svg";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import useAuthenticationActions from "../../contexts/authentication/UseAuthenticationActions";

const Login = () => {
  const { inProgress } = useMsal();
  const { dispatchLogin } = useAuthenticationActions();
  const appVersion = `v${packageJson.version}`;

  const onLogin = () => {
    if (inProgress === InteractionStatus.None) dispatchLogin();
  };

  return (
    <Box data-testid="login" className="min-h-screen flex">
      <Box className="w-[calc(100vw/3*2)] flex items-center justify-center bg-blue-UN">
        <img
          src={unDidLogo}
          alt="un-did-logo"
          onDoubleClick={() => console.log(appVersion)}
        />
      </Box>
      <Box className="w-[calc(100vw/3)] min-w-[30rem] flex flex-col justify-center px-[4.375rem] space-y-8">
        <Typography variant="h2">
          <IntlMessages id="login.title" />
        </Typography>
        <Button
          className="justify-between px-[1.25rem]"
          endIcon={<ChevronRight fontSize="large" />}
          onClick={onLogin}
        >
          <Typography>
            <IntlMessages id="login.button" />
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export { Login };
