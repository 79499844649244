import { Alert, AlertColor } from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import React from "react";
import { AppErrorObserver } from "./AppErrorObserver";
import { NotificationSubject } from "../../libs/observables/notification";

function NotificationObserver() {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const sub = NotificationSubject.subscribe((evt) => {
      /* istanbul ignore else */
      if (evt.kind === "toast") {
        /* istanbul ignore else */
        if ("message" in evt.params) {
          const { message, ...others } = evt.params;
          enqueueSnackbar({
            message,
            variant: evt.type as AlertColor,
            style: { whiteSpace: "pre-line" },
            ...others,
          });
        }
      }
    });

    return () => sub.unsubscribe();
  }, []);

  return null;
}

export const AppSnackbarProvider: React.FC<{ children: React.ReactNode }> = (
  props: any
) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      Components={{
        success: makeAlert("success" as AlertColor),
        warning: makeAlert("warning" as AlertColor),
        info: makeAlert("info" as AlertColor),
        error: makeAlert("error" as AlertColor),
      }}
    >
      {props.children}
      <AppErrorObserver />
      <NotificationObserver />
    </SnackbarProvider>
  );
};

type SnackbarAlertProps = {
  message: string;
  id: string;
  onClose?: () => void;
};

const makeAlert = (severity: AlertColor) =>
  React.forwardRef<{}, SnackbarAlertProps>(function SnackbarAlert(props, ref) {
    const { closeSnackbar } = useSnackbar();

    return (
      <Alert
        data-testid={`snackbar.alert.${severity}`}
        // @ts-ignore
        ref={ref}
        onClose={() => {
          props?.onClose?.();
          closeSnackbar(props.id);
        }}
        severity={severity}
        sx={{ width: "100%", whiteSpace: "pre-line" }}
      >
        {props.message}
      </Alert>
    );
  });
