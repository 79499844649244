import dayjs from "dayjs";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { CredentialStatus } from "../core/models/Credential";

dayjs.extend(advancedFormat.default);
dayjs.extend(timezone);
dayjs.extend(utc);

//#region Credentials
/**
 * Gets status color based on credential status
 * @param status {CredentialStatus} credential status
 */
export const getCredentialStatusColor = (
  status?: CredentialStatus
): "error" | "primary" | "success" | "warning" => {
  switch (status) {
    case CredentialStatus.Accepted:
      return "success" /** green */;
    case CredentialStatus.Revoked:
      return "error" /** red */;
    case CredentialStatus.Pending:
    case CredentialStatus.Sent:
      return "primary" /** blue */;
    case CredentialStatus.Canceled:
    default:
      return "warning" /** gray */;
  }
};
//#endregion

//#region Users
export const getFullName = (firstName?: string, lastName?: string): string => {
  return `${firstName ?? "NA"}${lastName ? " " : ""}${lastName ?? ""}`;
};
//#endregion

//#region Dates
export enum DateFormat {
  DayOfMonth = "MMM. Do YYYY",
}

/**
 * the timezone to use. If not set, the functions will guess it.
 */
let defaultTimezone: string | undefined = undefined;
export const setDefaultTimezone = (timezone?: string) => {
  defaultTimezone = timezone;
};

/**
 * Transform date from timestamp
 * @param timestamp {string} the timestamp
 */
export const getDateFromTimestamp = (timestamp: string) => {
  return dayjs.unix(+timestamp).toDate();
};

/**
 * Parses a date to a string format
 * @param date {Date} the date
 * @param format {DateFormat} the format
 */
export const getFormattedDate = (date: Date, format: DateFormat) => {
  const tz = defaultTimezone || dayjs.tz.guess();
  return dayjs(date).tz(tz).format(format);
};
//#endregion

//#region Others
/**
 * Gets hex color with opacity
 * @param color {string} hex color
 * @param opacity {number} opacity between 0 and 1
 */
export const colorWithOpacity = (color: string, opacity: number = 1) => {
  const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};
//#endregion
