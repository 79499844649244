import { TableSortDirection } from "../../../components/Table/TableTypes";

/// This type includes the access token and the refresh
export type AuthenticationResponse = {
  /// Access token
  access: string;
  /// Refresh token
  refresh: string;
};

// This will help on order and filter queries so we do not need to add all the keys in the filters and order
export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

// Defiles the filter
export type Filter<FilterFields extends string> = PartialRecord<
  FilterFields,
  any
>;
// Defines the order. The boolean is true when ascending, false descending
export type Ordering<OrderFields extends string> = Array<
  PartialRecord<OrderFields, boolean>
>;

// This is a helper to transform from the table form of ordering to the backend one. See Party container for an example
export const modelDataOrderTransform = <
  TableFields extends string,
  OrderFields extends string,
>(
  tableData: Array<Record<TableFields, TableSortDirection>> | undefined,
  transformData: Record<TableFields, OrderFields>
): Ordering<OrderFields> | undefined => {
  if (!tableData) {
    return undefined;
  }

  let tableTransform = tableData.map((record) => {
    let key = Object.keys(record)[0];
    // @ts-ignore
    let value = record[key];
    // @ts-ignore
    let transformedKey = transformData[key];
    return {
      // @ts-ignore
      [transformedKey]: value === TableSortDirection.ascending,
    } as any;
  });
  return tableTransform;
};

// This is the query data for backend
export type QueryData<
  FilterFields extends string,
  OrderFields extends string,
> = {
  filter?: Filter<FilterFields>;
  ordering?: Ordering<OrderFields>;
  page: number;
  size: number;
};

/**
 * This type will handle the pagination including filters, ordering... if any
 */
export type ResponseData<
  Data,
  FilterFields extends string,
  OrderFields extends string,
> = {
  data: Array<Data>;
  filter?: PartialRecord<FilterFields, any>;
  ordering?: Ordering<OrderFields>;
  size: number; // Size of page
  page: number; // Page number
  count: number; // Total count
};

export const DEFAULT_PAGE_SIZE = 12;
export const DEFAULT_QUERY_DATA = () => {
  return {
    page: 1, // Django pages start at 1
    size: DEFAULT_PAGE_SIZE,
  };
};
