import { ThemeOptions } from "@mui/material";
import {
  DARK_BLUE,
  GRAY_20,
  GRAY_40,
  GRAY_60,
  GRAY_80,
  GRAY_BACKGROUND,
  GRAY_BLACK,
  GRAY_INPUT,
  RED,
  UN_BLUE,
} from "./colors";
import { colorWithOpacity } from "../util/utils";
import { ExpandMoreOutlined } from "@mui/icons-material";

const rootElement = document.getElementById("root") as HTMLElement;

const undidTheme: ThemeOptions = {
  palette: {
    text: {
      primary: GRAY_BLACK,
    },
    primary: {
      main: DARK_BLUE,
    },
    secondary: {
      main: GRAY_40,
    },
    error: {
      main: RED,
    },
  },
  typography: {
    fontFamily: "Noto Sans, sans-serif",
    h1: {
      fontSize: "1.75rem" /** 28px */,
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.25rem" /** 20px */,
      fontWeight: 600,
    },
    h6: {
      fontSize: "1.125rem" /** 18px */,
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem" /** 16px */,
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem" /** 14px */,
      fontWeight: 400,
      lineHeight: 1.58,
    },
    caption: {
      fontSize: "0.75rem" /** 12px */,
      color: GRAY_60,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        icon: {
          fontSize: "1.35rem" /** 18px */,
          marginRight: "0.5rem" /** 8px */,
          padding: 0,
        },
        message: {
          padding: 0,
        },
        outlinedError: {
          backgroundColor: "#FCEAEB",
        },
        outlinedInfo: {
          backgroundColor: colorWithOpacity(UN_BLUE, 0.1),
          borderColor: UN_BLUE,
          color: UN_BLUE,
          fontWeight: 500,
          padding: "0.75rem" /** 12px */,
          svg: {
            color: UN_BLUE,
          },
        },
        standardInfo: {
          backgroundColor: colorWithOpacity(DARK_BLUE, 0.1),
          borderRadius: "8px",
          color: DARK_BLUE,
          fontWeight: 500,
          padding: "0.5rem 0.75rem" /** 8px 12px */,
          svg: {
            color: DARK_BLUE,
            fontSize: "1rem" /** 16px */,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 1200 + 1,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "100px",
          fontSize: "1rem" /** 16px */,
          fontWeight: 500,
          lineHeight: "normal",
          minWidth: "unset",
          padding: "0.75rem 1.875rem" /** 12px 30px  */,
          textTransform: "none",
        },
        startIcon: {
          "& > :first-of-type": {
            fontSize: "1.5rem" /** 24px */,
          },
        },
        endIcon: {
          "& > :first-of-type": {
            fontSize: "1.5rem" /** 24px */,
          },
        },
        containedSecondary: {
          backgroundColor: GRAY_INPUT,
          border: "1px solid",
          borderColor: GRAY_80,
          "&:hover": {
            backgroundColor: GRAY_40,
          },
        },
        text: {
          fontWeight: 600,
          padding: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          "&.Mui-checked": {
            color: UN_BLUE,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          color: GRAY_BLACK,
          fontSize: "0.875rem" /** 14px */,
          height: "unset",
          padding: "0.5rem 0" /** 8px 0 */,
        },
        colorError: {
          backgroundColor: colorWithOpacity("#AF4444", 0.1) /** red */,
        },
        colorInfo: {
          backgroundColor: DARK_BLUE,
          borderRadius: "12px",
          color: "white",
          fontSize: "1.125rem" /** 18px */,
          padding: "0.375rem 0" /** 6px 0 */,
        },
        colorPrimary: {
          backgroundColor: colorWithOpacity("#446FAF", 0.1) /** blue */,
          color: GRAY_BLACK,
        },
        colorSuccess: {
          backgroundColor: colorWithOpacity("#44AF69", 0.1) /** green */,
          color: GRAY_BLACK,
        },
        colorWarning: {
          backgroundColor: colorWithOpacity("#494949", 0.1) /** gray */,
          color: GRAY_BLACK,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
      styleOverrides: {
        paper: {
          borderRadius: "20px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${GRAY_BACKGROUND}`,
          justifyContent: "space-between",
          padding: "1.25rem 1.875rem",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: GRAY_BACKGROUND,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        labelPlacementTop: {
          alignItems: "flex-start",
          margin: 0,
        },
        label: {
          fontSize: "0.875rem" /** 14px */,
          lineHeight: "22.1px",
          "&.Mui-disabled": {
            color: GRAY_BLACK,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: GRAY_BLACK,
        },
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          "&.Mui-disabled": {
            backgroundColor: GRAY_INPUT,
          },
        },
        input: {
          padding: "1rem" /** 16px */,
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreOutlined,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "1rem" /** 16px */,
          fontWeight: 500,
          padding: "0.75rem 2rem" /** 12px 32px */,
          textTransform: "none",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scroller: {
          borderBottom: `1px solid ${GRAY_20}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: GRAY_BACKGROUND,
          borderStyle: "solid",
          fontSize: "0.875rem" /** 14px */,
          fontWeight: 400,
          lineHeight: "normal",
          padding: "1.125rem 0 1.125rem 1.875rem" /** 18px 0 18px 30px */,
          "&:first-of-type:not(.MuiTableCell-footer)": {
            borderLeftWidth: "1px",
          },
          "&:last-of-type:not(.MuiTableCell-footer)": {
            borderRightWidth: "1px",
            paddingRight: "1.875rem" /** 30px */,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: GRAY_INPUT,
          ".MuiTableCell-head": {
            borderTopWidth: "1px",
            color: GRAY_80,
            padding: "1.375rem 0 1.375rem 1.875rem",
          },
          ".MuiTableCell-head:first-of-type": {
            borderTopLeftRadius: "0.75rem",
          },
          ".MuiTableCell-head:last-of-type": {
            borderTopRightRadius: "0.75rem",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          ".MuiTablePagination-toolbar": {
            justifyContent: "space-between",
            minHeight: "unset",
            padding: 0,
          },
        },
        displayedRows: {
          lineHeight: "140%",
        },
        spacer: {
          display: "none",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-of-type": {
            "&:not(.table-skeleton)": {
              ".MuiTableCell-body:first-of-type": {
                borderBottomLeftRadius: "0.75rem",
              },
              ".MuiTableCell-body:last-of-type": {
                borderBottomRightRadius: "0.75rem",
              },
            },
            "&.table-skeleton": {
              ".MuiTableCell-body": {
                borderBottom: 0,
                borderImage:
                  "linear-gradient(to bottom, #E4E4E4, transparent) 0 1 0 1",
              },
            },
          },
          "&:hover:not(.MuiTableRow-head)": {
            cursor: "pointer",
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "&:hover > .MuiTableSortLabel-icon": {
            opacity: 1,
            color: GRAY_80,
          },
        },
        icon: {
          color: GRAY_40,
          fontSize: "1.25rem",
          opacity: 1,
          margin: "0 0.625rem",
          "&:hover": {
            color: GRAY_60,
          },
        },
      },
    },
  },
};

export default undidTheme;
