import { Box, Chip, Tooltip } from "@mui/material";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import useEvent from "react-use-event-hook";
import { PageHeader } from "../../components/PageHeader";
import { Table } from "../../components/Table";
import {
  TableColumnInterface,
  TableSort,
} from "../../components/Table/TableTypes";
import { NavTab } from "../../components/Tabs/NavTabs.container";
import { useCredentialsContext } from "../../contexts/credentials/CredentialsContext";
import { useCredentialsActions } from "../../contexts/credentials/UseCredentialsActions";
import Credential, {
  CredentialDataTableFields,
  credentialDataOrderTransform,
} from "../../core/models/Credential";
import {
  DEFAULT_PAGE_SIZE,
  modelDataOrderTransform,
} from "../../core/services/network/NetworkTypes";
import { RoutePath } from "../../defines/route-path";
import { useSearchHelper } from "../../hooks/useSearchHelper";
import {
  DateFormat,
  getCredentialStatusColor,
  getFormattedDate,
} from "../../util/utils";

const Credentials = () => {
  const intl = useIntl();
  const { credentialsResponseData, credentialsLoading } =
    useCredentialsContext();
  const { dispatchGetCredentials, dispatchCleanCredentialsContext } =
    useCredentialsActions();

  const tabs: Array<NavTab> = [
    { label: "credentials.issuedCredentials", href: RoutePath.CREDENTIALS },
  ];

  //#region Load credentials
  const loadCredentials = (
    page: number,
    ordering?: TableSort<CredentialDataTableFields>,
    search?: string
  ) => {
    dispatchGetCredentials({
      filter: { search },
      ordering: modelDataOrderTransform(ordering, credentialDataOrderTransform),
      page,
      size: DEFAULT_PAGE_SIZE,
    }).catch();
  };
  //#endregion

  //#region Table config
  const columns: Array<TableColumnInterface<CredentialDataTableFields>> = [
    { id: "email", label: "credential.email", sortable: true },
    {
      id: "userId",
      label: "credential.userId",
      cellClass: "max-w-xs truncate",
      sortable: true,
      handler: (row: Credential) => (
        <Tooltip title={row.userId}>
          <span>{row.userId}</span>
        </Tooltip>
      ),
    },
    { id: "credDef", label: "credential.credDef", sortable: true },
    { id: "schema", label: "credential.schema", sortable: true },
    {
      id: "status",
      label: "credential.status",
      sortable: true,
      handler: (row: Credential) => (
        <Chip
          color={getCredentialStatusColor(row.status)}
          label={
            row.status
              ? intl.formatMessage({
                  id: `credential.status.${row.status.toLowerCase()}`,
                })
              : "-"
          }
        />
      ),
    },
    {
      id: "issuedOn",
      label: "credential.issuedOn",
      sortable: true,
      handler: (row: Credential) => {
        return row.issuedOn
          ? getFormattedDate(row.issuedOn, DateFormat.DayOfMonth)
          : "-";
      },
    },
  ];
  //#endregion

  //#region Search config
  const onSearch = useEvent(({ page, ordering, search }) => {
    loadCredentials(page, ordering, search);
  });

  const searchHelper = useSearchHelper<CredentialDataTableFields>({
    onSearch,
  });
  //#endregion

  //#region Cleanup
  useEffect(() => {
    return () => dispatchCleanCredentialsContext();
  }, []);
  //#endregion

  return (
    <Box
      id="pageWrapper"
      className="w-full flex flex-col flex-1 space-y-[1.875rem]"
    >
      <PageHeader title="credentials" tabs={tabs} />
      <Box className="h-full">
        <Table
          {...searchHelper.tableProps}
          columns={columns}
          data={credentialsResponseData?.data}
          count={credentialsResponseData?.count}
          loading={credentialsLoading}
          onSearch={searchHelper.setSearch}
        />
      </Box>
    </Box>
  );
};

export { Credentials };
