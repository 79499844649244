import { debounce } from "lodash";
import { useCredentialDefinitionsActions } from "../credentialDefinitions/UseCredentialDefinitionsActions";
import { useCredentialsActions } from "../credentials/UseCredentialsActions";
import useLanguageActions from "../language/UseLanguageActions";
import { useSchemasContext } from "../schemas/SchemasContext";
import { useSchemasActions } from "../schemas/UseSchemasActions";
import { useUsersActions } from "../users/UseUsersActions";
import { useProofsActions } from "../proofs/UseProofsActions";

const useCommonActions = () => {
  const { dispatchCleanLanguageContext } = useLanguageActions();
  const { dispatchCleanSchemasContext } = useSchemasActions();
  const { dispatchCleanCredentialDefinitionsContext } =
    useCredentialDefinitionsActions();
  const { dispatchCleanCredentialsContext } = useCredentialsActions();
  const { dispatchCleanProofsContext } = useProofsActions();
  const { dispatchCleanUsersContext } = useUsersActions();

  const { setSchemasError } = useSchemasContext();

  const dispatchLogoutUser = () => {
    dispatchCleanLanguageContext();
    dispatchCleanSchemasContext();
    dispatchCleanCredentialDefinitionsContext();
    dispatchCleanCredentialsContext();
    dispatchCleanProofsContext();
    dispatchCleanUsersContext();
  };

  const dispatchClearErrors = debounce(() => {
    setSchemasError();
  }, 100);

  return { dispatchLogoutUser, dispatchClearErrors };
};

export default useCommonActions;
