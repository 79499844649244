export enum Language {
  Arabic = "ar",
  Chinese = "zh",
  English = "en",
  French = "fr",
  Russian = "ru",
  Spanish = "es",
}

export const LangNameMap = {
  ar: "Arabic",
  zh: "Chinese",
  en: "English",
  fr: "French",
  ru: "Russian",
  es: "Spanish",
} as const;

export enum LanguageDirection {
  LeftToRight = "ltr",
  RightToLeft = "rtl",
}

export const DEFAULT_LANGUAGE = Language.English;
export const SUPPORTED_LANGUAGES = Object.values(Language);
