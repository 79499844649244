/**
 * CUSTOM
 * This code has been developed for this solution.
 */
import User, {
  UserCreateData,
  UserInvitationData,
  UsersQueryData,
  UserUpdateData,
} from "../../core/models/User";
import NetworkServiceFactory from "../../core/services/network/NetworkService";
import { DEFAULT_QUERY_DATA } from "../../core/services/network/NetworkTypes";
import { ORGS_WITH_OIDC } from "../../defines/organizations";
import { useAuthContext } from "../authentication/AuthenticationContext";
import { useUsersContext } from "./UsersContext";

const useUsersActions = () => {
  const {
    setUsersResponseData,
    setUsersLoading,
    setSelectedUser,
    setUsersError,
    usersResponseData,
  } = useUsersContext();
  const { userInfo } = useAuthContext();

  const dispatchGetUsers = async (
    query: UsersQueryData = DEFAULT_QUERY_DATA()
  ) => {
    let cancelLoading = false;
    setTimeout(() => {
      if (!cancelLoading) {
        setUsersLoading(true);
      }
    }, 400);
    const networkService = NetworkServiceFactory.shared;
    try {
      const data = await networkService.getUsers(query);
      setUsersResponseData(data);
      setUsersError(undefined);
      return data;
    } catch (e: any) {
      setUsersError(e);
    } finally {
      cancelLoading = true;
      setUsersLoading(false);
    }
  };

  const dispatchGetUser = (userId: number) => {
    const networkService = NetworkServiceFactory.shared;
    networkService
      .getUser(userId)
      .then((user) => {
        setSelectedUser(user);
        setUsersError(undefined);
      })
      .catch((e) => {
        setUsersError(e);
      });
  };

  const dispatchCreateUser = (query: UserCreateData) => {
    const asyncCall = async () => {
      try {
        let data: User;

        if (userInfo && ORGS_WITH_OIDC.includes(userInfo.organization)) {
          const result = await Promise.all([
            NetworkServiceFactory.shared.postUser(query),
            NetworkServiceFactory.shared.postUserOIDC(query),
          ]);
          data = result[0];
        } else {
          data = await NetworkServiceFactory.shared.postUser(query);
        }
        try {
          if (usersResponseData) {
            const data =
              await NetworkServiceFactory.shared.getUsers(usersResponseData);
            setUsersResponseData(data);
          }
        } catch (e) {
          // Pass
        }
        setSelectedUser(data);
        setUsersError(undefined);
      } catch (e: any) {
        setUsersError(e);
      }
    };
    asyncCall().catch();
  };

  const dispatchUpdateUser = (query: UserUpdateData) => {
    const asyncCall = async () => {
      try {
        const data = await NetworkServiceFactory.shared.patchUser(query);
        try {
          if (usersResponseData) {
            const data =
              await NetworkServiceFactory.shared.getUsers(usersResponseData);
            setUsersResponseData(data);
          }
        } catch (e) {
          // Pass
        }
        setSelectedUser(data);
        setUsersError(undefined);
      } catch (e: any) {
        setUsersError(e);
      }
    };
    asyncCall().catch();
  };

  // const dispatchSendUserInvitation = async (query: UserInvitationData) => {
  //   try {
  //     await NetworkServiceFactory.shared.sendUserInvitation(query);
  //     setUsersError(undefined);
  //   } catch (e: any) {
  //     setUsersError(e);
  //   }
  // };

  const dispatchDeleteUser = async (userId: number) => {
    try {
      await NetworkServiceFactory.shared.deleteUser(userId);
      setUsersError(undefined);
    } catch (e: any) {
      setUsersError(e);
    }
  };

  const dispatchCleanUsersContext = () => {
    setUsersResponseData(undefined);
    setUsersError();
    setSelectedUser();
  };

  return {
    dispatchGetUsers,
    dispatchGetUser,
    dispatchCleanUsersContext,
    dispatchCreateUser,
    dispatchUpdateUser,
    // dispatchSendUserInvitation,
    dispatchDeleteUser,
  };
};

export { useUsersActions };
