import createCache from "@emotion/cache";
import { createTheme } from "@mui/material";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { LanguageDirection } from "../lngProvider/constants";
import undidTheme from "../themes/undid-theme";

export const createAppTheme = (
  languageDirection: LanguageDirection = LanguageDirection.LeftToRight
) => {
  return createTheme({ ...undidTheme, direction: languageDirection });
};

export const createLtrCache = () => {
  return createCache({ key: "muiltr", prepend: true });
};

export const createRtlCache = () => {
  return createCache({
    key: "muirtl",
    prepend: true,
    // prefixer is the only stylis plugin by default, so when
    // overriding the plugins you need to include it explicitly
    // if you want to retain the auto-prefixing behavior.
    stylisPlugins: [prefixer, rtlPlugin],
  });
};
