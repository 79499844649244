import { Box } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useEvent from "react-use-event-hook";
import { PageHeader } from "../../components/PageHeader";
import { Table } from "../../components/Table";
import {
  TableColumnInterface,
  TableSort,
} from "../../components/Table/TableTypes";
import { NavTab } from "../../components/Tabs/NavTabs.container";
import { useProofsContext } from "../../contexts/proofs/ProofsContext";
import { useProofsActions } from "../../contexts/proofs/UseProofsActions";
import Proof, {
  ProofDataTableFields,
  proofDataOrderTransform,
} from "../../core/models/Proof";
import {
  DEFAULT_PAGE_SIZE,
  modelDataOrderTransform,
} from "../../core/services/network/NetworkTypes";
import { RoutePath } from "../../defines/route-path";
import { useSearchHelper } from "../../hooks/useSearchHelper";
import { DateFormat, getFormattedDate } from "../../util/utils";

const Proofs = () => {
  const navigate = useNavigate();
  const { proofsResponseData, proofsLoading } = useProofsContext();
  const { dispatchGetProofs, dispatchCleanProofsContext } = useProofsActions();

  const tabs: Array<NavTab> = [
    { label: "proofs.allProofs", href: RoutePath.PROOFS },
  ];

  //#region Load proofs
  const loadProofs = (
    page: number,
    ordering?: TableSort<ProofDataTableFields>,
    search?: string
  ) => {
    dispatchGetProofs({
      filter: { search },
      ordering: modelDataOrderTransform(ordering, proofDataOrderTransform),
      page,
      size: DEFAULT_PAGE_SIZE,
    }).catch();
  };
  //#endregion

  //#region Table config
  const columns: Array<TableColumnInterface<ProofDataTableFields>> = [
    { id: "name", label: "proof.name", sortable: true },
    {
      id: "createdOn",
      label: "proof.createdOn",
      sortable: true,
      handler: (row: Proof) => {
        return row.createdOn
          ? getFormattedDate(row.createdOn, DateFormat.DayOfMonth)
          : "-";
      },
    },
  ];
  //#endregion

  //#region Search config
  const onSearch = useEvent(({ page, ordering, search }) => {
    loadProofs(page, ordering, search);
  });

  const searchHelper = useSearchHelper<ProofDataTableFields>({
    onSearch,
  });
  //#endregion

  //#region Actions config
  const goToCreateProof = () => {
    navigate(RoutePath.NEW_PROOF);
  };

  const goToEditProof = (proof: Proof) => {
    navigate(RoutePath.EDIT_PROOF.replace(":id", proof.id));
  };
  //#endregion

  //#region Cleanup
  useEffect(() => {
    return () => dispatchCleanProofsContext();
  }, []);
  //#endregion

  return (
    <Box
      id="pageWrapper"
      className="w-full flex flex-col flex-1 space-y-[1.875rem]"
    >
      <PageHeader title="proofs" tabs={tabs} />
      <Box className="h-full">
        <Table
          {...searchHelper.tableProps}
          columns={columns}
          data={proofsResponseData?.data}
          count={proofsResponseData?.count}
          loading={proofsLoading}
          actionTitle="table.add"
          onSearch={searchHelper.setSearch}
          onClickAction={goToCreateProof}
          onClickRow={goToEditProof}
        />
      </Box>
    </Box>
  );
};

export { Proofs };
