import { SelectOptions } from "../components/Select/Select.container";

export enum AttributeType {
  Boolean = "bool",
  Country = "country",
  Date = "date",
  Email = "email",
  Number = "num",
  Text = "str",
}

export const ATTRIBUTE_TYPES_OPTIONS: SelectOptions<AttributeType> =
  Object.values(AttributeType).map((type) => {
    return { value: type, label: `schema.attribute.type.${type}` };
  });
