/**
 * CUSTOM
 * This code has been developed for this solution.
 */
import { Organization } from "../../defines/organizations";
import { QueryData, ResponseData } from "../services/network/NetworkTypes";

export default class CredentialDefinition {
  constructor(
    public id: number,
    public name: string,
    public credentialId: string,
    public schemaId: number,
    public schemaName: string,
    public createdBy: Organization,
    public createdOn: Date
  ) {}

  static fromJSON(json: Record<string, any>): CredentialDefinition {
    /*
   {
            "id": 8,
            "schema": "2jYo5xjBZe682KxZpxUyqY:2:test_12:1.12",
            "schema_name": "test_schema_12",
            "organization_name": "UNICC",
            "created": "2023-11-30T13:56:58.450746Z",
            "modified": "2023-12-01T09:35:53.044779Z",
            "synced": true,
            "external": false,
            "name": "test15",
            "credential_id": "2jYo5xjBZe682KxZpxUyqY:3:CL:32:test15",
            "enabled": true,
            "support_revocation": true,
            "revocation_registry_size": 100,
            "creator": 1
         },
 * */

    return new CredentialDefinition(
      json.id,
      json.name,
      json.credential_id,
      json.schema,
      json.schema_name,
      json.organization_name ?? json.organization,
      json.created
    );
  }

  toJSON(): CredentialDefinitionUpdateData {
    return {
      id: this.id,
      name: this.name,
    };
  }
}

export type CredentialDefinitionCreateData = {
  name: string;
  schema: string;
  organization_name: Organization;
};

export type CredentialDefinitionUpdateData =
  Partial<CredentialDefinitionCreateData> & {
    id: number;
  };

export type CredentialDefinitionDataFilter =
  | "search"
  | "organization__name"
  | "enabled";

// Backend keys you can sort with
export type CredentialDefinitionDataOrder =
  | "name"
  | "credential_id"
  | "schema__name"
  | "organization__name"
  | "created"
  | "enabled";

// This will help transform the sorting from local to backend keys.
export const credentialDefinitionDataOrderTransform: Record<
  string,
  CredentialDefinitionDataOrder
> = {
  name: "name",
  credentialId: "credential_id",
  schemaName: "schema__name",
  createdBy: "organization__name",
  createdOn: "created",
  enabled: "enabled",
};

// These are the keys used for table listings
export type CredentialDefinitionDataTableFields =
  keyof typeof credentialDefinitionDataOrderTransform;

export type CredentialDefinitionQueryData = QueryData<
  CredentialDefinitionDataFilter,
  CredentialDefinitionDataOrder
>;

export type CredentialDefinitionResponseData = ResponseData<
  CredentialDefinition,
  CredentialDefinitionDataFilter,
  CredentialDefinitionDataOrder
>;
