import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import useEvent from "react-use-event-hook";
import { PageHeader } from "../../components/PageHeader";
import { Table } from "../../components/Table";
import {
  TableColumnInterface,
  TableSort,
} from "../../components/Table/TableTypes";
import { NavTab } from "../../components/Tabs/NavTabs.container";
import { useUsersActions } from "../../contexts/users/UseUsersActions";
import { useUsersContext } from "../../contexts/users/UsersContext";
import User, {
  UserDataTableFields,
  UserRole,
  userDataOrderTransform,
} from "../../core/models/User";
import {
  DEFAULT_PAGE_SIZE,
  modelDataOrderTransform,
} from "../../core/services/network/NetworkTypes";
import { RoutePath } from "../../defines/route-path";
import { useSearchHelper } from "../../hooks/useSearchHelper";
import { DateFormat, getFormattedDate } from "../../util/utils";

const Users = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { usersResponseData, usersLoading } = useUsersContext();
  const { dispatchGetUsers, dispatchCleanUsersContext } = useUsersActions();

  const tabs: Array<NavTab> = [
    { label: "users.allUsers", href: RoutePath.USERS },
  ];

  //#region Load users
  const loadUsers = (
    page: number,
    ordering?: TableSort<UserDataTableFields>,
    search?: string
  ) => {
    dispatchGetUsers({
      filter: {
        search,
        group: UserRole.Web,
      },
      ordering: modelDataOrderTransform(ordering, userDataOrderTransform),
      page,
      size: DEFAULT_PAGE_SIZE,
    }).catch();
  };
  //#endregion

  //#region Table config
  const columns: Array<TableColumnInterface<UserDataTableFields>> = [
    { id: "firstName", label: "user.firstName", sortable: true },
    { id: "lastName", label: "user.lastName", sortable: true },
    { id: "email", label: "user.email", sortable: true },
    {
      id: "roles",
      label: "user.role",
      sortable: true,
      handler: (row: User) =>
        row.roles
          .map((role) => intl.formatMessage({ id: `user.role.${role}` }))
          .join(", "),
    },
    {
      id: "createdOn",
      label: "user.createdOn",
      sortable: true,
      handler: (row: User) => {
        return row.createdOn
          ? getFormattedDate(row.createdOn, DateFormat.DayOfMonth)
          : "-";
      },
    },
  ];
  //#endregion

  //#region Search config
  const onSearch = useEvent(({ page, ordering, search }) => {
    loadUsers(page, ordering, search);
  });

  const searchHelper = useSearchHelper<UserDataTableFields>({
    onSearch,
  });
  //#endregion

  //#region Actions config
  const goToCreateUser = () => {
    navigate(RoutePath.NEW_USER);
  };

  const goToEditUser = (row: User) => {
    navigate(RoutePath.EDIT_USER.replace(":id", String(row.id)));
  };
  //#endregion

  //#region Cleanup
  useEffect(() => {
    return () => dispatchCleanUsersContext();
  }, []);
  //#endregion

  return (
    <Box
      id="pageWrapper"
      className="w-full flex flex-col flex-1 space-y-[1.875rem]"
    >
      <PageHeader title="users" tabs={tabs} />
      <Box className="h-full">
        <Table
          {...searchHelper.tableProps}
          columns={columns}
          data={usersResponseData?.data}
          count={usersResponseData?.count}
          loading={usersLoading}
          actionTitle="table.add"
          onSearch={searchHelper.setSearch}
          onClickAction={goToCreateUser}
          onClickRow={goToEditUser}
        />
      </Box>
    </Box>
  );
};

export { Users };
