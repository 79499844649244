import { createContext, useContext, useEffect, useState } from "react";
import CredentialDefinition, {
  CredentialDefinitionResponseData,
} from "../../core/models/CredentialDefinition";
import NetworkingServiceError from "../../core/services/network/NetworkServiceError";
import appConfig from "../../util/appConfig";

// This context allows the app to get the credential Definitions information

export type CredentialDefinitionError = NetworkingServiceError;

// Create type
export type CredentialDefinitionsDataType = {
  credentialDefinitionsResponseData?: CredentialDefinitionResponseData;
  setCredentialDefinitionsResponseData: (
    responseData?: CredentialDefinitionResponseData
  ) => void;
  selectedCredentialDefinition?: CredentialDefinition;
  setSelectedCredentialDefinition: (
    credentialDefinition?: CredentialDefinition
  ) => void;
  credentialDefinitionsLoading: boolean;
  setCredentialDefinitionsLoading: (isLoading: boolean) => void;
  credentialDefinitionsError: CredentialDefinitionError | undefined;
  setCredentialDefinitionsError: (error?: CredentialDefinitionError) => void;
};

// Create context for type
export const CredentialDefinitionsDataContext =
  // @ts-ignore
  createContext<CredentialDefinitionsDataType>();

//Create provider of the context
export const CredentialDefinitionsDataProvider = ({ children }: any) => {
  const [selectedCredentialDefinition, setSelectedCredentialDefinition] =
    useState<CredentialDefinition>();
  const [
    credentialDefinitionsResponseData,
    setCredentialDefinitionsResponseData,
  ] = useState<CredentialDefinitionResponseData>();
  const [credentialDefinitionsLoading, setCredentialDefinitionsLoading] =
    useState<boolean>(false);
  const [credentialDefinitionsError, setCredentialDefinitionsError] =
    useState<CredentialDefinitionError>();

  const value = {
    credentialDefinitionsResponseData,
    setCredentialDefinitionsResponseData,
    selectedCredentialDefinition,
    setSelectedCredentialDefinition,
    credentialDefinitionsLoading,
    setCredentialDefinitionsLoading,
    credentialDefinitionsError,
    setCredentialDefinitionsError,
  };
  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugCredentialDefinitions && selectedCredentialDefinition)
      console.debug(
        "[CredentialDefinitions][SelectedCredentialDefinition] ",
        selectedCredentialDefinition
      );
  }, [selectedCredentialDefinition]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (
      appConfig.debugCredentialDefinitions &&
      credentialDefinitionsResponseData
    )
      console.debug(
        "[CredentialDefinitions][CredentialDefinitionsResponseData] ",
        credentialDefinitionsResponseData
      );
  }, [credentialDefinitionsResponseData]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugCredentialDefinitions && credentialDefinitionsLoading)
      console.debug(
        "[CredentialDefinitions][CredentialDefinitionsLoading] ",
        credentialDefinitionsLoading
      );
  }, [credentialDefinitionsLoading]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugCredentialDefinitions && credentialDefinitionsError)
      console.warn(
        "[CredentialDefinitions][Error] ",
        credentialDefinitionsError?.userMessage(),
        credentialDefinitionsError?.debugMessage()
      );
  }, [credentialDefinitionsError]);
  return (
    <CredentialDefinitionsDataContext.Provider value={value}>
      {children}
    </CredentialDefinitionsDataContext.Provider>
  );
};

export const useCredentialDefinitionsContext = () =>
  useContext(CredentialDefinitionsDataContext);
