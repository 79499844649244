import { createContext, useContext, useEffect, useState } from "react";
import Credential, {
  CredentialResponseData,
} from "../../core/models/Credential";
import NetworkingServiceError from "../../core/services/network/NetworkServiceError";
import appConfig from "../../util/appConfig";

// This context allows the app to get the credentials information

export type CredentialError = NetworkingServiceError;

// Create type
export type CredentialsDataType = {
  credentialsResponseData?: CredentialResponseData;
  setCredentialsResponseData: (responseData?: CredentialResponseData) => void;
  credentialsLoading: boolean;
  setCredentialsLoading: (isLoading: boolean) => void;
  credentialsError: CredentialError | undefined;
  setCredentialsError: (error?: CredentialError) => void;
};

// Create context for type
// @ts-ignore
export const CredentialsDataContext = createContext<CredentialsDataType>();

//Create provider of the context
export const CredentialsDataProvider = ({ children }: any) => {
  const [credentialsResponseData, setCredentialsResponseData] =
    useState<CredentialResponseData>();
  const [credentialsLoading, setCredentialsLoading] = useState<boolean>(false);
  const [credentialsError, setCredentialsError] = useState<CredentialError>();

  const value = {
    credentialsResponseData,
    setCredentialsResponseData,
    credentialsLoading,
    setCredentialsLoading,
    credentialsError,
    setCredentialsError,
  };

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugCredentials && credentialsResponseData)
      console.debug(
        "[Credentials][CredentialsResponseData] ",
        credentialsResponseData
      );
  }, [credentialsResponseData]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugCredentials && credentialsLoading)
      console.debug("[Credentials][CredentialsLoading] ", credentialsLoading);
  }, [credentialsLoading]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugCredentials && credentialsError)
      console.warn(
        "[Credentials][Error] ",
        credentialsError?.userMessage(),
        credentialsError?.debugMessage()
      );
  }, [credentialsError]);
  return (
    <CredentialsDataContext.Provider value={value}>
      {children}
    </CredentialsDataContext.Provider>
  );
};

export const useCredentialsContext = () => useContext(CredentialsDataContext);
