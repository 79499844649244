export default abstract class BaseError extends Error {
  public code = 0;
  protected params: any;
  protected constructor(code = 0, message = "", ...params: any[]) {
    super(...params);

    // Maintains proper stack trace for where our error was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BaseError);
    }

    this.name = "BaseError";
    this.code = code;
    this.message = message;
    this.params = params;
  }

  /**
   * This message helps to debug
   */
  debugMessage(): Record<string, any> {
    const detail = this.params.map((element: any) => {
      if (element?.response) {
        return element.response;
      }
      if (element instanceof Error) {
        return element.message;
      } else if (typeof element !== "string" && !(element instanceof String)) {
        return JSON.stringify(element, null, 2);
      }
      return element;
    });

    return {
      error: this.name,
      code: this.code,
      message: this.message,
      detail: detail,
      stack: this.stack,
      original: this.params,
    };
  }

  abstract userMessage(): string;
}
