import { Organization } from "./organizations";

declare global {
  interface Window {
    ENV_ORG: Organization;
    ENV_ENVIRONMENT: Environment;
    ENV_APP_URL: string;
    ENV_IDMANAGER_URL: string;
    ENV_OIDC_URL: string;
    ENV_OIDC_CLIENTID: string;
  }
}

export enum Environment {
  Dev = "dev",
  QA = "qa",
  Preprod = "preprod",
  Prod = "prod",
}
