import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";
import { TableSkeletonInterface } from "../TableTypes";

const ROWS = 5;

const TableSkeleton = <IdField extends string>({
  columns,
  numberOfRows = ROWS,
}: TableSkeletonInterface<IdField>) => {
  const rows = Array(numberOfRows).fill(1);

  return (
    <TableBody data-testid="tableSkeleton">
      {rows.map((_, rowNumber) => {
        const rowId = `row.${rowNumber}`;
        let cellCount = 0;

        return (
          <TableRow
            key={rowId}
            id={rowId}
            data-testid={`tableSkeleton.${rowId}`}
            className="table-skeleton"
          >
            {columns &&
              columns.length > 0 &&
              columns.map((column) => {
                const cellId = `${rowId}-col-${cellCount++}`;
                const opacity = 1 - rowNumber * (1 / numberOfRows);

                return (
                  <TableCell
                    key={cellId}
                    id={cellId}
                    data-testid="tableSkeleton.cell"
                    align={column.align || "left"}
                  >
                    <Skeleton
                      data-testid="tableSkeleton.skeleton"
                      variant="rounded"
                      animation="wave"
                      sx={{ opacity }}
                    />
                  </TableCell>
                );
              })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export { TableSkeleton };
