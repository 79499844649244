const noDebugEnvironment =
  process.env.NODE_ENV === "test" || process.env.NODE_ENV === "production";

// const rootKey = process.env.REACT_APP_ROOT_KEY;
const debug = !noDebugEnvironment;

// if (!rootKey) console.error("NO ROOT KEY FOUND, check REACT_APP_ROOT_KEY");

let appConfig = {
  debug: debug,
  // rootKey: rootKey,
  cacheDisable: false,
  debugCache: debug && true,
  debugNetworking: debug && true,
  debugAuth: debug && true,
  debugLang: debug && false,
  debugSchemas: debug && true,
  debugCredentialDefinitions: debug && true,
  debugCredentials: debug && true,
  debugProofs: debug && true,
  debugUsers: debug && true,
};

export default appConfig;
