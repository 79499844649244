export enum RoutePath {
  CREDENTIALS = "/credentials",
  DASHBOARD = "/dashboard",
  HOME = "/",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_OF_SERVICE = "/terms-and-conditions",
  PROOFS = "/proofs",
  NEW_PROOF = "/proofs/new",
  EDIT_PROOF = "/proofs/:id",
  SCHEMAS_CREDENTIALS = "/schemas-credentials",
  CREDENTIAL_DEFINITIONS = "/schemas-credentials/credential-definitions",
  NEW_CREDENTIAL_DEFINITION = "/schemas-credentials/credential-definitions/new",
  EDIT_CREDENTIAL_DEFINITION = "/schemas-credentials/credential-definitions/:id",
  SCHEMAS = "/schemas-credentials/schemas",
  NEW_SCHEMA = "/schemas-credentials/schemas/new",
  EDIT_SCHEMA = "/schemas-credentials/schemas/:id",
  USERS = "/users",
  NEW_USER = "/users/new",
  EDIT_USER = "/users/:id",
}

export const ROUTE_PATH_VALUES = Object.values(RoutePath);
