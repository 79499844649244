/**
 * CUSTOM
 * This code has been developed for this solution.
 */
import { AttributeType } from "../../defines/attribute-type";
import { Organization } from "../../defines/organizations";
import { QueryData, ResponseData } from "../services/network/NetworkTypes";

export type SchemaAttribute = {
  name: string;
  type: AttributeType;
};

export type SchemaAttributes = Array<SchemaAttribute>;

export default class Schema {
  constructor(
    public id: number,
    public name: string,
    public version: string,
    public schemaId: string,
    public enabled: boolean,
    public createdBy: Organization,
    public createdOn: Date,
    public attributes: SchemaAttributes
  ) {}

  static fromJSON(json: Record<string, any>): Schema {
    /*
   {
            "id": 41,
            "schema_json": {
                "schema_name": "test_schema_arribas",
                "schema_version": "1.0",
                "attributes": {
                    "first_name": "str",
                    "email_address": "str"
                }
            },
            "creator": {
                "username": "arribas@unicc.org"
            },
            "created": "2024-02-08T08:40:33.941568Z",
            "modified": "2024-02-08T08:40:35.251384Z",
            "enabled": true,
            "synced": true,
            "external": false,
            "name": "test_schema_arribas",
            "schema_id": "2jYo5xjBZe682KxZpxUyqY:2:test_schema_arribas:1.0",
            "organization": "UNICC"
         },
 * */
    const attributes: Record<string, AttributeType> =
      json.schema_json?.attributes;

    return new Schema(
      json.id,
      json.name,
      json.schema_json?.schema_version,
      json.schema_id,
      json.enabled,
      json.organization,
      json.created,
      Object.entries(attributes).map((attr) => {
        const [name, type] = attr;
        return { name, type };
      })
    );
  }

  toJSON(): SchemaUpdateData {
    return {
      id: this.id,
      name: this.name,
      schema_json: {
        schema_name: this.name,
        attributes: this.attributes.reduce(
          (obj, attr) => {
            obj[attr.name] = attr.type;
            return obj;
          },
          {} as Record<string, AttributeType>
        ),
      },
    };
  }
}

export type SchemaCreateData = {
  name: string;
  organization_name: Organization;
  schema_json: {
    schema_name: string;
    attributes: Record<string, AttributeType>;
  };
};

export type SchemaUpdateData = Partial<SchemaCreateData> & {
  id: number;
};

export type SchemaDataFilter = "search" | "organization__name" | "enabled";

// Backend keys you can sort with
export type SchemaDataOrder =
  | "name"
  | "schema_json__schema_version"
  | "schema_id"
  | "enabled"
  | "organization__name"
  | "created";

// This will help transform the sorting from local to backend keys.
export const schemaDataOrderTransform: Record<string, SchemaDataOrder> = {
  name: "name",
  version: "schema_json__schema_version",
  schemaId: "schema_id",
  enabled: "enabled",
  createdBy: "organization__name",
  createdOn: "created",
};

// These are the keys used for table listings
export type SchemaDataTableFields = keyof typeof schemaDataOrderTransform;

export type SchemaQueryData = QueryData<SchemaDataFilter, SchemaDataOrder>;

export type SchemaResponseData = ResponseData<
  Schema,
  SchemaDataFilter,
  SchemaDataOrder
>;
