import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import IntlMessages, { TranslationKey } from "../IntlMessages/IntlMessages";
import { NavTabs } from "../Tabs";
import { NavTab } from "../Tabs/NavTabs.container";
import { InfoOutlined } from "@mui/icons-material";
import cn from "classnames";

type HeaderProps = {
  title: TranslationKey;
  tooltip?: TranslationKey;
  tabs?: Array<NavTab>;
  divider?: boolean;
  disableSave?: boolean;
  containerClass?: string;
  saveTitle?: TranslationKey;
  onCancel?(): void;
  onSave?(): void;
};

const PageHeader = ({
  title,
  tooltip,
  tabs,
  divider = false,
  disableSave = false,
  containerClass,
  saveTitle,
  onCancel,
  onSave,
}: HeaderProps) => {
  return (
    <Box
      id="pageHeader"
      data-testid="pageHeader"
      className={cn("min-w-full space-y-[1.875rem]", containerClass)}
    >
      <Box className="space-y-[1.25rem]">
        <Box className="flex items-center justify-between">
          <Box className="flex items-center space-x-[0.625rem]">
            <Typography data-testid="pageHeader.title" variant="h1">
              <IntlMessages id={title} />
            </Typography>
            {tooltip && (
              <Tooltip title={<IntlMessages id={tooltip} />}>
                <IconButton aria-label="more info" className="p-0">
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {(onCancel || onSave) && (
            <Box className="flex space-x-[1.375rem]">
              {onCancel && (
                <Button
                  data-testid="pageHeader.cancel"
                  variant="text"
                  color="error"
                  onClick={onCancel}
                >
                  <IntlMessages id="cancel" />
                </Button>
              )}
              {onSave && (
                <Button
                  data-testid="pageHeader.save"
                  disabled={disableSave}
                  onClick={onSave}
                >
                  <IntlMessages id={saveTitle ?? "save"} />
                </Button>
              )}
            </Box>
          )}
        </Box>
        {divider && <Divider data-testid="pageHeader.divider" />}
      </Box>
      {tabs && (
        <Box data-testid="pageHeader.tabs">
          <NavTabs tabs={tabs} />
        </Box>
      )}
    </Box>
  );
};

export { PageHeader };
