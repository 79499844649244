import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import type { Props as ReactIntlFormattedMessageProps } from "react-intl/src/components/message";
import enMessages from "../../lngProvider/locales/en_UK.json";

export type TranslationKey = keyof typeof enMessages;
type FormattedMessageProps = ReactIntlFormattedMessageProps<
  Record<string, ReactNode>
> & {
  id?: TranslationKey;
};

const InjectMassage = ({ id, ...props }: FormattedMessageProps) => (
  <FormattedMessage id={id} {...props} />
);

export default InjectMassage;
