import {
  Add,
  ChevronRight,
  DeleteOutline,
  EditOutlined,
  ExpandMore,
} from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Unstable_Grid2 as Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import { PageHeader } from "../../components/PageHeader";
import { TextField } from "../../components/TextField";
import { useCredentialDefinitionsContext } from "../../contexts/credentialDefinitions/CredentialDefinitionsContext";
import { useCredentialDefinitionsActions } from "../../contexts/credentialDefinitions/UseCredentialDefinitionsActions";
import { useProofsContext } from "../../contexts/proofs/ProofsContext";
import { useProofsActions } from "../../contexts/proofs/UseProofsActions";
import { ProofCreateData, RequestedAttribute } from "../../core/models/Proof";
import { notify } from "../../libs/observables/notification";
import { GRAY_60 } from "../../themes/colors";
import {
  AttributeCredDefs,
  AttributesModal,
} from "./attributesModal.container";
import { QrCodeModal } from "./qrCodeModal.container";

const ProofNewEdit = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { selectedProof, proofsError } = useProofsContext();
  const {
    dispatchGetProof,
    dispatchCreateProof,
    dispatchUpdateProof,
    dispatchCleanProofsContext,
  } = useProofsActions();
  const { credentialDefinitionsResponseData } =
    useCredentialDefinitionsContext();
  const { dispatchGetCredentialDefinitions } =
    useCredentialDefinitionsActions();
  const [name, setName] = useState<string>();
  const [originalAttributes, setOriginalAttributes] = useState<
    Array<AttributeCredDefs>
  >([]);
  const [attributes, setAttributes] = useState<Array<AttributeCredDefs>>([]);
  const [attributeToEdit, setAttributeToEdit] = useState<AttributeCredDefs>();
  const [attributeToEditIndex, setAttributeToEditIndex] = useState<number>();
  const [openSelectAttributes, setOpenSelectAttributes] =
    useState<boolean>(false);
  const [openQrCodeModal, setOpenQrCodeModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  //#region Aux
  const attributesCount = attributes.length;
  const credDefsCount = attributes
    .map((attrObj) => attrObj.credDefs.map((credDef) => credDef.credentialId))
    .flat(2)
    .filter((value, index, array) => array.indexOf(value) === index).length;
  const attributesSelectedText = `${attributesCount} ${intl.formatMessage({
    id: "proof.attributes",
  })} | ${credDefsCount} ${intl.formatMessage({
    id: "proof.credentialDefinitions",
  })}`;

  const originalAttributesSorted = JSON.stringify(
    [...originalAttributes]
      .map((attr) => {
        return {
          name: attr.name,
          credDefs: [...attr.credDefs].sort((a, b) =>
            a.credentialId.localeCompare(b.credentialId)
          ),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  );

  const attributesSorted = JSON.stringify(
    [...attributes]
      .map((attr) => {
        return {
          name: attr.name,
          credDefs: [...attr.credDefs].sort((a, b) =>
            a.credentialId.localeCompare(b.credentialId)
          ),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  );

  const formNotChanged =
    !!params.id &&
    !!selectedProof &&
    !!selectedProof.requestedAttributes &&
    originalAttributesSorted === attributesSorted;
  const disableSave =
    !name || !attributes || !attributes.length || formNotChanged;
  //#endregion

  //#region Load and cleanup
  useEffect(() => {
    if (params.id) dispatchGetProof(params.id);
    return () => dispatchCleanProofsContext();
  }, []);

  useEffect(() => {
    if (!params.id) {
      if (selectedProof && !proofsError) {
        notify("success")({ message: "Proof created!" });
        navigate(-1);
      }
    } else {
      if (selectedProof) {
        dispatchGetCredentialDefinitions({
          page: 1,
          size: 1000,
        });
        if (loading && selectedProof && !proofsError) {
          notify("success")({ message: "Proof updated!" });
        } else {
          setName(selectedProof.name);
        }
      }
    }

    setLoading(false);
  }, [selectedProof, proofsError]);

  useEffect(() => {
    if (
      !openSelectAttributes &&
      selectedProof &&
      credentialDefinitionsResponseData
    ) {
      const selectedAttributes: Array<AttributeCredDefs> = [];
      selectedProof.requestedAttributes.forEach((reqAttr) => {
        const { name, restrictions } = reqAttr;
        const credDefsIds = restrictions.map((r) => r.cred_def_id);
        const credDefs = credentialDefinitionsResponseData.data.filter((cd) =>
          credDefsIds.includes(cd.credentialId)
        );
        selectedAttributes.push({
          name,
          credDefs,
          expanded: true,
        });
      });
      setOriginalAttributes(selectedAttributes);
      setAttributes(selectedAttributes);
    }
  }, [credentialDefinitionsResponseData, selectedProof]);
  //#endregion

  //#region Page actions
  const onCancel = () => navigate(-1);

  const onSave = () => {
    if (!name) {
      notify("error")({ message: "Proof name is required!" });
      return;
    }
    if (!attributes) {
      notify("error")({ message: "Proof attributes is required!" });
      return;
    }

    setLoading(true);

    let subjectIdentifier: string = "";
    const requestedAttributes: Array<RequestedAttribute> = [];

    attributes.forEach((attrObj, index) => {
      const { name: attrName, credDefs } = attrObj;

      // TODO A criteria should be defined to get a meaningful subject identifier
      if (index === 0) subjectIdentifier = attrName;

      requestedAttributes.push({
        name: attrName,
        label: attrName,
        restrictions: credDefs.map((cd) => {
          return { cred_def_id: cd.credentialId };
        }),
      });
    });

    const proof: ProofCreateData = {
      id: params.id ?? name.replaceAll(" ", "_"),
      subject_identifier: subjectIdentifier,
      configuration: {
        name,
        version: "1.0",
        non_revoked: {},
        requested_attributes: requestedAttributes,
      },
    };

    if (params.id) {
      dispatchUpdateProof(proof);
    } else {
      dispatchCreateProof(proof);
    }
  };
  //#endregion

  //#region Attributes handlers
  const onSelectAttributes = (selectedAttribute?: AttributeCredDefs) => {
    if (selectedAttribute) {
      const newAttributes = [...attributes];
      if (attributeToEdit && attributeToEditIndex !== undefined) {
        newAttributes.splice(attributeToEditIndex, 1, selectedAttribute);
      } else {
        newAttributes.push(selectedAttribute);
      }
      setAttributes(newAttributes);
    }
    setOpenSelectAttributes(false);
    setAttributeToEdit(undefined);
    setAttributeToEditIndex(undefined);
  };

  const handleOpenAttributesModal = (attrIndex?: number) => {
    setAttributeToEdit(
      attrIndex !== undefined ? attributes.at(attrIndex) : undefined
    );
    setAttributeToEditIndex(attrIndex);
    setOpenSelectAttributes(true);
  };

  const onRemoveAttribute = (attrIndex: number) => {
    const newAttributes = [...attributes];
    newAttributes.splice(attrIndex, 1);
    setAttributes(newAttributes);
  };

  const onToggleCollapseAttribute = (attrIndex: number) => {
    const newAttributes = [...attributes];
    const attr = newAttributes.at(attrIndex);
    attr!.expanded = !attr?.expanded;
    setAttributes(newAttributes);
  };
  //#endregion

  //#region Renderers
  const attributesSelected = useMemo(
    () =>
      attributes.length ? (
        <Box data-testid="proof.attributes" className="space-y-5">
          <Alert severity="info">
            <IntlMessages id="proof.attributesSelected.alert.all" />
          </Alert>
          {attributes.map((attrObj, attrIndex) => {
            const { name: attrName, credDefs, expanded } = attrObj;

            return (
              <Box
                key={attrIndex}
                className="rounded-lg p-5 space-y-5 bg-white"
              >
                {/* Title and actions */}
                <Box className="flex justify-between items-center">
                  <Typography variant="h6" fontWeight={600}>
                    {attrName}
                  </Typography>
                  <Box className="flex space-x-[0.625rem]">
                    <IconButton
                      aria-label="edit attribute"
                      onClick={() => handleOpenAttributesModal(attrIndex)}
                    >
                      <EditOutlined color="secondary" className="text-lg" />
                    </IconButton>
                    <IconButton
                      aria-label="remove attribute"
                      onClick={() => onRemoveAttribute(attrIndex)}
                    >
                      <DeleteOutline color="secondary" className="text-lg" />
                    </IconButton>
                    <IconButton
                      aria-label="collapse attribute"
                      onClick={() => onToggleCollapseAttribute(attrIndex)}
                    >
                      {expanded ? (
                        <ExpandMore color="secondary" className="text-lg" />
                      ) : (
                        <ChevronRight color="secondary" className="text-lg" />
                      )}
                    </IconButton>
                  </Box>
                </Box>
                {/* Credential definitions of attribute */}
                {expanded ? (
                  <Box className="space-y-2">
                    <Alert severity="info" icon={false}>
                      <IntlMessages id="proof.attributesSelected.alert.atLeastOne" />
                    </Alert>
                    <Box>
                      {credDefs.map((credDef, cdIndex) => {
                        const { createdBy, credentialId, name } = credDef;

                        return (
                          <Box key={cdIndex} className="py-3 space-y-1">
                            <Box className="flex items-center space-x-2">
                              <Typography fontWeight={500}>
                                {createdBy}
                              </Typography>
                              <Typography fontWeight={500}>/</Typography>
                              <Typography fontWeight={500}>{name}</Typography>
                            </Box>
                            <Tooltip title={credentialId}>
                              <Typography
                                variant="body2"
                                color={GRAY_60}
                                className="truncate"
                              >
                                {credentialId}
                              </Typography>
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            );
          })}
        </Box>
      ) : (
        <></>
      ),
    [attributes]
  );
  //#endregion

  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress data-testid="loader" />
      </Backdrop>
      <Box
        data-testid="proof"
        className="min-w-full space-y-[1.875rem] flex-box"
      >
        <PageHeader
          title={params.id ? "proof.details" : "proof.new"}
          tooltip="proofs"
          divider
          disableSave={disableSave}
          containerClass="flex-initial"
          onCancel={onCancel}
          onSave={onSave}
        />
        {/* Form */}
        <Grid container columnSpacing={4} className="flex-auto">
          {/* Left panel */}
          <Grid xs={6} className="space-y-[1.875rem]">
            <TextField
              id="proof.name"
              label="proof.name"
              placeholder="proof.name.placeholder"
              value={name}
              onChange={setName}
              disabled={!!selectedProof}
            />
            {selectedProof && (
              <Box className="space-y-[3.125rem]">
                <TextField
                  id="proof.id"
                  label="proof.id"
                  value={selectedProof.id}
                  onChange={() => {}}
                  copy
                  disabled
                />
                <Button
                  data-testid="proof.testQrCode"
                  variant="outlined"
                  fullWidth
                  onClick={() => setOpenQrCodeModal(true)}
                >
                  <IntlMessages id="proof.testQrCode.button" />
                </Button>
              </Box>
            )}
          </Grid>
          {/* Right panel */}
          <Grid xs={6}>
            <Box className="h-full rounded-[10px] bg-gray-input p-[1.875rem] space-y-5">
              <Box className="flex justify-between items-center">
                <Typography variant="body2">
                  {attributesSelectedText}
                </Typography>
                <Button
                  data-testid="proof.attributes.add"
                  variant="text"
                  className="font-medium"
                  startIcon={<Add />}
                  onClick={() => handleOpenAttributesModal()}
                >
                  <IntlMessages id="proof.attributes.add" />
                </Button>
              </Box>
              {attributesSelected}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {openSelectAttributes && (
        <AttributesModal
          open={openSelectAttributes}
          selectedAttribute={attributeToEdit}
          onClose={onSelectAttributes}
        />
      )}
      {openQrCodeModal && (
        <QrCodeModal
          open={openQrCodeModal}
          proofId={selectedProof?.id!}
          onClose={() => setOpenQrCodeModal(false)}
        />
      )}
    </>
  );
};

export { ProofNewEdit };
