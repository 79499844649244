import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";
import { Environment } from "../../../defines/common";
import {
  ALL_MENU_ITEMS,
  DASHBOARD_MENU_ITEM,
  MENU_ITEMS_WITHOUT_OIDC,
} from "../../../defines/menu-items";
import { ORGS_WITH_OIDC } from "../../../defines/organizations";
import { ROUTE_PATH_VALUES, RoutePath } from "../../../defines/route-path";
import { MenuContentItem } from "./MenuItem";

const MenuContent = () => {
  const { userInfo } = useAuthContext();
  const [currentRoutePath, setCurrentRoutePath] = useState<RoutePath>();
  const navigate = useNavigate();
  const location = useLocation();
  const showDashboard = [Environment.Preprod, Environment.Prod].includes(
    window.ENV_ENVIRONMENT
  );
  const menuItems = ORGS_WITH_OIDC.includes(userInfo?.organization!)
    ? showDashboard
      ? [DASHBOARD_MENU_ITEM].concat(ALL_MENU_ITEMS)
      : ALL_MENU_ITEMS
    : showDashboard
    ? [DASHBOARD_MENU_ITEM].concat(MENU_ITEMS_WITHOUT_OIDC)
    : MENU_ITEMS_WITHOUT_OIDC;

  useEffect(() => {
    const currentPath = location.pathname;
    const routePath = ROUTE_PATH_VALUES.find(
      (value) => currentPath.startsWith(value) && value !== RoutePath.HOME
    );
    setCurrentRoutePath(routePath);
  }, [location]);

  const handleListItemClick = (route: RoutePath) => {
    navigate(route);
  };

  return (
    <Box className="space-y-[0.625rem]" aria-label="menu content">
      {menuItems?.map((item) => {
        return (
          <MenuContentItem
            key={item.id}
            item={item}
            selected={item.route.startsWith(currentRoutePath!)}
            onClick={handleListItemClick}
          />
        );
      })}
    </Box>
  );
};

export { MenuContent };
