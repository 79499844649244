/**
 * CUSTOM
 * This code has been developed for this solution.
 */
import { CredentialQueryData } from "../../core/models/Credential";
import NetworkServiceFactory from "../../core/services/network/NetworkService";
import { DEFAULT_QUERY_DATA } from "../../core/services/network/NetworkTypes";
import { useCredentialsContext } from "./CredentialsContext";

const useCredentialsActions = () => {
  const {
    setCredentialsResponseData,
    setCredentialsLoading,
    setCredentialsError,
    credentialsResponseData,
  } = useCredentialsContext();

  const dispatchGetCredentials = async (
    query: CredentialQueryData = DEFAULT_QUERY_DATA()
  ) => {
    let cancelLoading = false;
    setTimeout(() => {
      if (!cancelLoading) {
        setCredentialsLoading(true);
      }
    }, 400);
    const networkService = NetworkServiceFactory.shared;
    try {
      const data = await networkService.getCredentials(query);
      setCredentialsResponseData(data);
      setCredentialsError(undefined);
      return data;
    } catch (e: any) {
      setCredentialsError(e);
    } finally {
      cancelLoading = true;
      setCredentialsLoading(false);
    }
  };

  const dispatchCleanCredentialsContext = () => {
    setCredentialsResponseData(undefined);
    setCredentialsError();
  };

  return {
    dispatchGetCredentials,
    dispatchCleanCredentialsContext,
  };
};

export { useCredentialsActions };
