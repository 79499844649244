import { Close, Search } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { GRAY_60 } from "../../themes/colors";

interface SearchBoxProps {
  id?: string;
  label?: string;
  value?: string;
  delay?: number;
  onSearch(value?: string): void;
}

const SearchBox = (props: SearchBoxProps) => {
  const { id, label, value, delay, onSearch } = props;
  const [internalValue, setInternalValue] = useState<string>(value || "");
  const intl = useIntl();

  const dataTestId = id || "search";
  const placeholder = intl.formatMessage({ id: label || "search" });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInternalValue(newValue);
    callSearch(newValue);
  };

  const callSearch = debounce((value) => {
    !!onSearch && onSearch(value);
  }, delay || 0);

  const clearValue = () => {
    setInternalValue("");
    onSearch && onSearch("");
  };

  const getEndAdornment = useMemo(
    () => (
      <InputAdornment position="end">
        {internalValue ? (
          <IconButton
            data-testid={`${dataTestId}.clear`}
            aria-label="clear"
            onClick={clearValue}
          >
            <Close fontSize="small" />
          </IconButton>
        ) : (
          <></>
        )}
      </InputAdornment>
    ),
    [internalValue]
  );

  return (
    <TextField
      id={dataTestId}
      data-testid={dataTestId}
      placeholder={placeholder}
      value={internalValue}
      size="small"
      fullWidth
      InputProps={{
        startAdornment: <Search className="text-gray-60 mr-[0.375rem]" />,
        endAdornment: getEndAdornment,
        className: "rounded-full bg-gray-input px-1rem",
      }}
      sx={{
        input: {
          color: GRAY_60,
          padding: "0.75rem 0",
          "&::-webkit-input-placeholder": {
            opacity: 1,
          },
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
      }}
      onChange={handleChange}
    />
  );
};

export { SearchBox };
