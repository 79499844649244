/**
 * CUSTOM
 * This code has been developed for this solution.
 */
import {
  ProofCreateData,
  ProofQueryData,
  ProofUpdateData,
} from "../../core/models/Proof";
import NetworkServiceFactory from "../../core/services/network/NetworkService";
import { DEFAULT_QUERY_DATA } from "../../core/services/network/NetworkTypes";
import { useProofsContext } from "./ProofsContext";

const useProofsActions = () => {
  const {
    setProofsResponseData,
    setProofsLoading,
    setSelectedProof,
    setProofsError,
    proofsResponseData,
  } = useProofsContext();

  const dispatchGetProofs = async (
    query: ProofQueryData = DEFAULT_QUERY_DATA()
  ) => {
    let cancelLoading = false;
    setTimeout(() => {
      if (!cancelLoading) {
        setProofsLoading(true);
      }
    }, 400);
    const networkService = NetworkServiceFactory.shared;
    try {
      const data = await networkService.getProofs(query);
      setProofsResponseData(data);
      setProofsError(undefined);
      return data;
    } catch (e: any) {
      setProofsError(e);
    } finally {
      cancelLoading = true;
      setProofsLoading(false);
    }
  };

  const dispatchGetProof = (proofId: string) => {
    const networkService = NetworkServiceFactory.shared;
    networkService
      .getProof(proofId)
      .then((proof) => {
        setSelectedProof(proof);
        setProofsError(undefined);
      })
      .catch((e) => {
        setProofsError(e);
      });
  };

  const dispatchCreateProof = (query: ProofCreateData) => {
    const asyncCall = async () => {
      try {
        const data = await NetworkServiceFactory.shared.postProof(query);
        try {
          if (proofsResponseData) {
            const data =
              await NetworkServiceFactory.shared.getProofs(proofsResponseData);
            setProofsResponseData(data);
          }
        } catch (e) {
          // Pass
        }
        setSelectedProof(data);
        setProofsError(undefined);
      } catch (e: any) {
        setProofsError(e);
      }
    };
    asyncCall().catch();
  };

  const dispatchUpdateProof = (query: ProofUpdateData) => {
    const asyncCall = async () => {
      try {
        const data = await NetworkServiceFactory.shared.patchProof(query);
        try {
          if (proofsResponseData) {
            const data =
              await NetworkServiceFactory.shared.getProofs(proofsResponseData);
            setProofsResponseData(data);
          }
        } catch (e) {
          // Pass
        }
        setSelectedProof(data);
        setProofsError(undefined);
      } catch (e: any) {
        setProofsError(e);
      }
    };
    asyncCall().catch();
  };

  const dispatchCleanProofsContext = () => {
    setProofsResponseData(undefined);
    setProofsError();
    setSelectedProof();
  };

  return {
    dispatchGetProofs,
    dispatchGetProof,
    dispatchCleanProofsContext,
    dispatchCreateProof,
    dispatchUpdateProof,
  };
};

export { useProofsActions };
