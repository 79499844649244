import { curry } from "ramda";
import { Subject } from "rxjs";

type AlertMessage =
  | { message: JSX.Element | string }
  | { title: string; message: JSX.Element | string };

type NotificationEvents = {
  kind: "toast";
  type: "success" | "error" | "warning" | "info";
  params: AlertMessage & { onClose?: () => void; autoHideDuration?: number };
};

export const NotificationSubject = new Subject<NotificationEvents>();

/**
 * Shows a toast notification
 */
export const notify = curry(
  (type: NotificationEvents["type"], params: NotificationEvents["params"]) => {
    NotificationSubject.next({ kind: "toast", type, params });
  }
);
