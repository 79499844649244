import React from "react";
import { debounce } from "lodash";
import { TableSort } from "../components/Table/TableTypes";
import NetworkServiceFactory from "../core/services/network/NetworkService";

type SearchQueryProps = {
  onSearch: (a: any) => void;
  debounce?: number;
};

export const useSearchHelper = <DataFields extends string>({
  debounce: delay,
  onSearch,
}: SearchQueryProps) => {
  const [search, setSearch] = React.useState<string>();
  const [switchValue, setSwitchValue] = React.useState<boolean>(false);
  const [ordering, setOrdering] = React.useState<TableSort<DataFields>>();
  const [page, setPage] = React.useState<number>(1);
  const [refresh, setRefresh] = React.useState<boolean>(false);

  const doSearch = React.useCallback(debounce(onSearch, delay ?? 100), []);

  React.useEffect(() => {
    doSearch({ search, page, ordering, switchValue });
  }, [search, page, ordering, switchValue]);

  React.useEffect(() => {
    if (refresh) {
      // Reset cache when refresh is needed
      NetworkServiceFactory.shared.clearCache();
      doSearch({ search, page, ordering, switchValue });
      setRefresh(false);
    }
  }, [refresh]);

  const setSearchWithPageReset = (searchTerm: string | undefined) => {
    // When search is set, page reset is needed
    // if (!search || search.length === 0) {
    setPage(1);
    // }
    setSearch(searchTerm);
  };

  return {
    setPage,
    setSearch: setSearchWithPageReset,
    setOrdering,
    setSwitchValue,
    tableProps: {
      ordering,
      page,
      search,
      switchValue,
      onPageChange: setPage,
      onRefresh: () => setRefresh(true),
      onSort: setOrdering,
    },
  };
};
