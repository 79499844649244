import { createContext, useContext, useEffect, useState } from "react";
import Schema, { SchemaResponseData } from "../../core/models/Schema";
import NetworkingServiceError from "../../core/services/network/NetworkServiceError";
import appConfig from "../../util/appConfig";

// This context allows the app to get the schemas information

export type SchemaError = NetworkingServiceError;

// Create type
export type SchemasDataType = {
  schemasResponseData?: SchemaResponseData;
  setSchemasResponseData: (responseData?: SchemaResponseData) => void;
  selectedSchema?: Schema;
  setSelectedSchema: (schema?: Schema) => void;
  schemasLoading: boolean;
  setSchemasLoading: (isLoading: boolean) => void;
  schemasError: SchemaError | undefined;
  setSchemasError: (error?: SchemaError) => void;
};

// Create context for type
// @ts-ignore
export const SchemasDataContext = createContext<SchemasDataType>();

//Create provider of the context
export const SchemasDataProvider = ({ children }: any) => {
  const [selectedSchema, setSelectedSchema] = useState<Schema>();
  const [schemasResponseData, setSchemasResponseData] =
    useState<SchemaResponseData>();
  const [schemasLoading, setSchemasLoading] = useState<boolean>(false);
  const [schemasError, setSchemasError] = useState<SchemaError>();

  const value = {
    schemasResponseData,
    setSchemasResponseData,
    selectedSchema,
    setSelectedSchema,
    schemasLoading,
    setSchemasLoading,
    schemasError,
    setSchemasError,
  };
  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugSchemas && selectedSchema)
      console.debug("[Schemas][SelectedSchema] ", selectedSchema);
  }, [selectedSchema]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugSchemas && schemasResponseData)
      console.debug("[Schemas][SchemasResponseData] ", schemasResponseData);
  }, [schemasResponseData]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugSchemas && schemasLoading)
      console.debug("[Schemas][SchemasLoading] ", schemasLoading);
  }, [schemasLoading]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugSchemas && schemasError)
      console.warn(
        "[Schemas][Error] ",
        schemasError?.userMessage(),
        schemasError?.debugMessage()
      );
  }, [schemasError]);
  return (
    <SchemasDataContext.Provider value={value}>
      {children}
    </SchemasDataContext.Provider>
  );
};

export const useSchemasContext = () => useContext(SchemasDataContext);
