import { Box, Link, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { TranslationKey } from "../IntlMessages/IntlMessages";

const samePageLinkNavigation = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
};

type LinkTabProps = {
  label: string;
  href: string;
};

const LinkTab = (props: LinkTabProps) => {
  const navigate = useNavigate();

  return (
    <Tab
      LinkComponent={Link}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        // Routing libraries handle this, you can remove the onClick handle when using them.
        if (samePageLinkNavigation(event)) {
          event.preventDefault();
          navigate(props.href);
        }
      }}
      {...props}
    />
  );
};

export type NavTab = {
  label: TranslationKey;
  href: string;
};

type NavTabsProps = {
  tabs: Array<NavTab>;
};

const NavTabs = ({ tabs }: NavTabsProps) => {
  const intl = useIntl();
  const selectedTab = tabs.findIndex(
    (tab) => window.location.pathname === tab.href
  );
  const [value, setValue] = useState<number>(
    selectedTab > -1 ? selectedTab : 0
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== "click" ||
      (event.type === "click" &&
        samePageLinkNavigation(
          event as React.MouseEvent<HTMLAnchorElement, MouseEvent>
        ))
    ) {
      setValue(newValue);
    }
  };

  return (
    <Box className="min-w-full">
      <Tabs value={value} onChange={handleChange} aria-label="navigation tabs">
        {tabs.map((tab, index) => (
          <LinkTab
            data-testid={`tab.${index}`}
            key={tab.label}
            label={intl.formatMessage({ id: tab.label })}
            href={tab.href}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export { NavTabs };
