import { Box, Typography } from "@mui/material";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

const Header = () => {
  const { userInfo } = useAuthContext();

  return (
    <Box className="flex flex-col items-center space-y-4 mb-[4.375rem]">
      {userInfo?.orgLogo && (
        <Box className="max-w-[6rem] max-h-24 flex justify-center items-center mx-9 p-6 rounded-full bg-blue-600">
          <img src={userInfo.orgLogo} className="max-w-full" alt="logo" />
        </Box>
      )}
      <Typography
        data-testid="header-organization"
        variant="h6"
        className="text-gray-60 text-center"
      >
        {userInfo?.organization || "Unknown Org"}
      </Typography>
    </Box>
  );
};

export { Header };
