import AuthServiceFactory from "../../core/services/auth/AuthService";
import NetworkServiceFactory from "../../core/services/network/NetworkService";
import NetworkingServiceError, {
  NetworkingServiceErrorType,
} from "../../core/services/network/NetworkServiceError";
import useCommonActions from "../common/UseCommonActions";
import { useAuthContext } from "./AuthenticationContext";

const useAuthenticationActions = () => {
  const { setAuthenticated, setUserInfo, setAuthError } = useAuthContext();
  const { dispatchLogoutUser } = useCommonActions();

  const dispatchLogin = async () => await AuthServiceFactory.shared.login();

  const dispatchLogout = () => {
    setAuthenticated(false);
    setUserInfo(undefined);
    dispatchLogoutUser();
    AuthServiceFactory.shared.logout();
  };

  const dispatchGetMyUserInfo = async () => {
    try {
      const token = await AuthServiceFactory.shared.getToken();
      if (token) {
        NetworkServiceFactory.shared.token = token;

        try {
          const user = await NetworkServiceFactory.shared.getLoggedUser();
          user.orgLogo = await NetworkServiceFactory.shared.getOrgInfo(
            user.organization
          );
          setAuthenticated(true);
          setUserInfo(user);
        } catch (e: any) {
          const error = new NetworkingServiceError(
            NetworkingServiceErrorType.couldNotGetMyDetails
          );
          setAuthError(error);
          dispatchLogout();
        }
      } else {
        dispatchLogout();
      }
    } catch (e: any) {
      setAuthError(e);
      dispatchLogout();
    }
  };

  return {
    dispatchLogin,
    dispatchLogout,
    dispatchGetMyUserInfo,
  };
};
export default useAuthenticationActions;
