import { Language } from "./constants";
import { ArLang, EnLang, EsLang, FrLang, RuLang, ZhLang } from "./entries";
import { LocaleConfig } from "./model";

const AppLocale: LocaleConfig = {
  [Language.Arabic]: ArLang,
  [Language.Chinese]: ZhLang,
  [Language.English]: EnLang,
  [Language.French]: FrLang,
  [Language.Russian]: RuLang,
  [Language.Spanish]: EsLang,
};

export { AppLocale };
