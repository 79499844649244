/**
 * CUSTOM
 * This code has been developed for this solution.
 */
import {
  CredentialDefinitionCreateData,
  CredentialDefinitionQueryData,
  CredentialDefinitionUpdateData,
} from "../../core/models/CredentialDefinition";
import NetworkServiceFactory from "../../core/services/network/NetworkService";
import { DEFAULT_QUERY_DATA } from "../../core/services/network/NetworkTypes";
import { useCredentialDefinitionsContext } from "./CredentialDefinitionsContext";

const useCredentialDefinitionsActions = () => {
  const {
    setCredentialDefinitionsResponseData,
    setCredentialDefinitionsLoading,
    setSelectedCredentialDefinition,
    setCredentialDefinitionsError,
    credentialDefinitionsResponseData,
  } = useCredentialDefinitionsContext();

  const dispatchGetCredentialDefinitions = async (
    query: CredentialDefinitionQueryData = DEFAULT_QUERY_DATA()
  ) => {
    let cancelLoading = false;
    setTimeout(() => {
      if (!cancelLoading) {
        setCredentialDefinitionsLoading(true);
      }
    }, 400);
    const networkService = NetworkServiceFactory.shared;
    try {
      const data = await networkService.getCredentialDefinitions(query);
      setCredentialDefinitionsResponseData(data);
      setCredentialDefinitionsError(undefined);
      return data;
    } catch (e: any) {
      setCredentialDefinitionsError(e);
    } finally {
      cancelLoading = true;
      setCredentialDefinitionsLoading(false);
    }
  };

  const dispatchGetCredentialDefinition = (credentialDefinitionId: number) => {
    const networkService = NetworkServiceFactory.shared;
    networkService
      .getCredentialDefinition(credentialDefinitionId)
      .then((credentialDefinition) => {
        setSelectedCredentialDefinition(credentialDefinition);
        setCredentialDefinitionsError(undefined);
      })
      .catch((e) => {
        setCredentialDefinitionsError(e);
      });
  };

  const dispatchCreateCredentialDefinition = (
    query: CredentialDefinitionCreateData
  ) => {
    const asyncCall = async () => {
      try {
        const data =
          await NetworkServiceFactory.shared.postCredentialDefinition(query);
        try {
          if (credentialDefinitionsResponseData) {
            const data =
              await NetworkServiceFactory.shared.getCredentialDefinitions(
                credentialDefinitionsResponseData
              );
            setCredentialDefinitionsResponseData(data);
          }
        } catch (e) {
          // Pass
        }
        setSelectedCredentialDefinition(data);
        setCredentialDefinitionsError(undefined);
      } catch (e: any) {
        setCredentialDefinitionsError(e);
      }
    };
    asyncCall().catch();
  };

  const dispatchUpdateCredentialDefinition = (
    query: CredentialDefinitionUpdateData
  ) => {
    const asyncCall = async () => {
      try {
        const data =
          await NetworkServiceFactory.shared.patchCredentialDefinition(query);
        try {
          if (credentialDefinitionsResponseData) {
            const data =
              await NetworkServiceFactory.shared.getCredentialDefinitions(
                credentialDefinitionsResponseData
              );
            setCredentialDefinitionsResponseData(data);
          }
        } catch (e) {
          // Pass
        }
        setSelectedCredentialDefinition(data);
        setCredentialDefinitionsError(undefined);
      } catch (e: any) {
        setCredentialDefinitionsError(e);
      }
    };
    asyncCall().catch();
  };

  const dispatchCleanCredentialDefinitionsContext = () => {
    setCredentialDefinitionsResponseData(undefined);
    setCredentialDefinitionsError();
    setSelectedCredentialDefinition();
  };

  return {
    dispatchGetCredentialDefinitions,
    dispatchGetCredentialDefinition,
    dispatchCleanCredentialDefinitionsContext,
    dispatchCreateCredentialDefinition,
    dispatchUpdateCredentialDefinition,
  };
};

export { useCredentialDefinitionsActions };
