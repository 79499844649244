import { TranslationKey } from "../../components/IntlMessages/IntlMessages";

type CellAlign = "inherit" | "left" | "center" | "right" | "justify";

/**
 * The direction for the table sort
 */
export enum TableSortDirection {
  ascending = "asc",
  descending = "desc",
}

export type TableSortEntry<IdField extends string> = Record<
  IdField,
  TableSortDirection
>;

export type TableSort<IdField extends string> = Array<TableSortEntry<IdField>>;

/**
 * The table column definition
 */
export interface TableColumnInterface<IdField extends string> {
  // This field needs to exists in the data we pass
  id: IdField;
  // The label that will be applied
  label?: TranslationKey;
  // How to align the cell
  align?: CellAlign;
  // Whether the field is sortable or not
  sortable: boolean;
  handler?(row: any, rowNumber: number): any;
  // Style applied to particular cell
  cellClass?: string;
}

export interface TableHeaderInterface<IdField extends string> {
  columns: TableColumnInterface<IdField>[];
  /**
   * Sort coming from backend
   */
  ordering?: TableSort<IdField>;

  /**
   * This will tell the component to ask for new sorting
   */
  onSort?(sort?: TableSort<IdField>): void;
}

export type RowRules<T> = {
  disableWhen: (a: T) => boolean;
};

export interface TableInterface<IdField extends string, Model>
  extends TableFiltersInterface {
  id?: string;
  columns: Array<TableColumnInterface<IdField>>;
  data: Array<Model> | undefined;
  count?: number;
  loading?: boolean;
  page: number;
  onClickRow?(row: any): void;
  /**
   * Sort coming from backend
   */
  ordering?: TableSort<IdField>;

  /**
   * When component sorts, will execute this callback
   * @param sort the new sort requested
   */
  onSort?(sort?: TableSort<IdField>): void;
  onPageChange?(page: number): void;

  rowRules?: RowRules<Model>;

  EmptyState?: JSX.Element;
  BeforeTable?: JSX.Element;
}

export interface TableContentInterface<IdField extends string, Model> {
  data: Array<Model>;
  columns: Array<TableColumnInterface<IdField>>;
  onClickRow?(row: any): void;
  rowRules?: RowRules<Model>;
}

export interface TableSkeletonInterface<IdField extends string> {
  columns: Array<TableColumnInterface<IdField>>;
  numberOfRows?: number;
}

export interface TableFiltersInterface {
  searchValue?: string;
  switchValue?: boolean;
  showRefresh?: boolean;
  actionTitle?: TranslationKey;
  onSearch?(search: string): void;
  onChangeSwitch?(value: boolean): void;
  onRefresh?(): void;
  onClickAction?(): void;
}
