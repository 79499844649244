import { ListItemButton, Typography } from "@mui/material";
import IntlMessages from "../../../../components/IntlMessages/IntlMessages";
import { MenuItem } from "../../../../defines/menu-items";
import { RoutePath } from "../../../../defines/route-path";
import cn from "classnames";

type MenuItemProps = {
  item: MenuItem;
  selected: boolean;
  onClick(path: RoutePath): void;
};

const MenuContentItem = (props: MenuItemProps) => {
  const { item, selected, onClick } = props;
  const { icon, label, route } = item;

  return (
    <ListItemButton
      data-testid={item.id}
      className={cn(
        "space-x-4 rounded p-4",
        selected && "text-white bg-gray-80"
      )}
      onClick={() => onClick(route)}
    >
      {icon}
      <Typography variant="subtitle1">
        <IntlMessages id={label} />
      </Typography>
    </ListItemButton>
  );
};

export { MenuContentItem };
