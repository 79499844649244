import { ChevronRight, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  Unstable_Grid2 as Grid,
  ListItemButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import { Modal } from "../../components/Modal";
import { SearchBox } from "../../components/SearchBox";
import { Switch } from "../../components/Switch";
import { useAuthContext } from "../../contexts/authentication/AuthenticationContext";
import { useSchemasContext } from "../../contexts/schemas/SchemasContext";
import { useSchemasActions } from "../../contexts/schemas/UseSchemasActions";
import Schema from "../../core/models/Schema";
import { GRAY_60 } from "../../themes/colors";

type SchemasModalProps = {
  open: boolean;
  selectedSchema?: Schema;
  onClose(schemaId?: Schema): void;
};

const SchemasModal = ({ open, selectedSchema, onClose }: SchemasModalProps) => {
  const { schemasResponseData } = useSchemasContext();
  const { dispatchGetSchemas, dispatchCleanSchemasContext } =
    useSchemasActions();
  const { userInfo } = useAuthContext();
  const [schemasList, setSchemasList] = useState<Array<Schema>>();
  const [schemaSelected, setSchemaSelected] = useState<Schema | undefined>(
    selectedSchema
  );
  const [search, setSearch] = useState<string>();
  const [showOnlyOrg, setShowOnlyOrg] = useState<boolean>(false);

  //#region Load schemas
  const loadSchemas = () => {
    if (userInfo) {
      dispatchGetSchemas({
        filter: {
          search,
          organization__name: showOnlyOrg ? userInfo?.organization : undefined,
          enabled: true,
        },
        ordering: [{ created: false }],
        page: 1,
        size: 1000,
      }).catch();
    }
  };

  useEffect(() => {
    if (schemasResponseData?.data && schemasResponseData.data.length) {
      const schemasWithIds = schemasResponseData.data.filter(
        (schema) => !!schema.schemaId
      );
      setSchemasList(schemasWithIds);

      const findSchema = schemasResponseData.data.find(
        (schema) => schema.schemaId === schemaSelected?.schemaId
      );
      if (!findSchema && schemasWithIds.length) {
        setSchemaSelected(schemasWithIds[0]);
      }
    }
  }, [schemasResponseData]);

  useEffect(() => loadSchemas(), [showOnlyOrg, search]);
  //#endregion

  //#region Cleanup
  useEffect(() => {
    setShowOnlyOrg(false);
    return () => dispatchCleanSchemasContext();
  }, []);
  //#endregion

  //#region Renderers
  const content = (
    <Box className="space-y-6">
      {/* Filters */}
      <Grid data-testid="schemasModal.filters" container alignItems="center">
        <Grid xs={4}>
          <SearchBox
            id="schemasModal.searchBox"
            delay={500}
            value={search}
            onSearch={setSearch}
          />
        </Grid>
        <Grid xsOffset="auto">
          <Switch
            id="schemasModal.switchButton"
            label="credentialDefinition.new.selectSchema.showOnlyCreatedBy"
            labelParam={{ org: userInfo?.organization! }}
            value={showOnlyOrg}
            onChange={setShowOnlyOrg}
          />
        </Grid>
      </Grid>
      {/* Schemas list */}
      {schemasList && schemasList.length && (
        <RadioGroup
          data-testid="schemasModal.schemasList"
          aria-label="schemas list"
          className="space-y-3"
          value={schemaSelected?.schemaId}
        >
          {schemasList.map((schema, index) => {
            const { createdBy, name, version, schemaId, attributes, id } =
              schema;

            const composedName = `${createdBy} / ${name} / ${version}`;

            return (
              <React.Fragment key={id}>
                <ListItemButton
                  data-testid={`schemasModal.schema.${index}`}
                  className={cn(
                    "rounded-lg border border-solid border-gray-background px-3 py-4",
                    schemaSelected?.schemaId === schemaId &&
                      "bg-gray-background"
                  )}
                  onClick={() => setSchemaSelected(schema)}
                >
                  {/* Schema */}
                  <Grid container className="w-full items-center">
                    <Grid xs="auto">
                      <Radio className="p-3" value={schemaId} />
                    </Grid>
                    <Grid xs>
                      <Typography fontWeight={500}>{composedName}</Typography>
                      <Typography variant="body2" color={GRAY_60}>
                        {schemaId}
                      </Typography>
                    </Grid>
                    <Grid xsOffset="auto">
                      {schemaSelected &&
                      schemaSelected.schemaId === schemaId ? (
                        <ExpandMore color="secondary" />
                      ) : (
                        <ChevronRight color="secondary" />
                      )}
                    </Grid>
                  </Grid>
                </ListItemButton>
                {/* Schema attributes */}
                {schemaSelected && schemaSelected.schemaId === schemaId ? (
                  <Box className="rounded-lg border border-solid border-gray-background space-y-4 p-[1.25rem] ml-[10%]">
                    {attributes.map((attr, index) => (
                      <Box key={index} className="flex">
                        <Typography fontWeight={500}>{attr.name}</Typography>
                        <Typography whiteSpace="pre-wrap" color={GRAY_60}>
                          {" / "}
                          <IntlMessages
                            id={`schema.attribute.type.${attr.type}`}
                          />
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <></>
                )}
              </React.Fragment>
            );
          })}
        </RadioGroup>
      )}
    </Box>
  );

  const actions = (
    <>
      <Typography color={GRAY_60}>
        <IntlMessages
          id={`credentialDefinition.new.selectSchema.${
            schemaSelected ? "schemaSelected" : "noSchemaSelected"
          }`}
        />
      </Typography>
      <Box className="flex space-x-[1.375rem]">
        <Button
          data-testid="schemasModal.cancel"
          variant="text"
          color="error"
          onClick={() => onClose()}
        >
          <IntlMessages id="cancel" />
        </Button>
        <Button
          data-testid="schemasModal.save"
          disabled={!schemaSelected}
          onClick={() => onClose(schemaSelected)}
        >
          <IntlMessages id="ok" />
        </Button>
      </Box>
    </>
  );
  //#endregion

  return (
    <Modal
      id="schemasModal"
      title="credentialDefinition.new.selectSchema.title"
      maxWidth="md"
      open={open}
      actions={actions}
      onClose={() => onClose()}
    >
      {content}
    </Modal>
  );
};

export { SchemasModal };
