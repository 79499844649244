import { AddOutlined, Cached } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { debounce } from "lodash";
import { useCallback } from "react";
import IntlMessages from "../../IntlMessages/IntlMessages";
import { SearchBox } from "../../SearchBox";
import { Switch } from "../../Switch";
import { TableFiltersInterface } from "../TableTypes";

const TableFilters = ({
  searchValue,
  switchValue = false,
  showRefresh = false,
  actionTitle,
  onSearch,
  onChangeSwitch,
  onRefresh,
  onClickAction,
}: TableFiltersInterface) => {
  const debounceSearch = useCallback(
    debounce((value: string) => {
      if (onSearch) {
        onSearch(value);
      }
    }, 250),
    [onSearch]
  );

  return showRefresh ||
    onSearch ||
    onChangeSwitch ||
    onRefresh ||
    onClickAction ? (
    <Grid data-testid="tableFilters" container justifyContent="space-between">
      <Grid item md={6} xs={12} className="flex items-center space-x-5">
        <Box className="flex-1">
          {onSearch && (
            <SearchBox
              id="tableFilters.searchBox"
              delay={500}
              value={searchValue}
              onSearch={debounceSearch}
            />
          )}
        </Box>
        <Box className="flex-1">
          {onChangeSwitch && (
            <Switch
              id="tableFilters.switchButton"
              label="showAllOrgs"
              value={switchValue}
              onChange={onChangeSwitch}
            />
          )}
        </Box>
      </Grid>
      <Grid item className="space-x-[0.625rem]">
        {showRefresh && onRefresh && (
          <Button
            data-testid="tableFilters.refreshButton"
            variant="contained"
            color="secondary"
            className="pl-[1.25rem]"
            startIcon={<Cached fontSize="large" />}
            onClick={onRefresh}
          >
            <IntlMessages id="table.refresh" />
          </Button>
        )}
        {actionTitle && onClickAction && (
          <Button
            data-testid="tableFilters.actionButton"
            variant="contained"
            className="pl-[1.25rem]"
            startIcon={<AddOutlined fontSize="large" />}
            onClick={onClickAction}
          >
            <IntlMessages id={actionTitle} />
          </Button>
        )}
      </Grid>
    </Grid>
  ) : null;
};

export { TableFilters };
