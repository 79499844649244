import {
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";
import { useIntl } from "react-intl";
import { TranslationKey } from "../IntlMessages/IntlMessages";

type SelectProps<T> = {
  id: string;
  label?: TranslationKey;
  placeholder?: TranslationKey;
  disabled?: boolean;
  options: SelectOptions<T>;
  multiple?: boolean;
  value: ValueType<T>;
  onChange(newValue: ValueType<T>): void;
};

type Option<T> = {
  label: TranslationKey;
  value: T;
};

type ValueType<T> = T | Array<T> | string | undefined;

export type SelectOptions<T> = Array<Option<T>>;

const Select = <T extends string>({
  id,
  label,
  placeholder,
  disabled = false,
  multiple = false,
  options,
  value,
  onChange,
}: SelectProps<T>) => {
  const intl = useIntl();
  const translatedLabel = label ? intl.formatMessage({ id: label }) : null;
  const translatedPlaceholder = intl.formatMessage({
    id:
      placeholder ||
      (multiple ? "select.multiple.placeholder" : "select.single.placeholder"),
  });

  return (
    <FormGroup>
      <FormControlLabel
        id={`${id}.label`}
        data-testid={`${id}.label`}
        aria-label={translatedLabel || "select"}
        label={translatedLabel}
        labelPlacement="top"
        control={
          <MuiSelect<ValueType<T>>
            id={`${id}.label`}
            data-testid={id}
            variant="outlined"
            fullWidth
            displayEmpty
            disabled={disabled}
            multiple={multiple}
            classes={{ select: !!value ? "" : "opacity-50" }}
            renderValue={(value) => {
              // Placeholder
              if (!value || !value.length) {
                return translatedPlaceholder;
              }

              // Render array
              if (multiple && Array.isArray(value) && value.length) {
                return options
                  .filter((option) => value.includes(option.value))
                  .map((option) => intl.formatMessage({ id: option.label }))
                  .join(", ");
              }

              // Render single value
              const optionSelected = options.find(
                (option) => option.value === value
              );
              if (optionSelected) {
                return intl.formatMessage({ id: optionSelected.label });
              }
            }}
            value={value || (multiple ? [] : "")}
            onChange={(event) => onChange(event.target.value)}
          >
            <MenuItem value={multiple ? [] : ""} disabled selected>
              {translatedPlaceholder}
            </MenuItem>
            {options.map((option, index) => {
              const { label: optionLabel, value: optionValue } = option;

              return (
                <MenuItem
                  key={optionValue}
                  data-testid={`${id}.option.${index}`}
                  value={optionValue}
                >
                  {intl.formatMessage({ id: optionLabel })}
                </MenuItem>
              );
            })}
          </MuiSelect>
        }
        classes={{ label: "mb-[0.375rem]" }}
      />
    </FormGroup>
  );
};

export { Select };
