import { Box, CircularProgress, Typography } from "@mui/material";
import cn from "classnames";
import IframeResizer from "iframe-resizer-react";
import { useEffect, useRef, useState } from "react";
import { PageHeader } from "../../components/PageHeader";
import NetworkServiceFactory from "../../core/services/network/NetworkService";
import NetworkingServiceError from "../../core/services/network/NetworkServiceError";
import { notify } from "../../libs/observables/notification";

const Dashboard = () => {
  const [url, setUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [emptyUrl, setEmptyUrl] = useState<boolean>(false);
  const boxRef = useRef<HTMLDivElement>();

  useEffect(() => {
    NetworkServiceFactory.shared
      .getDashboardUrl()
      .then((url) => {
        if (url !== "") {
          setUrl(url);
        } else {
          setLoading(false);
          setEmptyUrl(true);
        }
      })
      .catch((error: NetworkingServiceError) => {
        setTimeout(() => {
          setLoading(false);
          notify("error")({ message: error.userMessage() });
        }, 100);
      });
  }, []);

  return (
    <Box
      ref={boxRef}
      className={cn(
        "flex flex-col",
        "h-[calc(100% + 4.25rem)] w-[calc(100%+3.75rem)]",
        "-mb-[4.25rem] space-y-4"
      )}
    >
      <PageHeader title="dashboard" />
      <Box
        className={cn(
          "flex-1 flex flex-col -mx-4",
          (loading || emptyUrl) && "items-center justify-center"
        )}
      >
        {loading && <CircularProgress />}
        {emptyUrl && (
          <Typography data-testid="dashboard.emptyUrl" variant="caption">
            Empty Signed Url
          </Typography>
        )}
        {url && (
          <IframeResizer
            data-testid="dashboard"
            id="dashboard"
            name="Dashboard"
            src={url}
            width="100%"
            onLoad={() => setLoading(false)}
          />
        )}
      </Box>
    </Box>
  );
};

export { Dashboard };
