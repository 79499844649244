/**
 * CUSTOM
 * This code has been developed for this solution.
 */

import BaseError from "../../../util/BaseError";

/**
 * Error type for credentials
 */
export enum AuthServiceErrorType {
  generic = 3000,
  noUserLogged,
  couldNotGetUserToken,
}

/**
 * Error to be thrown by the service, so we can assess the user on how to proceed
 */
export default class AuthServiceError extends BaseError {
  constructor(
    code: AuthServiceErrorType = AuthServiceErrorType.generic,
    message = "",
    ...params: any[]
  ) {
    super(code, message, ...params);
    this.name = "AuthServiceError";
    this.code = code;
    this.params = params;
    this.message = ": " + message;
  }

  /**
   * This is a user-friendly message
   */

  userMessage(): string {
    switch (this.code) {
      case AuthServiceErrorType.noUserLogged:
        return "There isn't any user logged. Please login first.";
      case AuthServiceErrorType.couldNotGetUserToken:
        return "Could not get token for logged user. Please login again.";
      case AuthServiceErrorType.generic:
      default:
        return "There was an authentication error. Please check your network connection.";
    }
  }
}
