import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AppSnackbarProvider } from "../components/Error/AppNotificationProvider";
import { useAuthContext } from "../contexts/authentication/AuthenticationContext";
import { useLanguageContext } from "../contexts/language/LanguageContext";
import { Environment } from "../defines/common";
import { ORGS_WITH_OIDC } from "../defines/organizations";
import { RoutePath } from "../defines/route-path";
import { Layout } from "../layout";
import { AppLocale } from "../lngProvider";
import { LanguageDirection } from "../lngProvider/constants";
import { EnLang } from "../lngProvider/entries";
import { LangConfig } from "../lngProvider/model";
import {
  CredentialDefinitionNewEdit,
  CredentialDefinitions,
} from "../pages/credentialDefinitions";
import { Credentials } from "../pages/credentials";
import { Dashboard } from "../pages/dashboard";
import { PrivacyPolicy } from "../pages/privacyPolicy";
import { ProofNewEdit, Proofs } from "../pages/proofs";
import {
  SchemaNewEdit,
  Schemas,
  SchemasCredentialsDef,
} from "../pages/schemas";
import { TermsOfService } from "../pages/termsOfService";
import { UserNewEdit, Users } from "../pages/users";
import { createAppTheme, createLtrCache, createRtlCache } from "../util/theme";

const App = ({ instance }: { instance: IPublicClientApplication }) => {
  const [currentAppLocale, setCurrentAppLocale] = useState<LangConfig>(EnLang);
  const { language, languageDirection } = useLanguageContext();
  const { userInfo } = useAuthContext();
  const showDashboard = [Environment.Preprod, Environment.Prod].includes(
    window.ENV_ENVIRONMENT
  );

  //#region Handle language and language direction changes
  const cacheLtr = createLtrCache();
  const cacheRtl = createRtlCache();
  let theme = createAppTheme(languageDirection);

  useEffect(() => {
    document.body.setAttribute("dir", languageDirection);
    theme = createAppTheme(languageDirection);
  }, [languageDirection]);

  useEffect(() => {
    if (language !== currentAppLocale?.locale) {
      setCurrentAppLocale(AppLocale[language]);
      document.documentElement.setAttribute("lang", language);
    }
  }, [language, currentAppLocale?.locale]);
  //#endregion

  return (
    <MsalProvider instance={instance}>
      <CacheProvider
        value={
          languageDirection === LanguageDirection.RightToLeft
            ? cacheRtl
            : cacheLtr
        }
      >
        <ThemeProvider theme={theme}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <BrowserRouter>
              <AppSnackbarProvider>
                <Routes>
                  <Route path={RoutePath.HOME} element={<Layout />}>
                    <Route
                      path={RoutePath.HOME}
                      element={
                        <Navigate
                          to={
                            showDashboard
                              ? RoutePath.DASHBOARD
                              : RoutePath.CREDENTIALS
                          }
                        />
                      }
                    />
                    <Route
                      path={RoutePath.CREDENTIALS}
                      element={<Credentials />}
                    />
                    <Route
                      path={RoutePath.SCHEMAS_CREDENTIALS}
                      element={<SchemasCredentialsDef />}
                    >
                      <Route
                        path={RoutePath.CREDENTIAL_DEFINITIONS}
                        element={<CredentialDefinitions />}
                      />
                      <Route path={RoutePath.SCHEMAS} element={<Schemas />} />
                    </Route>
                    {showDashboard && (
                      <Route
                        path={RoutePath.DASHBOARD}
                        element={<Dashboard />}
                      />
                    )}
                    <Route
                      path={RoutePath.NEW_CREDENTIAL_DEFINITION}
                      element={<CredentialDefinitionNewEdit />}
                    />
                    <Route
                      path={RoutePath.EDIT_CREDENTIAL_DEFINITION}
                      element={<CredentialDefinitionNewEdit />}
                    />
                    <Route
                      path={RoutePath.NEW_SCHEMA}
                      element={<SchemaNewEdit />}
                    />
                    <Route
                      path={RoutePath.EDIT_SCHEMA}
                      element={<SchemaNewEdit />}
                    />
                    {ORGS_WITH_OIDC.includes(userInfo?.organization!) && (
                      <>
                        <Route path={RoutePath.PROOFS} element={<Proofs />} />
                        <Route
                          path={RoutePath.NEW_PROOF}
                          element={<ProofNewEdit />}
                        />
                        <Route
                          path={RoutePath.EDIT_PROOF}
                          element={<ProofNewEdit />}
                        />
                      </>
                    )}
                    <Route path={RoutePath.USERS} element={<Users />} />
                    <Route
                      path={RoutePath.NEW_USER}
                      element={<UserNewEdit />}
                    />
                    <Route
                      path={RoutePath.EDIT_USER}
                      element={<UserNewEdit />}
                    />
                    <Route path="*" element={<>Not found</>} />
                  </Route>
                  <Route
                    path={RoutePath.PRIVACY_POLICY}
                    element={<PrivacyPolicy />}
                  />
                  <Route
                    path={RoutePath.TERMS_OF_SERVICE}
                    element={<TermsOfService />}
                  />
                </Routes>
              </AppSnackbarProvider>
            </BrowserRouter>
          </IntlProvider>
        </ThemeProvider>
      </CacheProvider>
    </MsalProvider>
  );
};

export default App;
