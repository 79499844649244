import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader";
import { NavTab } from "../../components/Tabs/NavTabs.container";
import { RoutePath } from "../../defines/route-path";

const SchemasCredentialsDef = () => {
  const tabs: Array<NavTab> = [
    { label: "credentialDefinitions", href: RoutePath.CREDENTIAL_DEFINITIONS },
    { label: "schemas", href: RoutePath.SCHEMAS },
  ];

  return (
    <Box
      id="pageWrapper"
      className="w-full flex flex-col flex-1 space-y-[1.875rem]"
    >
      <PageHeader title="schemas" tabs={tabs} />
      <Box className="h-full">
        <Outlet />
      </Box>
    </Box>
  );
};

export { SchemasCredentialsDef };
