import {
  CreditCard,
  List,
  PeopleOutline,
  PieChartOutlined,
  ShieldOutlined,
} from "@mui/icons-material";
import { TranslationKey } from "../components/IntlMessages/IntlMessages";
import { RoutePath } from "./route-path";

export type MenuItem = {
  id: string;
  icon?: JSX.Element;
  label: TranslationKey;
  route: RoutePath;
};

const CREDENTIALS: MenuItem = {
  id: "credentials",
  icon: <CreditCard />,
  label: "credentials",
  route: RoutePath.CREDENTIALS,
};
const DASHBOARD: MenuItem = {
  id: "dashboard",
  icon: <PieChartOutlined />,
  label: "dashboard",
  route: RoutePath.DASHBOARD,
};
const PROOFS: MenuItem = {
  id: "proofs",
  icon: <ShieldOutlined />,
  label: "proofs",
  route: RoutePath.PROOFS,
};
const SCHEMAS: MenuItem = {
  id: "schemas",
  icon: <List />,
  label: "schemas",
  route: RoutePath.SCHEMAS,
};
const USERS: MenuItem = {
  id: "users",
  icon: <PeopleOutline />,
  label: "users",
  route: RoutePath.USERS,
};

export const ALL_MENU_ITEMS = [SCHEMAS, CREDENTIALS, PROOFS, USERS];

export const MENU_ITEMS_WITHOUT_OIDC = [SCHEMAS, CREDENTIALS, USERS];

export const DASHBOARD_MENU_ITEM = DASHBOARD;
