import { Alert, Tooltip } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useEvent from "react-use-event-hook";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import { Table } from "../../components/Table";
import { TableColumnInterface } from "../../components/Table/TableTypes";
import { useAuthContext } from "../../contexts/authentication/AuthenticationContext";
import { useCredentialDefinitionsContext } from "../../contexts/credentialDefinitions/CredentialDefinitionsContext";
import { useCredentialDefinitionsActions } from "../../contexts/credentialDefinitions/UseCredentialDefinitionsActions";
import CredentialDefinition, {
  CredentialDefinitionDataTableFields,
  credentialDefinitionDataOrderTransform,
} from "../../core/models/CredentialDefinition";
import {
  DEFAULT_PAGE_SIZE,
  modelDataOrderTransform,
} from "../../core/services/network/NetworkTypes";
import { RoutePath } from "../../defines/route-path";
import { useSearchHelper } from "../../hooks/useSearchHelper";
import { DateFormat, getFormattedDate } from "../../util/utils";

const CredentialDefinitions = () => {
  const navigate = useNavigate();
  const { credentialDefinitionsResponseData, credentialDefinitionsLoading } =
    useCredentialDefinitionsContext();
  const {
    dispatchGetCredentialDefinitions,
    dispatchCleanCredentialDefinitionsContext,
  } = useCredentialDefinitionsActions();
  const { userInfo } = useAuthContext();

  //#region Load credential definitions
  const onSearch = useEvent(({ page, ordering, search, switchValue }) => {
    if (userInfo) {
      dispatchGetCredentialDefinitions({
        filter: {
          search,
          organization__name: switchValue ? undefined : userInfo?.organization,
        },
        ordering: modelDataOrderTransform(
          ordering,
          credentialDefinitionDataOrderTransform
        ),
        page,
        size: DEFAULT_PAGE_SIZE,
      }).catch();
    }
  });

  const searchHelper = useSearchHelper<CredentialDefinitionDataTableFields>({
    onSearch,
  });
  //#endregion

  //#region Table config
  const columns: Array<
    TableColumnInterface<CredentialDefinitionDataTableFields>
  > = [
    { id: "name", label: "credentialDefinition.name", sortable: true },
    {
      id: "credentialId",
      label: "credentialDefinition.id",
      cellClass: "max-w-xs truncate",
      sortable: true,
      handler: (row: CredentialDefinition) => (
        <Tooltip title={row.credentialId}>
          <span>{row.credentialId}</span>
        </Tooltip>
      ),
    },
    {
      id: "schemaName",
      label: "credentialDefinition.schema",
      sortable: true,
    },
    {
      id: "createdBy",
      label: "credentialDefinition.createdBy",
      sortable: true,
    },
    {
      id: "createdOn",
      label: "credentialDefinition.createdOn",
      sortable: true,
      handler: (row: CredentialDefinition) => {
        return row.createdOn
          ? getFormattedDate(row.createdOn, DateFormat.DayOfMonth)
          : "-";
      },
    },
  ];
  //#endregion

  //#region Actions config
  const goToCreateCredentialDefinition = () => {
    navigate(RoutePath.NEW_CREDENTIAL_DEFINITION);
  };

  const goToEditCredentialDefinition = (
    credentialDefinition: CredentialDefinition
  ) => {
    navigate(
      RoutePath.EDIT_CREDENTIAL_DEFINITION.replace(
        ":id",
        String(credentialDefinition.id)
      )
    );
  };
  //#endregion

  //#region Cleanup
  useEffect(() => {
    return () => dispatchCleanCredentialDefinitionsContext();
  }, []);
  //#endregion

  return (
    <Table
      {...searchHelper.tableProps}
      columns={columns}
      data={credentialDefinitionsResponseData?.data}
      count={credentialDefinitionsResponseData?.count}
      loading={credentialDefinitionsLoading}
      actionTitle="table.add"
      BeforeTable={
        <Alert variant="outlined" severity="info">
          <IntlMessages id="credentialDefinition.disclaimer.newCredentialDefinition" />
        </Alert>
      }
      showRefresh
      onSearch={searchHelper.setSearch}
      onChangeSwitch={searchHelper.setSwitchValue}
      onClickAction={goToCreateCredentialDefinition}
      onClickRow={goToEditCredentialDefinition}
    />
  );
};

export { CredentialDefinitions };
