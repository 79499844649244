import { AddOutlined, DeleteOutline } from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Unstable_Grid2 as Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import { PageHeader } from "../../components/PageHeader";
import { Select } from "../../components/Select";
import { TextField } from "../../components/TextField";
import { useAuthContext } from "../../contexts/authentication/AuthenticationContext";
import { useSchemasContext } from "../../contexts/schemas/SchemasContext";
import { useSchemasActions } from "../../contexts/schemas/UseSchemasActions";
import {
  SchemaAttribute,
  SchemaAttributes,
  SchemaCreateData,
  SchemaUpdateData,
} from "../../core/models/Schema";
import {
  ATTRIBUTE_TYPES_OPTIONS,
  AttributeType,
} from "../../defines/attribute-type";
import { notify } from "../../libs/observables/notification";

enum Attributes {
  Add,
  Change,
  Delete,
}

const SchemaNewEdit = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { userInfo } = useAuthContext();
  const { selectedSchema, schemasError } = useSchemasContext();
  const {
    dispatchGetSchema,
    dispatchCreateSchema,
    dispatchUpdateSchema,
    dispatchCleanSchemasContext,
  } = useSchemasActions();
  const [name, setName] = useState<string>();
  const [version, setVersion] = useState<string>("1.0");
  const [attributes, setAttributes] = useState<SchemaAttributes>([
    {
      name: "",
      type: AttributeType.Text,
    },
  ]);
  const [loading, setLoading] = useState<boolean>(false);

  const originalAttributesSorted = selectedSchema?.attributes
    ? JSON.stringify(
        selectedSchema.attributes
          .filter((attr) => !!attr.name)
          .sort((a, b) => a.name.localeCompare(b.name))
      )
    : "";
  const attributesSorted = attributes
    ? JSON.stringify(
        attributes
          .filter((attr) => !!attr.name)
          .sort((a, b) => a.name.localeCompare(b.name))
      )
    : "";
  const formNotChanged =
    !!params.id &&
    !!selectedSchema &&
    !!selectedSchema.attributes &&
    originalAttributesSorted === attributesSorted;
  const disableSave =
    !name ||
    !attributes.length ||
    !attributes.filter((attr) => !!attr.name).length ||
    formNotChanged;

  //#region Load and cleanup
  useEffect(() => {
    if (params.id) {
      dispatchGetSchema(+params.id);
    }
    return () => dispatchCleanSchemasContext();
  }, []);

  useEffect(() => {
    if (!params.id) {
      if (selectedSchema && !schemasError) {
        notify("success")({ message: "Schema created!" });
        navigate(-1);
      }
    } else {
      if (loading && selectedSchema && !schemasError) {
        notify("success")({ message: "Schema updated!" });
      } else if (selectedSchema) {
        setName(selectedSchema.name);
        setVersion(selectedSchema.version);
        setAttributes(selectedSchema.attributes);
      }
    }

    setLoading(false);
  }, [selectedSchema, schemasError]);
  //#endregion

  //#region Page actions
  const onCancel = () => navigate(-1);

  const onSave = () => {
    if (!name) {
      notify("error")({ message: "Schema name is required!" });
      return;
    }
    if (!attributes.length) {
      notify("error")({ message: "Schema attributes is required!" });
      return;
    }
    if (!version) {
      console.warn("[NewSchema][Save] Version is not defined.");
      return;
    }
    if (!userInfo?.organization) {
      console.warn("[NewSchema][Save] User organization is not defined.");
      return;
    }

    setLoading(true);

    const schema: SchemaCreateData = {
      name,
      organization_name: userInfo.organization,
      schema_json: {
        schema_name: name,
        attributes: attributes.reduce(
          (obj, attr) => {
            if (attr.name) obj[attr.name] = attr.type;
            return obj;
          },
          {} as Record<string, AttributeType>
        ),
      },
    };

    if (params.id) {
      const schemaModified: SchemaUpdateData = {
        id: +params.id,
        ...schema,
      };
      dispatchUpdateSchema(schemaModified);
    } else {
      dispatchCreateSchema(schema);
    }
  };
  //#endregion

  //#region Attributes
  const handleAttributesChanges = (args: AttributesChangesProps) => {
    let newAttributes: SchemaAttributes;
    const { action } = args;

    switch (action) {
      case Attributes.Add:
        newAttributes = [{ name: "", type: AttributeType.Text }, ...attributes];
        break;
      case Attributes.Change:
        const { index: attrToEdit, newAttr } = args;
        newAttributes = [...attributes];
        newAttributes.splice(attrToEdit, 1, newAttr);
        break;
      case Attributes.Delete:
        const { index: attrToDelete } = args;
        newAttributes = [...attributes];
        newAttributes.splice(attrToDelete, 1);
    }

    setAttributes(newAttributes);
  };
  //#endregion

  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress data-testid="loader" />
      </Backdrop>
      <Box className="min-w-full space-y-[1.875rem] flex-box">
        <PageHeader
          title={params.id ? "schema.details" : "schema.new"}
          tooltip="schemas"
          divider
          disableSave={disableSave}
          containerClass="flex-initial"
          onCancel={onCancel}
          onSave={onSave}
        />
        {/* Form */}
        <Grid container columnSpacing={4} className="flex-auto">
          {/* Left panel */}
          <Grid xs={6} className="space-y-[1.875rem]">
            <TextField
              id="schema.name"
              label="schema.name"
              placeholder="schema.name.placeholder"
              value={name}
              onChange={setName}
              disabled={!!selectedSchema}
            />
            <TextField
              id="schema.version"
              label="schema.version"
              value={version}
              onChange={setVersion}
              disabled
            />
            {selectedSchema && (
              <>
                <TextField
                  id="schema.id"
                  label="schema.schemaId"
                  value={selectedSchema.schemaId}
                  onChange={() => {}}
                  copy
                  disabled
                />
                <TextField
                  id="schema.enabled"
                  label="schema.enabled"
                  value={intl.formatMessage({
                    id: `schema.enabled.${
                      selectedSchema.enabled ? "valid" : "deprecated"
                    }`,
                  })}
                  onChange={() => {}}
                  disabled
                />
              </>
            )}
          </Grid>
          {/* Right panel */}
          <Grid xs={6}>
            <Box className="h-full rounded-[10px] bg-gray-input p-[1.875rem] space-y-[1.25rem]">
              <Box className="flex justify-between items-center">
                <Typography variant="body2">
                  <IntlMessages id="schema.attributes" />
                </Typography>
                <Button
                  data-testid="schema.attributes.add"
                  variant="text"
                  className="font-medium"
                  startIcon={<AddOutlined />}
                  onClick={() =>
                    handleAttributesChanges({ action: Attributes.Add })
                  }
                >
                  <IntlMessages id="add" />
                </Button>
              </Box>
              {attributes.length > 0 ? (
                attributes.map((attr, index) => (
                  <Attribute
                    key={index}
                    attr={attr}
                    index={index}
                    onChangeAttributes={handleAttributesChanges}
                  />
                ))
              ) : (
                <Alert variant="outlined" severity="error">
                  <IntlMessages id="schema.attributes.disclaimer" />
                </Alert>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

type AttributesChangesProps =
  | {
      action: Attributes.Add;
    }
  | {
      action: Attributes.Change;
      index: number;
      newAttr: SchemaAttribute;
    }
  | {
      action: Attributes.Delete;
      index: number;
    };

const Attribute = ({
  attr,
  index,
  onChangeAttributes,
}: {
  attr: SchemaAttribute;
  index: number;
  onChangeAttributes(args: AttributesChangesProps): void;
}) => {
  const { name, type } = attr;

  return (
    <Box
      data-testid={`schema.attribute.${index}`}
      className="rounded-2xl bg-white p-6 space-y-4"
    >
      <TextField
        id={`schema.attribute.${index}.name`}
        placeholder="schema.attribute.name.placeholder"
        value={name}
        onChange={(newName) =>
          onChangeAttributes({
            action: Attributes.Change,
            index,
            newAttr: { name: newName, type },
          })
        }
      />
      <Select<AttributeType>
        id={`schema.attribute.${index}.type`}
        options={ATTRIBUTE_TYPES_OPTIONS}
        value={type}
        onChange={(newType) =>
          onChangeAttributes({
            action: Attributes.Change,
            index,
            newAttr: { name, type: newType as AttributeType },
          })
        }
      />
      <Box className="w-full flex justify-end">
        <Button
          data-testid={`schema.attribute.${index}.delete`}
          variant="text"
          color="error"
          startIcon={<DeleteOutline />}
          onClick={() =>
            onChangeAttributes({ action: Attributes.Delete, index })
          }
        >
          <Typography variant="body2" fontWeight={500}>
            <IntlMessages id="schema.attribute.delete" />
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export { SchemaNewEdit };
