import { Box, Typography } from "@mui/material";
import packageJSON from "../../../../package.json";
import IntlMessages from "../../../components/IntlMessages/IntlMessages";

const APP_VERSION = `v${packageJSON.version}`;
const CURRENT_YEAR = new Date().getFullYear();

const Footer = () => (
  <Box className="mt-auto flex flex-col text-gray-80 space-y-2">
    <Typography variant="body2" className="whitespace-pre-line">
      <IntlMessages id="copyright" />
      {"\nUN Digital ID "}
      {CURRENT_YEAR}
    </Typography>
    <Typography variant="body2">{APP_VERSION}</Typography>
  </Box>
);

export { Footer };
