import { useEffect } from "react";
import useCommonActions from "../../contexts/common/UseCommonActions";
import { useCredentialDefinitionsContext } from "../../contexts/credentialDefinitions/CredentialDefinitionsContext";
import { useSchemasContext } from "../../contexts/schemas/SchemasContext";
import { useUsersContext } from "../../contexts/users/UsersContext";
import { NetworkingServiceErrorType } from "../../core/services/network/NetworkServiceError";
import { notify } from "../../libs/observables/notification";
import { useAuthContext } from "../../contexts/authentication/AuthenticationContext";
import { useCredentialsContext } from "../../contexts/credentials/CredentialsContext";
import { useProofsContext } from "../../contexts/proofs/ProofsContext";

export const AppErrorObserver = () => {
  const { authError } = useAuthContext();
  const { schemasError } = useSchemasContext();
  const { credentialDefinitionsError } = useCredentialDefinitionsContext();
  const { credentialsError } = useCredentialsContext();
  const { proofsError } = useProofsContext();
  const { usersError } = useUsersContext();
  const { dispatchClearErrors } = useCommonActions();

  useEffect(() => {
    const error =
      authError ||
      schemasError ||
      credentialDefinitionsError ||
      credentialsError ||
      proofsError ||
      usersError;

    if (!error) return;

    if (error?.code === NetworkingServiceErrorType.cancelled) {
      return;
    }

    notify("error")({
      message: error.userMessage(),
      autoHideDuration: 6000,
      onClose: dispatchClearErrors,
    });
  }, [
    authError,
    schemasError,
    credentialDefinitionsError,
    credentialsError,
    proofsError,
    usersError,
  ]);

  return null;
};
