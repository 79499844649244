/**
 * CUSTOM
 * This code has been developed for this solution.
 */
import { QueryData, ResponseData } from "../services/network/NetworkTypes";

export type RequestedAttribute = {
  name: string;
  label: string;
  restrictions: Array<Record<"cred_def_id", string>>;
};

export default class Proof {
  constructor(
    public id: string,
    public subjectIdentifier: string,
    public name: string,
    public requestedAttributes: Array<RequestedAttribute>,
    public createdOn: Date
  ) {}

  static fromJSON(json: Record<string, any>): Proof {
    /*
   {
            "id": "drivingLicense",
            "created": "2024-01-19T07:15:42.758237Z",
            "modified": "2024-01-19T07:15:42.776973Z",
            "subject_identifier": "name",
            "configuration": {
                "name": "driverLicenseMultiple",
                "version": "1.0",
                "requested_attributes": [
                    {
                        "name": "name",
                        "label": "0_name",
                        "restrictions": [
                            {
                                "cred_def_id": "Lai5iEisdmtXzRFR1XVQvx:3:CL:18:cd333"
                            }
                        ]
                    },
                    {
                        "name": "license",
                        "label": "0_license",
                        "restrictions": [
                            {
                                "cred_def_id": "Lai5iEisdmtXzRFR1XVQvx:3:CL:18:cd333"
                            }
                        ]
                    },
                ],
                "requested_predicates": []
            }
         },
 * */

    return new Proof(
      json.id,
      json.subject_identifier,
      json.configuration?.name,
      json.configuration?.requested_attributes,
      json.created
    );
  }

  toJSON(): ProofUpdateData {
    return {
      id: this.id,
      subject_identifier: this.subjectIdentifier,
    };
  }
}

export type ProofCreateData = {
  id: string;
  subject_identifier: string;
  configuration: {
    name: string;
    version: string;
    non_revoked: {
      from?: number;
      to?: number;
    };
    requested_attributes: Array<RequestedAttribute>;
  };
};

export type ProofUpdateData = Partial<ProofCreateData>;
// & {
//   id: string;
// };

export type ProofDataFilter = "search";

// Backend keys you can sort with
export type ProofDataOrder = "name" | "created";

// This will help transform the sorting from local to backend keys.
export const proofDataOrderTransform: Record<string, ProofDataOrder> = {
  name: "name",
  created: "created",
};

// These are the keys used for table listings
export type ProofDataTableFields = keyof typeof proofDataOrderTransform;

export type ProofQueryData = QueryData<ProofDataFilter, ProofDataOrder>;

export type ProofResponseData = ResponseData<
  Proof,
  ProofDataFilter,
  ProofDataOrder
>;
