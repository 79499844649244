import {
  LabelDisplayedRowsArgs,
  Pagination,
  TablePagination,
} from "@mui/material";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import { useIntl } from "react-intl";
import { DEFAULT_PAGE_SIZE } from "../../../core/services/network/NetworkTypes";

interface TableFooterProps {
  count: number;
  page: number;
  onPageChange?(page: number): void;
}
const TableFooter = ({ count, page, onPageChange }: TableFooterProps) => {
  const intl = useIntl();

  const getPaginationLabel = ({ from, to, count }: LabelDisplayedRowsArgs) => {
    const results = intl.formatMessage({ id: "table.results" });
    const of = intl.formatMessage({ id: "table.of" });

    return `${from} - ${to} ${of} ${count} ${results}`;
  };

  return count > 0 ? (
    <TablePagination
      data-testid="tableFooter"
      component="div"
      count={count}
      page={page - 1}
      rowsPerPage={DEFAULT_PAGE_SIZE}
      rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
      ActionsComponent={TablePaginationActions}
      labelDisplayedRows={getPaginationLabel}
      onPageChange={(_, page) => onPageChange && onPageChange(page)}
    />
  ) : (
    <></>
  );
};

const TablePaginationActions = ({
  count,
  page,
  onPageChange,
}: TablePaginationActionsProps) => {
  const paginationCount = Math.ceil(count / DEFAULT_PAGE_SIZE);

  return (
    <Pagination
      data-testid="tableFooter.pagination"
      color="primary"
      count={paginationCount}
      page={page + 1}
      onChange={(_, newPage) => onPageChange(null, newPage)}
    />
  );
};

export { TableFooter };
