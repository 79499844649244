import { createContext, useContext, useEffect, useState } from "react";
import {
  DEFAULT_LANGUAGE,
  Language,
  LanguageDirection,
  SUPPORTED_LANGUAGES,
} from "../../lngProvider/constants";
import BaseError from "../../util/BaseError";
import appConfig from "../../util/appConfig";
import dayjs from "dayjs";

const locales = {
  [Language.Arabic]: () => import("dayjs/locale/ar"),
  [Language.Chinese]: () => import("dayjs/locale/zh"),
  [Language.English]: () => import("dayjs/locale/en"),
  [Language.French]: () => import("dayjs/locale/fr"),
  [Language.Russian]: () => import("dayjs/locale/ru"),
  [Language.Spanish]: () => import("dayjs/locale/es"),
};

export const setDayJsLocale = (language: Language) => {
  locales[language]().then(() => dayjs.locale(language));
};

// This context allows the app to get the authentication information

export type LanguageError = BaseError;

// Create type
export type LanguageDataType = {
  language: Language;
  languageDirection: LanguageDirection;
  setLanguage: (language: Language) => void;
  setLanguageDirection: (languageDirection: LanguageDirection) => void;
  languageError: LanguageError | undefined;
  setLanguageError: (error?: LanguageError) => void;
};

// Create context for type
// @ts-ignore
export const LanguageDataContext = createContext<LanguageDataType>();

export const getInitialLanguage = () => {
  const language = navigator.language.split("-")[0];
  const initialLanguage =
    SUPPORTED_LANGUAGES.find((lng) => lng === language) ?? DEFAULT_LANGUAGE;
  return initialLanguage;
};

export const getInitialLanguageDirection = () => {
  const initialLanguage = getInitialLanguage();
  return initialLanguage === Language.Arabic
    ? LanguageDirection.RightToLeft
    : LanguageDirection.LeftToRight;
};
//Create provider of the context
export const LanguageDataProvider = ({ children }: any) => {
  const [language, setLanguage] = useState<Language>(getInitialLanguage());
  const [languageDirection, setLanguageDirection] = useState<LanguageDirection>(
    getInitialLanguageDirection()
  );
  const [languageError, setLanguageError] = useState<LanguageError>();

  const value = {
    language,
    languageDirection,
    setLanguageDirection,
    setLanguage,
    languageError,
    setLanguageError,
  };

  useEffect(() => {
    setDayJsLocale(language);
    /* istanbul ignore next: logs */
    if (appConfig.debugLang)
      console.debug("[Language][LanguageChange] ", language);
  }, [language]);

  useEffect(() => {
    if (languageDirection) {
      /* istanbul ignore next: logs */
      if (appConfig.debugLang)
        console.debug(
          "[Language][LanguageDirectionChange] ",
          languageDirection
        );
    }
  }, [languageDirection]);

  useEffect(() => {
    if (languageError) {
      /* istanbul ignore next: logs */
      if (appConfig.debugLang)
        console.warn(
          "[Language][Error] ",
          languageError.userMessage(),
          languageError.debugMessage()
        );
    }
  }, [languageError]);
  return (
    <LanguageDataContext.Provider value={value}>
      {children}
    </LanguageDataContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageDataContext);
