import axios from "axios";
import {
  AxiosCacheInstance,
  buildMemoryStorage,
  setupCache,
} from "axios-cache-interceptor";
import * as _ from "lodash";
import appConfig from "../../../util/appConfig";
import Credential, {
  CredentialQueryData,
  CredentialResponseData,
} from "../../models/Credential";
import CredentialDefinition, {
  CredentialDefinitionCreateData,
  CredentialDefinitionQueryData,
  CredentialDefinitionResponseData,
  CredentialDefinitionUpdateData,
} from "../../models/CredentialDefinition";
import Proof, {
  ProofCreateData,
  ProofQueryData,
  ProofResponseData,
  ProofUpdateData,
} from "../../models/Proof";
import Schema, {
  SchemaCreateData,
  SchemaQueryData,
  SchemaResponseData,
  SchemaUpdateData,
} from "../../models/Schema";
import User, {
  UserCreateData,
  UserInvitationData,
  UserUpdateData,
  UsersQueryData,
  UsersResponseData,
} from "../../models/User";
import AuthServiceFactory from "../auth/AuthService";
import NetworkingServiceError, {
  NetworkingServiceErrorType,
} from "./NetworkServiceError";
import NetworkServiceInterface from "./NetworkServiceInterface";
import { QueryData, ResponseData } from "./NetworkTypes";

enum BaseUrl {
  IdManager = "ENV_IDMANAGER_URL",
  OidcBridge = "ENV_OIDC_URL",
}

export enum Endpoint {
  Credentials = "/credential-request",
  CredentialDefinitions = "/credential-definition/",
  Dashboard = "/metabase-sign-url",
  LoggedUser = "/users/me",
  Organization = "/organization/",
  Proofs = "/vc-configs",
  Schemas = "/v2/schema/",
  UserInvitation = "/send-invitation",
  Users = "/users/",
}

enum HTTPErrorType {
  Generic,
  Cancelled = -1,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
}

enum SendMethod {
  Post = "post",
  Patch = "put",
}

const RETRY_TIMES = 1;

type DataTransformFn<ModelType> = (json: any) => ModelType;

export class NetworkService implements NetworkServiceInterface {
  private jwtToken?: string;

  //#region Private Helpers
  private processHTTPError(e: any): HTTPErrorType {
    if (e?.response?.status) {
      switch (e?.response?.status) {
        case 401:
          return HTTPErrorType.Unauthorized;
        case 403:
          return HTTPErrorType.Forbidden;
        case 404:
          return HTTPErrorType.NotFound;
      }
    }
    if (e?.code === "ERR_CANCELED") {
      return HTTPErrorType.Cancelled;
    }
    return HTTPErrorType.Generic;
  }

  private getBaseUrl(baseUrl: BaseUrl): string {
    return window[baseUrl]!;
  }

  private axiosApiInstance: AxiosCacheInstance;

  private cacheStorage = buildMemoryStorage();

  private abortControllers: Record<string, AbortController> = {};
  //#endregion

  constructor() {
    const axiosInstance = axios.create({
      baseURL: window.ENV_IDMANAGER_URL,
      timeout: 20000,
    });

    this.axiosApiInstance = setupCache(axiosInstance, {
      debug: (msg) => {
        if (appConfig.debugCache) console.warn("[NetworkService][Cache]", msg);
      },
      cacheTakeover: false,
      storage: this.cacheStorage,
    });

    this.axiosApiInstance.interceptors.response.use(
      (config) => {
        if (appConfig.debugNetworking) {
          const cached = config.cached;
          const id = config.id;
          let message = "[NetworkService]";
          message += cached ? "[CachedRequest][id: " + id + "]" : "[Request]";
          console.debug(message, config);
        }
        return config;
      },
      async (error) => {
        if (appConfig.debugNetworking) {
          console.warn("[NetworkService][RequestError]", error);
        }

        // Retry when 401 unauthorized
        const { config, response } = error;
        if (
          !config ||
          !config.retry ||
          response?.status !== HTTPErrorType.Unauthorized
        ) {
          return Promise.reject(error);
        }

        config.retry -= 1;
        // Update token
        const token = await AuthServiceFactory.shared.getToken();
        if (token) {
          this.token = token;
          config.headers = {
            ...config.headers,
            ...{ Authorization: "Bearer " + this.jwtToken },
          };

          const delayRetryRequest = new Promise((resolve) => {
            setTimeout(() => {
              if (appConfig.debugNetworking) {
                console.log(
                  "[NetworkService][RetryAfterUnauthorized]",
                  config.url
                );
              }
              //@ts-ignore
              resolve();
            }, config.retryDelay || 500);
          });
          return delayRetryRequest.then(() => axios(config));
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  clearCache() {
    if (appConfig.debugCache) {
      console.debug(
        "[NetworkService][Cache] Clear cached values",
        Object.keys(this.cacheStorage.data).length
      );
    }
    this.cacheStorage.data = {};
  }

  //#region Private Request Helpers
  errorWith(
    type: NetworkingServiceErrorType,
    error: any
  ): NetworkingServiceError {
    if (error instanceof NetworkingServiceError) {
      return error;
    }
    const status = this.processHTTPError(error);
    if (status === HTTPErrorType.Cancelled) {
      return new NetworkingServiceError(
        NetworkingServiceErrorType.cancelled,
        error.message || JSON.stringify(error),
        error,
        status
      );
    }
    if (status === HTTPErrorType.NotFound) {
      return new NetworkingServiceError(
        NetworkingServiceErrorType.notFound,
        error.message || JSON.stringify(error),
        error,
        status
      );
    }
    if (status === HTTPErrorType.Unauthorized) {
      // This should not occur, send an unauthorized error
      // this.tokenSubscribers.forEach((cb) => cb(false));
      return new NetworkingServiceError(
        NetworkingServiceErrorType.notAuthenticated,
        error.message || JSON.stringify(error),
        error,
        status
      );
    }
    return new NetworkingServiceError(
      type,
      error.message || JSON.stringify(error),
      error,
      status
    );
  }

  private async getPaginatedRequest<
    Model,
    Filter extends string,
    Order extends string,
  >(
    baseUrl: BaseUrl,
    endpoint: Endpoint,
    queryData: QueryData<Filter, Order>,
    transform: (json: Record<string, any>) => Model,
    errorType: NetworkingServiceErrorType
  ): Promise<ResponseData<Model, Filter, Order>> {
    try {
      let controllerKey = endpoint + JSON.stringify(queryData);
      let controller = this.abortControllers[controllerKey];
      if (controller) {
        controller.abort();
      }
      this.abortControllers[controllerKey] = new AbortController();

      let { filter, ordering, page, size } = queryData;
      let params: Record<string, string | number | Array<string | number>> = {
        limit: size,
        offset: (page - 1) * size,
      };

      if (ordering) {
        params.ordering = ordering
          .map((entry) => {
            let field = Object.keys(entry)[0];
            // @ts-ignore
            let asc = entry[field] as boolean;
            return asc ? field : "-" + field;
          })
          .join(",");
      }
      if (filter) {
        const filtered: any = _.omitBy(filter, _.isUndefined);
        params = {
          ...params,
          ...filtered,
        };
      }
      const result = await this.axiosApiInstance.get(endpoint, {
        baseURL: this.getBaseUrl(baseUrl),
        headers: this.addAuthHeader({}),
        params: params,
        signal: this.abortControllers[controllerKey]?.signal,
        paramsSerializer: {
          indexes: null, // this is needed to get the correct params with array
        },
        // Disable cache till we find a new solution
        cache: false,
        // This will get a cached value
        // id: controllerKey,
        //@ts-ignore
        retry: RETRY_TIMES,
      });
      try {
        const response: ResponseData<Model, Filter, Order> = {
          ...queryData,
          data: [],
          count: 0,
          size: queryData.size,
        };

        if (result?.data?.results) {
          response.data = result.data.results.map(
            (json: Record<string, any>) => {
              return transform(json);
            }
          );
          response.count = result.data.count;
        } else {
          throw new NetworkingServiceError(
            NetworkingServiceErrorType.couldNotTransformData,
            "No data in result",
            result
          );
        }
        return response;
      } catch (e: any) {
        throw this.errorWith(
          NetworkingServiceErrorType.couldNotTransformData,
          e
        );
      }
    } catch (e) {
      throw this.errorWith(errorType, e);
    }
  }

  private transformWith<
    Fn extends DataTransformFn<any>,
    R extends Record<string, any>,
  >(transformFn: Fn, result: R) {
    if (result?.data) {
      return transformFn(result?.data);
    }

    throw new NetworkingServiceError(
      NetworkingServiceErrorType.couldNotTransformData,
      "No data in result",
      result
    );
  }

  /**
   * This is a post or patch helper.
   * @param baseUrl the base URL of the API
   * @param endpoint the endpoint to call
   * @param type the type of call: patch or post
   * @param data the data to send
   * @param dataTransform a method to transform the data
   * @param errorType the type of error to return if this fails
   * @private
   */
  private async sendRequest<ModelType, Data>(
    baseUrl: BaseUrl,
    endpoint: string,
    type: SendMethod,
    data: Data,
    dataTransform: DataTransformFn<ModelType>,
    errorType: NetworkingServiceErrorType,
    reset = true
  ): Promise<ModelType> {
    try {
      const result = await this.axiosApiInstance.request({
        baseURL: this.getBaseUrl(baseUrl),
        url: endpoint,
        method: type,
        headers: this.addAuthHeader({}),
        data,
        //@ts-ignore
        retry: RETRY_TIMES,
      });
      // Remove cached values before returning data
      if (reset) this.clearCache();
      return this.transformWith(dataTransform, result);
    } catch (e) {
      throw this.errorWith(errorType, e);
    }
  }

  /**
   * This is a get helper
   * @param baseUrl the base URL of the API
   * @param endpoint the endpoint to call
   * @param dataTransform the transform to return a model
   * @param errorType the error type to return in case this fails
   * @param cacheId if you want the cache to be updated and used, need to send the id along with the call
   * @param params params if any
   * @private
   */
  private async getRequest<ModelType>(
    baseUrl: BaseUrl,
    endpoint: string,
    dataTransform: DataTransformFn<ModelType>,
    errorType: NetworkingServiceErrorType,
    cacheId?: string,
    params?: Record<string, any>
  ): Promise<ModelType> {
    try {
      const result = await this.axiosApiInstance.get(endpoint, {
        baseURL: this.getBaseUrl(baseUrl),
        headers: this.addAuthHeader({}),
        // Disable cache till we find a new solution
        cache: false,
        // id: cacheId,
        params: params || {},
        //@ts-ignore
        retry: RETRY_TIMES,
      });
      return this.transformWith(dataTransform, result);
    } catch (e) {
      throw this.errorWith(errorType, e);
    }
  }

  /**
   * This is a delete helper
   * @param baseUrl the base URL of the API
   * @param endpoint the endpoint to call
   * @param errorType the error type to return in case this fails
   * @private
   */
  private async deleteRequest(
    baseUrl: BaseUrl,
    endpoint: string,
    errorType: NetworkingServiceErrorType,
    reset = true
  ): Promise<void> {
    try {
      const result = await this.axiosApiInstance.delete(endpoint, {
        baseURL: this.getBaseUrl(baseUrl),
        headers: this.addAuthHeader({}),
        //@ts-ignore
        retry: RETRY_TIMES,
      });
      // Remove cached values
      if (reset) this.clearCache();
    } catch (e) {
      throw this.errorWith(errorType, e);
    }
  }
  //#endregion

  //#region Auth helpers
  private addAuthHeader(
    headers: Record<string, string>
  ): Record<string, string> {
    if (!this.jwtToken) {
      throw new NetworkingServiceError(
        NetworkingServiceErrorType.notAuthenticated,
        "no token",
        {}
      );
    }
    return {
      ...headers,
      ...{ Authorization: "Bearer " + this.jwtToken },
    };
  }
  //#endregion

  //#region Auth
  set token(theToken: string) {
    this.jwtToken = theToken;
  }

  async getLoggedUser(): Promise<User> {
    return this.getRequest(
      BaseUrl.IdManager,
      Endpoint.LoggedUser,
      User.fromJSON,
      NetworkingServiceErrorType.couldNotGetMyDetails
    );
  }

  async getOrgInfo(org: string): Promise<string> {
    return this.getRequest(
      BaseUrl.IdManager,
      `${Endpoint.Organization}${org}/`,
      (data) => data.logo,
      NetworkingServiceErrorType.couldNotGetOrgDetails
    );
  }
  //#endregion

  //#region Dashboard
  /**
   * Get dashboard URL
   * @param dashboardId the dashboard id
   */
  async getDashboardUrl(dashboardId: number = 1): Promise<string> {
    return this.getRequest(
      BaseUrl.IdManager,
      Endpoint.Dashboard,
      (data) => data.signed_url,
      NetworkingServiceErrorType.couldNotGetDashboardUrl,
      undefined,
      { dashboard_id: dashboardId }
    );
  }
  //#endregion

  //#region Schemas
  async getSchemas(queryData: SchemaQueryData): Promise<SchemaResponseData> {
    return this.getPaginatedRequest(
      BaseUrl.IdManager,
      Endpoint.Schemas,
      queryData,
      Schema.fromJSON,
      NetworkingServiceErrorType.couldNotGetListOfSchemas
    );
  }

  async getSchema(schemaId: number): Promise<Schema> {
    return this.getRequest(
      BaseUrl.IdManager,
      `${Endpoint.Schemas}${schemaId}/`,
      Schema.fromJSON,
      NetworkingServiceErrorType.couldNotGetSchemaDetails,
      String(schemaId)
    );
  }

  async patchSchema(schema: SchemaUpdateData): Promise<Schema> {
    const endpoint = `${Endpoint.Schemas}${schema.id}/`;
    let data: Record<string, any> = schema;
    delete data.id; // id not needed
    return this.sendRequest(
      BaseUrl.IdManager,
      endpoint,
      SendMethod.Patch,
      data,
      Schema.fromJSON,
      NetworkingServiceErrorType.couldNotPatchSchema
    );
  }

  async postSchema(schema: SchemaCreateData): Promise<Schema> {
    return this.sendRequest(
      BaseUrl.IdManager,
      Endpoint.Schemas,
      SendMethod.Post,
      schema,
      Schema.fromJSON,
      NetworkingServiceErrorType.couldNotPostNewSchema
    );
  }
  //#endregion

  //#region Credential Definitions
  async getCredentialDefinitions(
    queryData: CredentialDefinitionQueryData
  ): Promise<CredentialDefinitionResponseData> {
    return this.getPaginatedRequest(
      BaseUrl.IdManager,
      Endpoint.CredentialDefinitions,
      queryData,
      CredentialDefinition.fromJSON,
      NetworkingServiceErrorType.couldNotGetListOfCredentialDefinitions
    );
  }

  async getCredentialDefinition(
    credentialDefinitionId: number
  ): Promise<CredentialDefinition> {
    return this.getRequest(
      BaseUrl.IdManager,
      `${Endpoint.CredentialDefinitions}${credentialDefinitionId}/`,
      CredentialDefinition.fromJSON,
      NetworkingServiceErrorType.couldNotGetCredentialDefinitionDetails,
      String(credentialDefinitionId)
    );
  }

  async postCredentialDefinition(
    credentialDefinition: CredentialDefinitionCreateData
  ): Promise<CredentialDefinition> {
    return this.sendRequest(
      BaseUrl.IdManager,
      Endpoint.CredentialDefinitions,
      SendMethod.Post,
      credentialDefinition,
      CredentialDefinition.fromJSON,
      NetworkingServiceErrorType.couldNotPostNewCredentialDefinition
    );
  }

  async patchCredentialDefinition(
    credentialDefinition: CredentialDefinitionUpdateData
  ): Promise<CredentialDefinition> {
    const endpoint = `${Endpoint.CredentialDefinitions}${credentialDefinition.id}/`;
    let data: Record<string, any> = credentialDefinition;
    delete data.id; // id not needed
    return this.sendRequest(
      BaseUrl.IdManager,
      endpoint,
      SendMethod.Patch,
      data,
      CredentialDefinition.fromJSON,
      NetworkingServiceErrorType.couldNotPatchCredentialDefinition
    );
  }
  //#endregion

  //#region Users
  async getUsers(queryData: UsersQueryData): Promise<UsersResponseData> {
    return this.getPaginatedRequest(
      BaseUrl.IdManager,
      Endpoint.Users,
      queryData,
      User.fromJSON,
      NetworkingServiceErrorType.couldNotGetListOfUsers
    );
  }

  async getUser(userId: number): Promise<User> {
    return this.getRequest(
      BaseUrl.IdManager,
      `${Endpoint.Users}${userId}/`,
      User.fromJSON,
      NetworkingServiceErrorType.couldNotGetUserDetails,
      String(userId)
    );
  }

  async postUser(user: UserCreateData): Promise<User> {
    return this.sendRequest(
      BaseUrl.IdManager,
      Endpoint.Users,
      SendMethod.Post,
      user,
      User.fromJSON,
      NetworkingServiceErrorType.couldNotPostNewUser
    );
  }

  async postUserOIDC(user: UserCreateData): Promise<User> {
    return this.sendRequest(
      BaseUrl.OidcBridge,
      Endpoint.Users,
      SendMethod.Post,
      user,
      User.fromJSON,
      NetworkingServiceErrorType.couldNotPostNewUser
    );
  }

  async patchUser(user: UserUpdateData): Promise<User> {
    const endpoint = `${Endpoint.Users}${user.id}/`;
    let data: Record<string, any> = user;
    delete data.id; // id not needed
    return this.sendRequest(
      BaseUrl.IdManager,
      endpoint,
      SendMethod.Patch,
      data,
      User.fromJSON,
      NetworkingServiceErrorType.couldNotPatchUser
    );
  }

  // async sendUserInvitation(data: UserInvitationData): Promise<string> {
  //   return this.sendRequest(
  //     BaseUrl.IdManager,
  //     Endpoint.UserInvitation,
  //     SendMethod.Post,
  //     data,
  //     (connectionId) => connectionId as string,
  //     NetworkingServiceErrorType.couldNotSendUserInvitation
  //   );
  // }

  async deleteUser(userId: number): Promise<void> {
    return this.deleteRequest(
      BaseUrl.IdManager,
      `${Endpoint.Users}${userId}/`,
      NetworkingServiceErrorType.couldNotDeleteUser
    );
  }
  //#endregion

  //#region Credentials
  async getCredentials(
    queryData: CredentialQueryData
  ): Promise<CredentialResponseData> {
    return this.getPaginatedRequest(
      BaseUrl.IdManager,
      Endpoint.Credentials,
      queryData,
      Credential.fromJSON,
      NetworkingServiceErrorType.couldNotGetListOfCredentials
    );
  }
  //#endregion

  //#region Proofs
  async getProofs(queryData: ProofQueryData): Promise<ProofResponseData> {
    return this.getPaginatedRequest(
      BaseUrl.OidcBridge,
      Endpoint.Proofs,
      queryData,
      Proof.fromJSON,
      NetworkingServiceErrorType.couldNotGetListOfProofs
    );
  }

  async getProof(proofId: string): Promise<Proof> {
    return this.getRequest(
      BaseUrl.OidcBridge,
      `${Endpoint.Proofs}/${proofId}`,
      Proof.fromJSON,
      NetworkingServiceErrorType.couldNotGetProofDetails,
      proofId
    );
  }

  async postProof(proof: ProofCreateData): Promise<Proof> {
    return this.sendRequest(
      BaseUrl.OidcBridge,
      Endpoint.Proofs,
      SendMethod.Post,
      proof,
      Proof.fromJSON,
      NetworkingServiceErrorType.couldNotPostNewProof
    );
  }

  async patchProof(proof: ProofUpdateData): Promise<Proof> {
    const endpoint = `${Endpoint.Proofs}/${proof.id}`;
    return this.sendRequest(
      BaseUrl.OidcBridge,
      endpoint,
      SendMethod.Patch,
      proof,
      Proof.fromJSON,
      NetworkingServiceErrorType.couldNotPatchProof
    );
  }
  //#endregion
}

export default class NetworkServiceFactory {
  private static service: NetworkServiceInterface;

  static get shared(): NetworkServiceInterface {
    if (!this.service) {
      this.service = new NetworkService();
    }
    return this.service;
  }
}
