import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import { PageHeader } from "../../components/PageHeader";
import { Select } from "../../components/Select";
import { SelectOptions } from "../../components/Select/Select.container";
import { TextField } from "../../components/TextField";
import { useUsersActions } from "../../contexts/users/UseUsersActions";
import { useUsersContext } from "../../contexts/users/UsersContext";
import {
  UserCreateData,
  UserRole,
  UserUpdateData,
} from "../../core/models/User";
import { notify } from "../../libs/observables/notification";

// const USER_ROLES: SelectOptions<UserRole> = Object.values(UserRole).map(
//   (role) => {
//     return { label: `user.role.${role}`, value: role };
//   }
// );
const EMAIL_VALIDATOR = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const UserNewEdit = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { selectedUser, usersError } = useUsersContext();
  const {
    dispatchGetUser,
    dispatchCreateUser,
    dispatchUpdateUser,
    // dispatchSendUserInvitation,
    dispatchDeleteUser,
    dispatchCleanUsersContext,
  } = useUsersActions();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  // const [roles, setRoles] = useState<Array<UserRole>>();
  const [loading, setLoading] = useState<boolean>(false);
  const formNotChanged =
    !!params.id &&
    !!selectedUser &&
    selectedUser.firstName === firstName &&
    selectedUser.lastName === lastName &&
    selectedUser.email === email;
  // !!roles &&
  // JSON.stringify([...selectedUser.roles].sort()) ===
  //   JSON.stringify([...roles].sort());
  const disableSave =
    !firstName ||
    !lastName ||
    !email ||
    !email.match(EMAIL_VALIDATOR) ||
    // !roles ||
    formNotChanged;

  //#region Load and cleanup
  useEffect(() => {
    if (params.id) {
      dispatchGetUser(+params.id);
    }
    return () => dispatchCleanUsersContext();
  }, []);

  useEffect(() => {
    if (!params.id) {
      if (selectedUser && !usersError) {
        // onSendInvite();
        notify("success")({ message: "User created!" });
        navigate(-1);
      }
    } else {
      if (loading && selectedUser && !usersError) {
        notify("success")({ message: "User updated!" });
      } else if (selectedUser) {
        setFirstName(selectedUser.firstName);
        setLastName(selectedUser.lastName);
        setEmail(selectedUser.email);
        // setRoles(selectedUser.roles);
      }
    }

    setLoading(false);
  }, [selectedUser, usersError]);
  //#endregion

  //#region Page actions
  const onCancel = () => navigate(-1);

  const onSave = () => {
    if (!firstName) {
      notify("error")({ message: "User first name is required!" });
      return;
    }
    if (!lastName) {
      notify("error")({ message: "User last name is required!" });
      return;
    }
    if (!email || !email?.match(EMAIL_VALIDATOR)) {
      notify("error")({ message: "User email is required!" });
      return;
    }
    // if (!roles || !roles.length) {
    //   notify("error")({ message: "User roles is required!" });
    //   return;
    // }

    setLoading(true);

    const user: UserCreateData = {
      first_name: firstName,
      last_name: lastName,
      username: email,
      groups: [UserRole.Web],
    };

    if (params.id) {
      const userModified: UserUpdateData = {
        id: +params.id,
        ...user,
      };
      dispatchUpdateUser(userModified);
    } else {
      dispatchCreateUser(user);
    }
  };
  //#endregion

  //#region User actions
  // const onSendInvite = () => {
  //   dispatchSendUserInvitation({
  //     email: params.id ? selectedUser!.email : email!,
  //   }).then(() => notify("success")({ message: "User invitation sent!" }));
  // };

  const onDeleteUser = () => {
    setLoading(true);
    dispatchDeleteUser(+params.id!)
      .then(() => {
        notify("success")({ message: "User deleted!" });
        navigate(-1);
      })
      .finally(() => setLoading(false));
  };
  //#endregion

  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress data-testid="loader" />
      </Backdrop>
      <Box
        data-testid="user"
        className="min-w-full space-y-[1.875rem] flex-box"
      >
        <PageHeader
          title={params.id ? "user.details" : "user.new"}
          tooltip="users"
          divider
          disableSave={disableSave}
          containerClass="flex-initial"
          saveTitle="save"
          onCancel={onCancel}
          onSave={onSave}
        />
        {/* Form */}
        <Grid container columnSpacing={4} className="flex-auto">
          {/* Left panel */}
          <Grid xs={6} className="flex-box space-y-[1.875rem]">
            <Box className="flex-auto rounded-[10px] bg-gray-input p-[1.875rem]">
              <Box className="rounded-2xl bg-white border border-solid border-gray-background p-6 space-y-6">
                <TextField
                  id="user.firstName"
                  label="user.new.firstName"
                  placeholder="user.new.firstName.placeholder"
                  value={firstName}
                  onChange={setFirstName}
                />
                <TextField
                  id="user.lastName"
                  label="user.new.lastName"
                  placeholder="user.new.lastName.placeholder"
                  value={lastName}
                  onChange={setLastName}
                />
                {/* <Select<UserRole>
                  id="user.roles"
                  label="user.roles"
                  options={USER_ROLES}
                  multiple
                  value={roles}
                  onChange={(roles) => setRoles(roles as Array<UserRole>)}
                /> */}
              </Box>
            </Box>
            {params.id ? (
              <Button
                data-testid="user.delete"
                variant="outlined"
                fullWidth
                onClick={onDeleteUser}
              >
                <IntlMessages id="user.delete" />
              </Button>
            ) : null}
          </Grid>
          {/* Right panel */}
          <Grid xs={6}>
            {/* <Box className="flex items-end space-x-[0.625rem]"> */}
            <TextField
              id="user.email"
              label="user.email"
              placeholder="user.email.placeholder"
              // containerClass="flex-auto"
              value={email}
              onChange={setEmail}
            />
            {/* {params.id ? (
                <Button
                  data-testid="user.sendInvite"
                  disabled={!email?.match(EMAIL_VALIDATOR)}
                  className="py-4"
                  onClick={onSendInvite}
                >
                  <IntlMessages id="user.sendInvite" />
                </Button>
              ) : null} */}
            {/* </Box> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export { UserNewEdit };
