/**
 * CUSTOM
 * This code has been developed for this solution.
 */
import { QueryData, ResponseData } from "../services/network/NetworkTypes";

export enum CredentialStatus {
  Accepted = "Accepted",
  Canceled = "Cancelled",
  Pending = "Pending",
  Revoked = "Revoked",
  Sent = "Sent",
}

export default class Credential {
  constructor(
    public id: number,
    public userId: string,
    public email: string,
    public credDef: string,
    public schema: string,
    public status: CredentialStatus,
    public issuedOn: Date
  ) {}

  static fromJSON(json: Record<string, any>): Credential {
    /*
   {
            "cred_def_id": "2jYo5xjBZe682KxZpxUyqY:3:CL:32:test15",
            "credential_definition_name": "test15",
            "schema_name": "test_schema_12",
            "issuance": "2024-01-16T14:41:01.053138Z",
            "code": "849934c8-67c9-4403-b09c-309f057c45bb",
            "email": "tertinek@unicc.org",
            "invitation_url": "https://idmanager-unicc-digitalid-dev.parana.un-icc.cloud/deep-link-redirect/849934c8-67c9-4403-b09c-309f057c45bb",
            "id": 111,
            "revoked_credential": false,
            "connection_accepted": false,
            "credential_offer_accepted": false,
            "connection_invitation_url": "Imh0dHBzOi8vYWNhLWlkLW1hbmFnZXItdW5pY2MtZGlnaXRhbGlkLWRldi5wYXJhbmEudW4taWNjLmNsb3VkP2NfaT1leUpBZEhsd1pTSTZJQ0prYVdRNmMyOTJPa0o2UTJKelRsbG9UWEpxU0dseFdrUlVWVUZUU0djN2MzQmxZeTlqYjI1dVpXTjBhVzl1Y3k4eExqQXZhVzUyYVhSaGRHbHZiaUlzSUNKQWFXUWlPaUFpT1RJek1ESmlOVEV0WkRGbE1DMDBaakUxTFdKbFlqVXRaVFJsTkdWbFlXSmpNR1ptSWl3Z0lteGhZbVZzSWpvZ0lrbEVJRTFoYm1GblpYSWlMQ0FpY21WamFYQnBaVzUwUzJWNWN5STZJRnNpT0ZCTmIyTk1aakZaVm05R2NrSmhjME5qVFVSSE5FUXpPSGswTTJSWWVIWlJWMFprTTBod09EWlJNVE1pWFN3Z0luTmxjblpwWTJWRmJtUndiMmx1ZENJNklDSm9kSFJ3Y3pvdkwyRmpZUzFwWkMxdFlXNWhaMlZ5TFhWdWFXTmpMV1JwWjJsMFlXeHBaQzFrWlhZdWNHRnlZVzVoTG5WdUxXbGpZeTVqYkc5MVpDSjki",
            "credential_status": "Sent",
            "user_uid_hash": "3808b477a45cdb9c17ccc9c04618893d5b3bb6ee5e9a88f1f0edfb41b60d4b36",
         },
 * */

    return new Credential(
      json.id,
      json.user_uid_hash,
      json.email,
      json.credential_definition_name,
      json.schema_name,
      json.credential_status,
      json.issuance
    );
  }
}

export type CredentialDataFilter = "search";

// Backend keys you can sort with
export type CredentialDataOrder =
  | "email"
  | "credential_definition_name"
  | "schema_name"
  | "credential_status"
  | "issuance";

// This will help transform the sorting from local to backend keys.
export const credentialDataOrderTransform: Record<string, CredentialDataOrder> =
  {
    email: "email",
    credDef: "credential_definition_name",
    schema: "schema_name",
    status: "credential_status",
    issuedOn: "issuance",
  };

// These are the keys used for table listings
export type CredentialDataTableFields =
  keyof typeof credentialDataOrderTransform;

export type CredentialQueryData = QueryData<
  CredentialDataFilter,
  CredentialDataOrder
>;

export type CredentialResponseData = ResponseData<
  Credential,
  CredentialDataFilter,
  CredentialDataOrder
>;
