import { Alert, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import useEvent from "react-use-event-hook";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import { Table } from "../../components/Table";
import {
  TableColumnInterface,
  TableSort,
} from "../../components/Table/TableTypes";
import { useSchemasContext } from "../../contexts/schemas/SchemasContext";
import { useSchemasActions } from "../../contexts/schemas/UseSchemasActions";
import Schema, {
  SchemaDataTableFields,
  schemaDataOrderTransform,
} from "../../core/models/Schema";
import {
  DEFAULT_PAGE_SIZE,
  modelDataOrderTransform,
} from "../../core/services/network/NetworkTypes";
import { useSearchHelper } from "../../hooks/useSearchHelper";
import { DateFormat, getFormattedDate } from "../../util/utils";
import { useAuthContext } from "../../contexts/authentication/AuthenticationContext";
import { EditOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { RoutePath } from "../../defines/route-path";

const Schemas = () => {
  const navigate = useNavigate();
  const { schemasResponseData, schemasLoading } = useSchemasContext();
  const { dispatchGetSchemas, dispatchCleanSchemasContext } =
    useSchemasActions();
  const { userInfo } = useAuthContext();

  //#region Load data
  const onSearch = useEvent(({ page, ordering, search, switchValue }) => {
    if (userInfo) {
      dispatchGetSchemas({
        filter: {
          search,
          organization__name: switchValue ? undefined : userInfo?.organization,
        },
        ordering: modelDataOrderTransform(ordering, schemaDataOrderTransform),
        page,
        size: DEFAULT_PAGE_SIZE,
      }).catch();
    }
  });

  const searchHelper = useSearchHelper<SchemaDataTableFields>({
    onSearch,
  });
  //#endregion

  //#region Table config
  const columns: Array<TableColumnInterface<SchemaDataTableFields>> = [
    { id: "name", label: "schema.name", sortable: true },
    { id: "version", label: "schema.version", sortable: true },
    { id: "schemaId", label: "schema.schemaId", sortable: true },
    {
      id: "enabled",
      label: "schema.enabled",
      sortable: true,
      handler: (row: Schema) => (
        <IntlMessages
          id={`schema.enabled.${row?.enabled ? "valid" : "deprecated"}`}
        />
      ),
    },
    { id: "createdBy", label: "schema.createdBy", sortable: true },
    {
      id: "createdOn",
      label: "schema.createdOn",
      sortable: true,
      handler: (row: Schema) => {
        return row.createdOn
          ? getFormattedDate(row.createdOn, DateFormat.DayOfMonth)
          : "-";
      },
    },
    {
      id: "edit",
      sortable: false,
      cellClass: "px-0",
      handler: (row: Schema, rowNumber: number) =>
        userInfo?.organization === row.createdBy && row.enabled ? (
          <IconButton
            data-testid={`schema.edit.${rowNumber}`}
            className="text-gray-40"
            onClick={() => goToEditSchema(row.id)}
          >
            <EditOutlined />
          </IconButton>
        ) : null,
    },
  ];
  //#endregion

  //#region Actions config
  const goToCreateSchema = () => {
    navigate(RoutePath.NEW_SCHEMA);
  };

  const goToEditSchema = (schemaId: number) => {
    navigate(RoutePath.EDIT_SCHEMA.replace(":id", String(schemaId)));
  };
  //#endregion

  //#region Cleanup
  useEffect(() => {
    searchHelper.setSwitchValue(true);
    return () => dispatchCleanSchemasContext();
  }, []);
  //#endregion

  return (
    <Table
      {...searchHelper.tableProps}
      columns={columns}
      data={schemasResponseData?.data}
      count={schemasResponseData?.count}
      loading={schemasLoading}
      actionTitle="table.add"
      BeforeTable={
        <Alert variant="outlined" severity="info">
          <IntlMessages id="schema.disclaimer.newSchema" />
        </Alert>
      }
      showRefresh
      onSearch={searchHelper.setSearch}
      onChangeSwitch={searchHelper.setSwitchValue}
      onClickAction={goToCreateSchema}
    />
  );
};

export { Schemas };
