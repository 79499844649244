import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import IntlMessages, { TranslationKey } from "../IntlMessages/IntlMessages";

interface ModalBaseProps extends DialogProps {
  id?: string;
  open: boolean;
  actions?: JSX.Element;
  onClose(): void;
}

interface ModalTitleText extends ModalBaseProps {
  title: TranslationKey;
  titleElement?: never;
}

interface ModalTitleElement extends ModalBaseProps {
  title?: never;
  titleElement: JSX.Element;
}

type ModalProps = ModalTitleText | ModalTitleElement;

const Modal = ({
  id,
  open,
  title,
  titleElement,
  children,
  actions,
  onClose,
  ...props
}: ModalProps) => {
  const testId = id ?? "modal";

  return (
    <Dialog
      {...props}
      data-testid={testId}
      fullWidth
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle component="div">
        {title ? (
          <Typography
            data-testid={`${testId}.title`}
            variant="h2"
            fontWeight={500}
          >
            <IntlMessages id={title} />
          </Typography>
        ) : (
          titleElement
        )}
        <IconButton
          data-testid={`${testId}.closeButton`}
          aria-label={`close ${id}`}
          onClick={() => onClose()}
        >
          <Close className="text-[2rem]" />
        </IconButton>
      </DialogTitle>
      <DialogContent data-testid={`${testId}.content`}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions data-testid={`${testId}.actions`}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export { Modal };
