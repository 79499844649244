import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAuthContext } from "../contexts/authentication/AuthenticationContext";
import useAuthenticationActions from "../contexts/authentication/UseAuthenticationActions";
import { Login } from "../pages/Login";
import { SideMenu } from "./SideMenu";
import { TopBar } from "./TopBar";

const Layout = () => {
  const isAuthenticated = useIsAuthenticated();
  const { authenticated } = useAuthContext();
  const { dispatchGetMyUserInfo } = useAuthenticationActions();

  useEffect(() => {
    if (isAuthenticated) dispatchGetMyUserInfo();
  }, [isAuthenticated]);

  return (
    <>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {authenticated ? (
          <Box className="flex min-h-screen">
            <Box className="flex-1 max-w-[15rem]">
              <SideMenu />
            </Box>
            <Box className="flex-1 min-h-screen flex flex-col overflow-hidden">
              <TopBar />
              <Box
                id="contentWrapper"
                data-testid="layout.contentWrapper"
                className="flex flex-1 overflow-auto pt-8 pb-[4.25rem] px-[1.875rem]"
              >
                <Outlet />
              </Box>
            </Box>
          </Box>
        ) : (
          <Backdrop open>
            <CircularProgress />
          </Backdrop>
        )}
      </AuthenticatedTemplate>
    </>
  );
};

export { Layout };
