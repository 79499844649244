import { TableBody, TableCell, TableRow } from "@mui/material";
import { TableContentInterface } from "../TableTypes";

const TableContent = <IdField extends string, Model>({
  data,
  columns,
  rowRules,
  onClickRow,
}: TableContentInterface<IdField, Model>) => {
  return (
    <TableBody data-testid="tableContent">
      {data.map((row, rowNumber) => {
        const disabled = rowRules?.disableWhen?.(row) || false;
        const rowId = `row.${rowNumber}`;
        let cellCount = 0;

        return (
          <TableRow
            hover
            key={rowId}
            id={rowId}
            data-testid={`tableContent.${rowId}`}
            onClick={() => onClickRow && onClickRow(row)}
          >
            {columns &&
              columns.length > 0 &&
              columns.map((column, colNumber) => {
                const cellContent = column?.handler
                  ? column.handler(row, rowNumber)
                  : // @ts-ignore
                    row[column.id] || "-";
                const cellId = `${rowId}-col-${cellCount++}`;

                return (
                  <TableCell
                    key={cellId}
                    id={cellId}
                    data-testid={`tableCell.${rowNumber}.${colNumber}`}
                    align={column.align || "left"}
                    className={column.cellClass}
                  >
                    {cellContent}
                  </TableCell>
                );
              })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export { TableContent };
