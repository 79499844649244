import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { OidcAuth } from "unicc-oidc-auth";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import { Modal } from "../../components/Modal";
import qrCodeSuccess from "../../assets/images/qrcode-success.svg";
import qrCodeError from "../../assets/images/qrcode-error.svg";

enum Step {
  QrCode,
  Success,
  Error,
}

type QrCodeModalProps = {
  open: boolean;
  proofId: string;
  onClose(): void;
};

const QrCodeModal = ({ open, proofId, onClose }: QrCodeModalProps) => {
  const [step, setStep] = useState<Step>(Step.QrCode);
  const feedback = [Step.Success, Step.Error].includes(step);
  const successOrError = step === Step.Success ? "success" : "error";

  const qrCode = step === Step.QrCode && (
    <OidcAuth
      appUrl={window.ENV_APP_URL}
      oidcUrl={window.ENV_OIDC_URL.replace("/api/", "")}
      oidcClientId={window.ENV_OIDC_CLIENTID}
      proofId={proofId}
      renderQrCode={({ qrCode, maxReloadsExceeded }) => (
        <>
          {maxReloadsExceeded ? (
            <span data-testid="qrCodeModal.maxReloadsExceeded">
              Max reloads exceeded
            </span>
          ) : (
            <Box className="w-64">{qrCode}</Box>
          )}
        </>
      )}
      onDataLoaded={() => setStep(Step.Success)}
      onError={() => setStep(Step.Error)}
    />
  );

  const message = feedback && (
    <>
      <img
        data-testid={`qrCodeModal.${successOrError}.image`}
        src={step === Step.Success ? qrCodeSuccess : qrCodeError}
        alt={
          step === Step.Success
            ? "data successfully read"
            : "error while reading"
        }
        className="-my-10"
      />
      <Typography
        data-testid={`qrCodeModal.${successOrError}.text`}
        variant="h6"
      >
        <IntlMessages id={`proof.testQrCode.${successOrError}Message`} />
      </Typography>
    </>
  );

  return (
    <Modal
      id="qrCodeModal"
      title="proof.testQrCode.title"
      open={open}
      onClose={() => onClose()}
    >
      <Box className="flex flex-col justify-center items-center py-4 space-y-10">
        {qrCode}
        {message}
      </Box>
    </Modal>
  );
};

export { QrCodeModal };
