/**
 * CUSTOM
 * This code has been developed for this solution.
 */
import {
  SchemaCreateData,
  SchemaQueryData,
  SchemaUpdateData,
} from "../../core/models/Schema";
import NetworkServiceFactory from "../../core/services/network/NetworkService";
import { DEFAULT_QUERY_DATA } from "../../core/services/network/NetworkTypes";
import { useSchemasContext } from "./SchemasContext";

const useSchemasActions = () => {
  const {
    setSchemasResponseData,
    setSchemasLoading,
    setSelectedSchema,
    setSchemasError,
    schemasResponseData,
  } = useSchemasContext();

  const dispatchGetSchemas = async (
    query: SchemaQueryData = DEFAULT_QUERY_DATA()
  ) => {
    let cancelLoading = false;
    setTimeout(() => {
      if (!cancelLoading) {
        setSchemasLoading(true);
      }
    }, 400);
    const networkService = NetworkServiceFactory.shared;
    try {
      const data = await networkService.getSchemas(query);
      setSchemasResponseData(data);
      setSchemasError(undefined);
      return data;
    } catch (e: any) {
      setSchemasError(e);
    } finally {
      cancelLoading = true;
      setSchemasLoading(false);
    }
  };

  const dispatchGetSchema = (schemaId: number) => {
    const networkService = NetworkServiceFactory.shared;
    networkService
      .getSchema(schemaId)
      .then((schema) => {
        setSelectedSchema(schema);
        setSchemasError(undefined);
      })
      .catch((e) => {
        setSchemasError(e);
      });
  };

  const dispatchCreateSchema = (query: SchemaCreateData) => {
    const asyncCall = async () => {
      try {
        const data = await NetworkServiceFactory.shared.postSchema(query);
        try {
          if (schemasResponseData) {
            const data =
              await NetworkServiceFactory.shared.getSchemas(
                schemasResponseData
              );
            setSchemasResponseData(data);
          }
        } catch (e) {
          // Pass
        }
        setSelectedSchema(data);
        setSchemasError(undefined);
      } catch (e: any) {
        setSchemasError(e);
      }
    };
    asyncCall().catch();
  };

  const dispatchUpdateSchema = (query: SchemaUpdateData) => {
    const asyncCall = async () => {
      try {
        const data = await NetworkServiceFactory.shared.patchSchema(query);
        try {
          if (schemasResponseData) {
            const data =
              await NetworkServiceFactory.shared.getSchemas(
                schemasResponseData
              );
            setSchemasResponseData(data);
          }
        } catch (e) {
          // Pass
        }
        setSelectedSchema(data);
        setSchemasError(undefined);
      } catch (e: any) {
        setSchemasError(e);
      }
    };
    asyncCall().catch();
  };

  const dispatchCleanSchemasContext = () => {
    setSchemasResponseData(undefined);
    setSchemasError();
    setSelectedSchema();
  };

  return {
    dispatchGetSchemas,
    dispatchGetSchema,
    dispatchCleanSchemasContext,
    dispatchCreateSchema,
    dispatchUpdateSchema,
  };
};

export { useSchemasActions };
