import {
  ChevronLeft,
  ChevronRight,
  ExpandMore,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  ListItemButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import IntlMessages from "../../components/IntlMessages/IntlMessages";
import { Modal } from "../../components/Modal";
import { SearchBox } from "../../components/SearchBox";
import { TextStrong } from "../../components/TextStrong";
import { useAuthContext } from "../../contexts/authentication/AuthenticationContext";
import { useCredentialDefinitionsContext } from "../../contexts/credentialDefinitions/CredentialDefinitionsContext";
import { useCredentialDefinitionsActions } from "../../contexts/credentialDefinitions/UseCredentialDefinitionsActions";
import { useSchemasContext } from "../../contexts/schemas/SchemasContext";
import { useSchemasActions } from "../../contexts/schemas/UseSchemasActions";
import CredentialDefinition from "../../core/models/CredentialDefinition";
import { GRAY_60 } from "../../themes/colors";

type Attribute = {
  name: string;
  orgs: Orgs;
};

type Org = {
  name: string;
  credDefs: CredentialDefinitions;
  expanded?: boolean;
};

export type AttributeCredDefs = {
  name: string;
  credDefs: CredentialDefinitions;
  expanded?: boolean;
};

type Attributes = Array<Attribute>;
type Orgs = Array<Org>;
type CredentialDefinitions = Array<CredentialDefinition>;

enum Step {
  Attribute = "attribute",
  CredentialDefinitions = "credDef",
}

type AttributesModalProps = {
  open: boolean;
  selectedAttribute?: AttributeCredDefs;
  onClose(attribute?: AttributeCredDefs): void;
};

const AttributesModal = ({
  open,
  selectedAttribute,
  onClose,
}: AttributesModalProps) => {
  const intl = useIntl();
  const { schemasResponseData } = useSchemasContext();
  const { dispatchGetSchemas, dispatchCleanSchemasContext } =
    useSchemasActions();
  const { credentialDefinitionsResponseData } =
    useCredentialDefinitionsContext();
  const {
    dispatchGetCredentialDefinitions,
    dispatchCleanCredentialDefinitionsContext,
  } = useCredentialDefinitionsActions();
  const { userInfo } = useAuthContext();

  const [step, setStep] = useState<Step>(Step.Attribute);
  const [loadingAttr, setLoadingAttr] = useState<boolean>(true);
  const [attributesWithCredDef, setAttributesWithCredDef] =
    useState<Attributes>();
  const [attributesList, setAttributesList] = useState<Attributes>();
  const [attributeSelected, setAttributeSelected] = useState<Attribute>();
  const [orgsList, setOrgsList] = useState<Orgs>();
  const [credDefsSelected, setCredDefsSelected] =
    useState<CredentialDefinitions>([]);
  const [search, setSearch] = useState<string>();

  const credDefSelectedText = intl.formatMessage(
    {
      id: `proof.credentialDefinitions.selected.${
        credDefsSelected.length > 1 ? "multiple" : "single"
      }`,
    },
    { selected: credDefsSelected.length }
  );

  //#region Load schemas and credential definitions
  const loadSchemas = () => {
    if (userInfo) {
      dispatchGetSchemas({
        ordering: [{ created: false }],
        page: 1,
        size: 1000,
      }).catch();
    }
  };

  const loadCredentialDefinitions = () => {
    if (userInfo) {
      dispatchGetCredentialDefinitions({
        filter: { enabled: true },
        ordering: [{ created: false }],
        page: 1,
        size: 1000,
      }).catch();
    }
  };

  useEffect(() => {
    if (
      schemasResponseData &&
      schemasResponseData.data.length &&
      credentialDefinitionsResponseData &&
      credentialDefinitionsResponseData.data.length
    ) {
      loadAttributes();
    }
  }, [schemasResponseData, credentialDefinitionsResponseData]);

  useEffect(() => {
    loadSchemas();
    loadCredentialDefinitions();

    return () => {
      dispatchCleanSchemasContext();
      dispatchCleanCredentialDefinitionsContext();
    };
  }, []);
  //#endregion

  //#region Load attributes
  const loadAttributes = () => {
    const attributes: Attributes = [];

    credentialDefinitionsResponseData!.data.forEach((credDef) => {
      const schema = schemasResponseData!.data.find(
        (s) => s.schemaId === String(credDef.schemaId)
      );
      if (schema) {
        schema.attributes.forEach((attr) => {
          const attrAdded = attributes.find((a) => a.name === attr.name);
          // If attribute already exist add org, else add attr/org/cred def
          if (attrAdded) {
            const orgInAttr = attrAdded.orgs.find(
              (o) => o.name === credDef.createdBy
            );
            // If org already exist add credential definition, else add org/cred def
            if (orgInAttr) {
              const credDefInOrg = orgInAttr.credDefs.find(
                (c) => c.id === credDef.id
              );
              // Add credential definition if not yet added
              if (!credDefInOrg) {
                orgInAttr.credDefs.push(credDef);
              }
            } else {
              attrAdded.orgs.push({
                name: credDef.createdBy,
                credDefs: [credDef],
              });
            }
          } else {
            attributes.push({
              name: attr.name,
              orgs: [{ name: credDef.createdBy, credDefs: [credDef] }],
            });
          }
        });
      }
    });

    // If editing attribute, select attribute and cred defs
    if (selectedAttribute) {
      const { name, credDefs } = selectedAttribute;
      const attribute = attributes.find((a) => a.name === name);
      const lastOrg = attribute?.orgs.find(
        (o) => o.name === credDefs.at(-1)?.createdBy
      );
      lastOrg!.expanded = true;
      handleSelectAttribute(attribute!);
      setCredDefsSelected(credDefs);
    }

    setAttributesWithCredDef(attributes);
    setAttributesList(attributes);
    setLoadingAttr(false);
  };

  useEffect(() => {
    switch (step) {
      case Step.Attribute:
        if (attributesWithCredDef) {
          const attributesFiltered = !!search
            ? attributesWithCredDef.filter((attr) =>
                attr.name.toLowerCase().includes(search?.toLowerCase())
              )
            : attributesWithCredDef;
          setAttributesList(attributesFiltered);
        }
    }
  }, [search]);
  //#endregion

  //#region Selection handlers
  const resetFilters = () => {
    setSearch(undefined);
  };

  const handleSelectAttribute = (attr: Attribute) => {
    setAttributeSelected(attr);
    setOrgsList(attr.orgs);
    setStep(Step.CredentialDefinitions);
    resetFilters();
  };

  const handleGoBackToAttributes = () => {
    setStep(Step.Attribute);
    resetFilters();
  };

  const handleOrgExpand = (org: Org) => {
    const newOrgs = [...orgsList!];
    const orgExpanded = newOrgs.find((o) => o === org);
    orgExpanded!.expanded = !orgExpanded?.expanded;
    setOrgsList(newOrgs);
  };

  const handleSelectCredDef = (
    credDef: CredentialDefinition,
    checked: boolean
  ) => {
    const newCredDefs = [...credDefsSelected];
    if (checked) {
      newCredDefs.push(credDef);
      setCredDefsSelected(newCredDefs);
    } else {
      setCredDefsSelected(newCredDefs.filter((cd) => cd.id !== credDef.id));
    }
  };

  const handleModalClose = () =>
    onClose({
      name: attributeSelected?.name!,
      credDefs: credDefsSelected,
      expanded: selectedAttribute ? selectedAttribute.expanded : true,
    });
  //#endregion

  //#region Renderers
  const title = useMemo(
    () => (
      <>
        {step === Step.CredentialDefinitions && (
          <Box className="flex items-center">
            <IconButton
              aria-label="go back to attributes"
              onClick={handleGoBackToAttributes}
            >
              <ChevronLeft color="primary" className="text-2xl" />
            </IconButton>
            <Chip color="info" label={attributeSelected?.name} />
          </Box>
        )}
        <Box className="flex space-x-[0.625rem]">
          <Typography variant="h2" fontWeight={500}>
            <IntlMessages id={`proof.select.${step}`} />
          </Typography>
          {step === Step.Attribute && (
            <Tooltip title={<IntlMessages id="proof.select.attribute" />}>
              <IconButton aria-label="more info" className="p-0">
                <InfoOutlined />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </>
    ),
    [step]
  );

  const loader = useMemo(
    () =>
      loadingAttr && (
        <Box
          data-testid="attributesModal.loader"
          className="min-w-full flex justify-center"
        >
          <CircularProgress color="secondary" />
        </Box>
      ),
    [loadingAttr]
  );

  const searchBox = useMemo(
    () =>
      !loadingAttr && (
        <SearchBox
          id="attributesModal.searchBox"
          delay={500}
          value={search}
          onSearch={setSearch}
        />
      ),
    [loadingAttr, step]
  );

  const attributes = useMemo(
    () =>
      step === Step.Attribute && (
        <>
          <Grid container alignItems="center">
            <Grid xs={4}>{searchBox}</Grid>
          </Grid>
          {attributesList && Object.keys(attributesList).length ? (
            <RadioGroup
              data-testid="attributesModal.attributesList"
              aria-label="attributes list"
              className="space-y-3"
              value={attributeSelected?.name}
            >
              {attributesList.map((attr, index) => {
                const { name } = attr;

                return (
                  <React.Fragment key={index}>
                    <ListItemButton
                      data-testid={`attributesModal.attr.${index}`}
                      className={cn(
                        "rounded-lg p-3",
                        attributeSelected === attr && "bg-gray-background"
                      )}
                      onClick={() => handleSelectAttribute(attr)}
                    >
                      {/* Attribute */}
                      <Grid container className="w-full items-center space-x-3">
                        <Grid xs="auto">
                          <Radio className="p-0" value={name} />
                        </Grid>
                        <Typography fontWeight={500}>{name}</Typography>
                      </Grid>
                    </ListItemButton>
                  </React.Fragment>
                );
              })}
            </RadioGroup>
          ) : (
            <></>
          )}
        </>
      ),
    [step, attributesList, attributeSelected, loadingAttr]
  );

  const credDefs = useMemo(
    () =>
      step === Step.CredentialDefinitions && (
        <>
          <Grid container>
            {/* Credentials list */}
            <Grid xs={6} className="pr-3">
              <Box className="space-y-[0.625rem]">
                {searchBox}
                {orgsList && (
                  <Box>
                    {orgsList.map((org, index) => {
                      const { name: orgName, expanded } = org;

                      return (
                        <Box
                          key={orgName}
                          className={cn(
                            "border-b border-solid",
                            "border-gray-background last:border-b-0"
                          )}
                        >
                          <>
                            <ListItemButton
                              data-testid={`attributesModal.credDef.${index}`}
                              className="rounded-2xl p-0 hover:bg-white"
                              onClick={() => handleOrgExpand(org)}
                            >
                              {/* Org */}
                              <Box className="w-full flex justify-between items-center py-[1.25rem]">
                                <Typography>{orgName}</Typography>
                                {expanded ? (
                                  <ExpandMore color="secondary" />
                                ) : (
                                  <ChevronRight color="secondary" />
                                )}
                              </Box>
                            </ListItemButton>
                            {expanded ? (
                              <Box className="py-[1.25rem] space-y-[1.875rem]">
                                {org.credDefs.map((cd, index) => {
                                  const { name: credDefName, credentialId } =
                                    cd;

                                  return (
                                    <Box
                                      key={index}
                                      className="flex items-start px-3 space-x-3"
                                    >
                                      <Checkbox
                                        checked={credDefsSelected.some(
                                          (c) => c.id === cd.id
                                        )}
                                        onChange={(_, checked) =>
                                          handleSelectCredDef(cd, checked)
                                        }
                                      />
                                      <Box className="overflow-hidden">
                                        <Typography fontWeight={500}>
                                          {credDefName}
                                        </Typography>
                                        <Tooltip title={credentialId}>
                                          <Typography
                                            variant="caption"
                                            component="p"
                                            className="truncate"
                                          >
                                            {credentialId}
                                          </Typography>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  );
                                })}
                              </Box>
                            ) : (
                              <></>
                            )}
                          </>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Grid>
            {/* Selected credentials */}
            <Grid xs={6} className="pl-3">
              <Box
                className={cn(
                  "h-full",
                  "rounded-lg",
                  "border border-solid border-gray-background",
                  "p-[1.875rem] space-y-6"
                )}
              >
                <Box className="space-y-4">
                  <Typography className="text-gray-60">
                    <IntlMessages id="proof.select.selectedCredentials" />
                  </Typography>
                  <Divider />
                </Box>
                <Box className="space-y-4">
                  {credDefsSelected.length ? (
                    credDefsSelected.map((cds, index) => {
                      const { createdBy, credentialId, name } = cds;

                      return (
                        <Box key={index} className="space-y-1">
                          <Box className="flex items-center space-x-[0.625rem]">
                            <Typography fontWeight={500}>
                              {createdBy}
                            </Typography>
                            <Typography fontWeight={500}>/</Typography>
                            <Typography fontWeight={500}>{name}</Typography>
                          </Box>
                          <Tooltip title={credentialId}>
                            <Typography
                              variant="caption"
                              component="p"
                              className="truncate"
                            >
                              {credentialId}
                            </Typography>
                          </Tooltip>
                        </Box>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Alert
            severity="info"
            icon={false}
            className="border border-solid border-blue-dark px-4 py-[1.25rem]"
          >
            <Box className="flex space-x-2">
              <Typography>•</Typography>
              <TextStrong id="proof.select.alert.firstMessage" />
            </Box>
            <Box className="flex space-x-2">
              <Typography>•</Typography>
              <TextStrong id="proof.select.alert.secondMessage" />
            </Box>
          </Alert>
        </>
      ),
    [step, orgsList, credDefsSelected]
  );

  const content = (
    <Box className="space-y-6">
      {loader}
      {attributes}
      {credDefs}
    </Box>
  );

  const actions = (
    <>
      <Typography color={GRAY_60}>
        {credDefsSelected.length ? credDefSelectedText : <span></span>}
      </Typography>
      <Box className="flex space-x-[1.375rem]">
        <Button
          data-testid="attributesModal.cancel"
          variant="text"
          color="error"
          onClick={() => onClose()}
        >
          <IntlMessages id="cancel" />
        </Button>
        <Button
          data-testid="attributesModal.save"
          disabled={!attributeSelected || !credDefsSelected?.length}
          onClick={handleModalClose}
        >
          <IntlMessages id="ok" />
        </Button>
      </Box>
    </>
  );
  //#endregion

  return (
    <Modal
      id="attributesModal"
      titleElement={title}
      maxWidth="md"
      open={open}
      actions={actions}
      onClose={() => onClose()}
    >
      {content}
    </Modal>
  );
};

export { AttributesModal };
