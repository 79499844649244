import { createContext, useContext, useEffect, useState } from "react";
import Proof, { ProofResponseData } from "../../core/models/Proof";
import NetworkingServiceError from "../../core/services/network/NetworkServiceError";
import appConfig from "../../util/appConfig";

// This context allows the app to get the proofs information

export type ProofError = NetworkingServiceError;

// Create type
export type ProofsDataType = {
  proofsResponseData?: ProofResponseData;
  setProofsResponseData: (responseData?: ProofResponseData) => void;
  selectedProof?: Proof;
  setSelectedProof: (proof?: Proof) => void;
  proofsLoading: boolean;
  setProofsLoading: (isLoading: boolean) => void;
  proofsError: ProofError | undefined;
  setProofsError: (error?: ProofError) => void;
};

// Create context for type
// @ts-ignore
export const ProofsDataContext = createContext<ProofsDataType>();

//Create provider of the context
export const ProofsDataProvider = ({ children }: any) => {
  const [selectedProof, setSelectedProof] = useState<Proof>();
  const [proofsResponseData, setProofsResponseData] =
    useState<ProofResponseData>();
  const [proofsLoading, setProofsLoading] = useState<boolean>(false);
  const [proofsError, setProofsError] = useState<ProofError>();

  const value = {
    proofsResponseData,
    setProofsResponseData,
    selectedProof,
    setSelectedProof,
    proofsLoading,
    setProofsLoading,
    proofsError,
    setProofsError,
  };
  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugProofs && selectedProof)
      console.debug("[Proofs][SelectedProof] ", selectedProof);
  }, [selectedProof]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugProofs && proofsResponseData)
      console.debug("[Proofs][ProofsResponseData] ", proofsResponseData);
  }, [proofsResponseData]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugProofs && proofsLoading)
      console.debug("[Proofs][ProofsLoading] ", proofsLoading);
  }, [proofsLoading]);

  useEffect(() => {
    /* istanbul ignore next: logs */
    if (appConfig.debugProofs && proofsError)
      console.warn(
        "[Proofs][Error] ",
        proofsError?.userMessage(),
        proofsError?.debugMessage()
      );
  }, [proofsError]);
  return (
    <ProofsDataContext.Provider value={value}>
      {children}
    </ProofsDataContext.Provider>
  );
};

export const useProofsContext = () => useContext(ProofsDataContext);
