/**
 * CUSTOM
 * This code has been developed for this solution.
 */

import BaseError from "../../../util/BaseError";

/**
 * Error type for credentials
 */
export enum NetworkingServiceErrorType {
  generic = 3000,
  cancelled,
  notFound,
  notAuthenticated,
  couldNotTransformData,
  couldNotAuthenticate,
  couldNotGetMyDetails,
  couldNotGetOrgDetails,
  couldNotGetDashboardUrl,
  couldNotGetListOfSchemas,
  couldNotGetSchemaDetails,
  couldNotPostNewSchema,
  couldNotPatchSchema,
  couldNotGetListOfCredentialDefinitions,
  couldNotGetCredentialDefinitionDetails,
  couldNotPostNewCredentialDefinition,
  couldNotPatchCredentialDefinition,
  couldNotGetListOfUsers,
  couldNotGetUserDetails,
  couldNotPostNewUser,
  couldNotPatchUser,
  couldNotSendUserInvitation,
  couldNotDeleteUser,
  couldNotGetListOfCredentials,
  couldNotGetListOfProofs,
  couldNotGetProofDetails,
  couldNotPostNewProof,
  couldNotPatchProof,
}

/**
 * Error to be thrown by the service, so we can assess the user on how to proceed
 */
export default class NetworkingServiceError extends BaseError {
  constructor(
    code: NetworkingServiceErrorType = NetworkingServiceErrorType.generic,
    message = "",
    ...params: any[]
  ) {
    super(code, message, ...params);
    this.name = "NetworkingServiceError";
    this.code = code;
    this.params = params;
    this.message = ": " + message;
  }

  /**
   * This is a user-friendly message
   */

  userMessage(): string {
    switch (this.code) {
      case NetworkingServiceErrorType.couldNotTransformData:
        return "There was a communication problem. A response was received but the format is not right.";
      case NetworkingServiceErrorType.notFound:
        return "Requested data could not be found.";
      case NetworkingServiceErrorType.cancelled:
        return "Request was cancelled.";
      case NetworkingServiceErrorType.notAuthenticated:
        return "Not authenticated. Please log in first.";
      case NetworkingServiceErrorType.couldNotAuthenticate:
        return "Could not authenticate. Please check your credentials and network connection.";
      case NetworkingServiceErrorType.couldNotGetMyDetails:
        return "Could not get your user details. Please check your credentials and network connection.";
      case NetworkingServiceErrorType.couldNotGetOrgDetails:
        return "Could not get organization details. Please check your credentials and network connection.";
      case NetworkingServiceErrorType.couldNotGetDashboardUrl:
        return "Could not get dashboard URL. Please check your credentials and network connection.";
      case NetworkingServiceErrorType.couldNotGetListOfSchemas:
        return "Could not get schemas list. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotGetSchemaDetails:
        return "Could not get schema details. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotPostNewSchema:
        return "Could not create new schema. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotPatchSchema:
        return "Could not update the schema. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotGetListOfCredentialDefinitions:
        return "Could not get credential definitions list. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotGetCredentialDefinitionDetails:
        return "Could not get credential definition details. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotPostNewCredentialDefinition:
        return "Could not create new credential definition. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotPatchCredentialDefinition:
        return "Could not update the credential definition. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotGetListOfUsers:
        return "Could not get users list. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotGetUserDetails:
        return "Could not get user details. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotPatchUser:
        return "Could not update the user. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotPostNewUser:
        return "Could not create new user. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotSendUserInvitation:
        return "Could not send invitation email to user. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotDeleteUser:
        return "Could not delete user. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotGetListOfCredentials:
        return "Could not get credentials list. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotGetListOfProofs:
        return "Could not get proofs list. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotGetProofDetails:
        return "Could not get proof details. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotPostNewProof:
        return "Could not create new proof. Please check your network connection.";
      case NetworkingServiceErrorType.couldNotPatchProof:
        return "Could not update the proof. Please check your network connection.";
      case NetworkingServiceErrorType.generic:
      default:
        return "There was a network error. Please check your network connection.";
    }
  }
}
