import { Typography } from "@mui/material";
import IntlMessages, { TranslationKey } from "../IntlMessages/IntlMessages";

interface TextStrongProps {
  id: TranslationKey;
}

const TextStrong = ({ id }: TextStrongProps) => (
  <Typography>
    <IntlMessages
      id={id}
      //@ts-ignore
      values={{ strong: (text) => <strong>{text}</strong> }}
    />
  </Typography>
);

export { TextStrong };
