export enum Organization {
  UN = "UN",
  UNDP = "UNDP",
  UNHCR = "UNHCR",
  UNICC = "UNICC",
  UNICEF = "UNICEF",
  UNJSPF = "UNJSPF",
  WFP = "WFP",
}

export const ORGS_WITH_OIDC = [Organization.UNJSPF, Organization.UNICC];

export const OrgDomain = {
  [Organization.UN]: "un.org",
  [Organization.UNDP]: "undp.org",
  [Organization.UNHCR]: "unhcr.org",
  [Organization.UNICC]: "un.org",
  [Organization.UNICEF]: "unicef.org",
  [Organization.UNJSPF]: "un.org",
  [Organization.WFP]: "wfp.org",
};
