import { Box, Divider, Link, Typography } from "@mui/material";
import unLogoMobile from "../../assets/logos/un-logo.png";
import unLogo from "../../assets/logos/un-logo-text.png";
import undidLogo from "../../assets/logos/undid-logo-text.svg";
import undidLogoMobile from "../../assets/logos/undid-logo-notext-blue.png";
import useResponsive from "../../hooks/useResponsive";
import cn from "classnames";

const PrivacyPolicy = () => {
  const { isMobile } = useResponsive();

  //#region Texts
  const mainTitle = "UN Digital Wallet Privacy Policy";
  const mainParagraph = [
    "The UN Digital Wallet Application, \
    owned and provided by the United Nations International Computing Centre (UNICC) \
    on behalf of UN Agencies participating to UN Digital ID project, \
    is designed to provide convenient device-based storage for its users' UN-issued staff-related personal data. \
    This privacy policy describes what personal data is processed through the Application. \
    Please note that the terms of this privacy policy may change without prior notification. \
    You are encouraged to review this policy regularly. You can contact UNICC at ",
    <Link key="email">servicedesk@unicc.org</Link>,
    ".\n\nThis privacy policy only refers to the identifying information that is retrieved from a UN Agency \
    and stored on your Application. It does not refer to any other personal data \
    that may be processed by any UN Agency for its own purposes.",
  ];
  const userDataTitle = "User data";
  const userDataParagraph =
    "The Application only transmits or stores identifying documentation \
  that has been voluntarily provided by its users or is authorized by its users \
  for retrieval from the UN Agency which holds the relevant identifying information. \
  \n\nIf you provide user data in order to obtain access to the Application, \
  we will use such data to provide access to such services and to monitor use of such services \
  only for security purposes. UNICC does not have access to nor stores \
  any identifying information stored in the Application. Therefore, if you lose your log-in credentials, \
  you will need to create a new wallet and retrieve identifying information from the issuing UN agency. \
  \n\nThe Application may require the use of biometrics, to securely provide you access with your wallet. \
  Biometric information is only stored in the device, \
  is not transmitted online and therefore UNICC has no access to such information. \
  \n\nUNICC processes non-personal, aggregated data relating to the use of the Application \
  to ensure its performance, improve user experience and ensure the security. \
  \n\nThe Application may ask for your permission to access your mobile device's camera, \
  which is only used in the process of adding or verifying identifying documentation. \
  \n\nThe Application requires your local device storage to offline securely store your credentials.";
  const dataSecurityTitle = "Data security";
  const dataSecurityParagraph =
    "UNICC implements industry standard security measures \
  to protect User Data from unauthorized access, modification or destruction. \
  All staff-related personal data are encrypted at rest in the device.";
  const yourRightsTitle = "Your rights";
  const yourRightsParagraph =
    "We offer you choices regarding the storage, deletion, use \
  and sharing of your identifying documentation and we respect the choices you make. \
  If you decide not to retrieve, to delete or not to share your identifying information \
  with the relevant UN agencies you may not be able to avail of UN Digital ID services.";

  const policyList = [
    { title: mainTitle, paragraph: mainParagraph, isMain: true },
    { title: userDataTitle, paragraph: userDataParagraph, isMain: false },
    {
      title: dataSecurityTitle,
      paragraph: dataSecurityParagraph,
      isMain: false,
    },
    { title: yourRightsTitle, paragraph: yourRightsParagraph, isMain: false },
  ];
  //#endregion

  //#region Renderers
  const sections = policyList.map((pol, index) => {
    const { title, paragraph, isMain } = pol;
    return (
      <Box key={index} className={isMain ? "space-y-8" : "space-y-1"}>
        <Typography variant={isMain ? "h1" : "h2"}>{title}</Typography>
        <Typography whiteSpace="pre-line">{paragraph}</Typography>
      </Box>
    );
  });
  //#endregion

  return (
    <Box
      data-testid="privacyPolicy"
      className={isMobile ? "p-8 space-y-10" : "p-14 space-y-20"}
    >
      <Box className={cn("flex space-x-12", isMobile && "justify-between")}>
        <img
          src={isMobile ? unLogoMobile : unLogo}
          className="h-16"
          alt="united nations logo"
        />
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            aria-hidden="true"
            className="border-black opacity-50"
          />
        )}
        <img
          src={isMobile ? undidLogoMobile : undidLogo}
          className="h-16"
          alt="digital id logo"
        />
      </Box>
      <Box className={cn("space-y-5", isMobile ? "px-2" : "px-24")}>
        {sections}
      </Box>
    </Box>
  );
};

export { PrivacyPolicy };
