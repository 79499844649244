import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useEffect, useState } from "react";
import IntlMessages from "../../../components/IntlMessages/IntlMessages";
import {
  TableHeaderInterface,
  TableSort,
  TableSortDirection,
} from "../TableTypes";

const TableHeader = <IdField extends string>({
  columns,
  ordering,
  onSort,
}: TableHeaderInterface<IdField>) => {
  const [sortColumns, setSortColumns] = useState<TableSort<IdField>>([]);

  useEffect(() => {
    setSortColumns(ordering || []);
  }, [ordering]);

  const sortValueForField = (columnId: IdField) => {
    let entry = sortColumns.find((entry) => entry[columnId] !== undefined);
    return entry ? entry[columnId] : undefined;
  };
  const sortOrderForField = (columnId: IdField) => {
    let entry = sortColumns.find((entry) => entry[columnId] !== undefined);
    if (!entry) return "";
    return sortColumns.indexOf(entry) + 1 + " - ";
  };

  const handleSort = (columnId: IdField) => {
    if (onSort) {
      /* Enable this line if you need sort by one field */
      // let newSort: TableSort<IdField> = [];

      /* Enable this line if you need sort by multiple fields */
      let newSort = sortColumns.filter(
        (entry) => entry[columnId] === undefined
      );

      let newSortValue = sortValueForField(columnId);
      switch (newSortValue) {
        case TableSortDirection.ascending:
          // @ts-ignore
          newSort.push({
            [columnId]: TableSortDirection.descending,
          });
          break;
        case TableSortDirection.descending:
          // We simply delete it from the list
          break;
        default:
          // @ts-ignore
          newSort.push({
            [columnId]: TableSortDirection.ascending,
          });
          break;
      }
      onSort(newSort);
    }
  };

  return (
    <TableHead data-testid="tableHeader">
      <TableRow data-testid="tableHeader.row">
        {columns.map((column) => {
          const { id: columnId, label, align, sortable, cellClass } = column;
          const headCellId = `header-${columnId}`;

          return (
            <TableCell
              key={headCellId}
              id={headCellId}
              data-testid="tableHeader.cell"
              align={align || "left"}
              className={cellClass}
            >
              {label ? (
                <>
                  {sortOrderForField(columnId)}
                  <IntlMessages id={label} />
                </>
              ) : (
                ""
              )}
              {sortable ? (
                <TableSortLabel
                  data-testid="tableHeader.sortIcon"
                  active={sortValueForField(columnId) !== undefined}
                  direction={sortValueForField(columnId) || "asc"}
                  IconComponent={KeyboardArrowDownOutlined}
                  onClick={(_) => handleSort(columnId)}
                ></TableSortLabel>
              ) : (
                <></>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export { TableHeader };
