import { AuthenticationDataProvider } from "./authentication/AuthenticationContext";
import { CredentialDefinitionsDataProvider } from "./credentialDefinitions/CredentialDefinitionsContext";
import { CredentialsDataProvider } from "./credentials/CredentialsContext";
import { LanguageDataProvider } from "./language/LanguageContext";
import { ProofsDataProvider } from "./proofs/ProofsContext";
import { SchemasDataProvider } from "./schemas/SchemasContext";
import { UsersDataProvider } from "./users/UsersContext";

// This is setup so we do not have to do this at the index.tsx. If you add a provider, please do the same at the
// __testUtils__ folder
export const GlobalContextProvider = ({ children }: any) => {
  return (
    <LanguageDataProvider>
      <AuthenticationDataProvider>
        <SchemasDataProvider>
          <CredentialDefinitionsDataProvider>
            <CredentialsDataProvider>
              <ProofsDataProvider>
                <UsersDataProvider>{children}</UsersDataProvider>
              </ProofsDataProvider>
            </CredentialsDataProvider>
          </CredentialDefinitionsDataProvider>
        </SchemasDataProvider>
      </AuthenticationDataProvider>
    </LanguageDataProvider>
  );
};
